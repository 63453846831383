import { Inject, Injectable, InjectionToken } from '@angular/core'
import * as moment from 'moment-timezone'
// import { DateAdapter } from '../../shared/components/mat-inspired/core/datetime'
import { LocalStorage } from '../local-storage/local-storage.service'
import { Logger } from '../logger/logger'
import { LoggerService } from '../logger/logger.service'
import { WindowRef } from '../window/browser-window.ref'
import { CountryService } from './country.service'
import { TranslateService } from './translate.service'

export const TRANSLATE_CONFIG = new InjectionToken<TranslateConfig>('translateConfig')

export interface TranslateConfig {
  fallbackLanguage: string
  localStorageKey: string
  localeMatcher: (locale: string) => string
}

@Injectable()
export class LocaleService {
  private static browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage']

  /** holds the locale with country information de instead of de-CH */
  private _currentLocale: string

  /** holds the locale without country information */
  private _currentLanguage: string

  private _currentZoneId: string
  private window: Window

  private logger: Logger

  private static getBrowserLanguageOrFallback(fallback: string): string {
    let language
    let i
    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(navigator['languages'])) {
      for (i = 0; i < navigator['languages'].length; i++) {
        language = navigator['languages'][i]
        if (language && language.length) {
          return language
        }
      }
    }

    // support for other well known properties in browsers
    for (i = 0; i < LocaleService.browserLanguagePropertyKeys.length; i++) {
      language = navigator[LocaleService.browserLanguagePropertyKeys[i]]
      if (language && language.length) {
        return language
      }
    }

    return fallback
  }

  private static localeToLanguage(locale: string): string {
    return locale.split('-')[0]
  }

  constructor(
    loggerService: LoggerService,
    windowRef: WindowRef,
    private translateService: TranslateService,
    private countryService: CountryService,
    private localStorage: LocalStorage,
    @Inject(TRANSLATE_CONFIG) private translateConfig: TranslateConfig
  ) {
    this.logger = loggerService.getInstance('LocaleService')
    this.window = windowRef.nativeWindow
    // defaults
    this.translateService.setDefaultLang(this.translateConfig.fallbackLanguage)
  }

  get currentLocale() {
    return this._currentLocale
  }

  get currentTimeZone() {
    return this._currentZoneId
  }

  /**
   * Will initialize the locale and timezone
   */
  init(preventReload: boolean): void {
    this.initLocale(preventReload)
    this.initGuessedTimeZone()
  }

  changeLocaleAndTimezone(locale, zoneId?: string) {
    this.logger.debug('changeLocaleAndTimezone(%s, %s)', locale, zoneId)
    if (zoneId && zoneId !== this._currentZoneId) {
      this.logger.debug('time-zone changed to: ', zoneId)
      this.initTimeZone(zoneId)
    }
    this.changeLocale(locale)
  }

  /**
   *
   * @param newLocale
   * @param preventReload
   * @returns Returns a boolean indicating if the language was changed or not
   */
  changeLocale(newLocale, preventReload?: boolean): boolean {
    // ignore the country information we are only interested in language
    let localeToUse: string
    if (this.translateConfig.localeMatcher) {
      localeToUse = this.translateConfig.localeMatcher(newLocale)
    } else {
      localeToUse = newLocale
    }

    // only change if locale differs
    if (localeToUse !== this._currentLocale) {
      this.logger.debug('changeLocale(%s)', localeToUse)
      this._currentLocale = localeToUse
      this._currentLanguage = LocaleService.localeToLanguage(newLocale)
      this.translateService.use(localeToUse)
      // set the moment locale
      moment.locale(localeToUse)
      // FIXME set the locale on the DateAdapter used for date-picker component
      // this.dateAdapter.setLocale(localeToUse)
      this.localStorage.setItem(this.translateConfig.localStorageKey, localeToUse)

      if (!preventReload) {
        this.window.location.reload()
      }

      return true
    } else {
      return false
    }
  }

  /*
   * Init the current locale to use with following priority:
   *
   *  1. local storage
   *  2. browser language
   *  3. default fallback language
   */
  private initLocale(preventReload: boolean) {
    let preferredLocale = this.localStorage.getItem(this.translateConfig.localStorageKey)
    if (preferredLocale === null) {
      // set defaultLocale to browser language or fallback to default langauge
      preferredLocale = LocaleService.getBrowserLanguageOrFallback(this.translateConfig.fallbackLanguage)
    }

    this.changeLocale(preferredLocale, preventReload)
  }

  private initGuessedTimeZone() {
    this.initTimeZone(this.countryService.getGuessedCurrentTimeZone())
  }

  private initTimeZone(zoneId: string) {
    this.logger.debug('initTimeZone(%s)', zoneId)
    this._currentZoneId = zoneId
    moment.tz.setDefault(zoneId)
  }
}
