// FIXME TSLINT
// tslint:disable
import { Directive, TemplateRef } from '@angular/core'

@Directive({
  selector: '[sc-accordion-header]',
})
export class AccordionHeaderDirective {
  // only catch the templateRef to query inside TabComponent
  constructor(public templateRef: TemplateRef<any>) {}
}
