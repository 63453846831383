import { ModuleWithProviders, NgModule } from '@angular/core'
import { LocalStorage } from './local-storage.service'

@NgModule({})
export class LocalStorageModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LocalStorageModule,
      providers: [LocalStorage],
    }
  }
}
