/**
 * Header row definition for the sc-table.
 * Captures the header row's template and other header properties such as the columns to display.
 */
import { CdkHeaderRow, CdkHeaderRowDef, CdkRow, CdkRowDef } from '@angular/cdk/table'
import { ChangeDetectionStrategy, Component, Directive } from '@angular/core'

@Directive({
  selector: '[scHeaderRowDef]',
  providers: [{ provide: CdkHeaderRowDef, useExisting: ScHeaderRowDef }],
  // tslint:disable-next-line:use-input-property-decorator
  inputs: ['columns: scHeaderRowDef'],
})
// tslint:disable-next-line:directive-class-suffix
export class ScHeaderRowDef extends CdkHeaderRowDef {}

/**
 * Data row definition for the sc-table.
 * Captures the header row's template and other row properties such as the columns to display and
 * a when predicate that describes when this row should be used.
 */
// tslint:disable-next-line:max-classes-per-file
@Directive({
  selector: '[scRowDef]',
  providers: [{ provide: CdkRowDef, useExisting: ScRowDef }],
  // tslint:disable-next-line:use-input-property-decorator
  inputs: ['columns: scRowDefColumns', 'when: scRowDefWhen'],
})
// tslint:disable-next-line:directive-class-suffix
export class ScRowDef<T> extends CdkRowDef<T> {}

/** Header template container that contains the cell outlet. Adds the right class and role. */
// tslint:disable-next-line:max-classes-per-file
@Component({
  selector: 'sc-header-row',
  template: '<ng-container cdkCellOutlet></ng-container>',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'sc-header-row',
    role: 'row',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'scHeaderRow',
  preserveWhitespaces: false,
})
// tslint:disable-next-line:component-class-suffix
export class ScHeaderRow extends CdkHeaderRow {}

/** Data row template container that contains the cell outlet. Adds the right class and role. */
// tslint:disable-next-line:max-classes-per-file
@Component({
  selector: 'sc-row',
  template: '<ng-container cdkCellOutlet></ng-container>',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'sc-row',
    role: 'row',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'scRow',
  preserveWhitespaces: false,
})
// tslint:disable-next-line:component-class-suffix
export class ScRow extends CdkRow {}
