import { Modal2Config } from './modal2.model'

export const MODAL_DECOR_KEY = 'Modal2Config'

// TODO check what exactly is the right way to define metadata
export function Modal2(param: Modal2Config) {
  return cls => {
    // Reflect.defineMetadata(MODAL_DECOR_KEY, param, cls)
    cls[MODAL_DECOR_KEY] = param
  }
}

export function readModalConfig(cls) {
  // return Reflect.getMetadata(MODAL_DECOR_KEY, cls)
  return cls[MODAL_DECOR_KEY]
}
