import { hasTouch } from './has-touch.function'

interface ETouch extends Touch {
  offsetX?: number
  offsetY?: number
}

export function makeTouchEventUsable(event: Event | TouchEvent, centerX: number, centerY: number): any {
  if (hasTouch() && event instanceof TouchEvent) {
    event.preventDefault()
    const touch: ETouch = event.touches[0]
    // decorate with some new fields
    touch.offsetX =
      touch.clientX -
      ((<HTMLElement>touch.target).offsetLeft + (<HTMLElement>(<HTMLElement>touch.target).offsetParent).offsetLeft) +
      centerX
    // header height include
    touch.offsetY =
      touch.clientY -
      ((<HTMLElement>touch.target).offsetTop + (<HTMLElement>(<HTMLElement>touch.target).offsetParent).offsetTop) +
      centerY
    return touch
  } else {
    return event
  }
}
