import { animate, state, style, transition, trigger } from '@angular/animations'
import {
  Component,
  ContentChild,
  ElementRef,
  Host,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  ViewChild,
} from '@angular/core'
import { DeviceInfoService, ScreenProperties, ScrollToService, WindowRef } from '@shiftcoders/core'
import { AccordionContentDirective } from './accordion-content.directive'
import { AccordionHeaderDirective } from './accordion-header.directive'
import { AccordionComponent } from './accordion.component'

const ACCORDION_ANIMATION_DURATION = 400
const ACCORDION_SCROLL_SPEED = 400

@Component({
  selector: 'sc-accordion-group',
  template: `<div class="accordion__heading" role="tab" (click)="toggle()">
  <div #anchor class="accordion__anchor"></div>
  <div [ngTemplateOutlet]="accordionHeader.templateRef"></div>
</div>
<div class="accordion__body" role="tabaccordion" *ngIf="isOpened" [@scAccordionGroupState]="'active'">
  <div class="accordion__content"
       [ngTemplateOutlet]="accordionContent.templateRef">
  </div>
</div>
`,
  animations: [
    trigger('scAccordionGroupState', [
      state(
        'active',
        style({
          height: '*',
          opacity: '1',
        })
      ),
      transition('void => *', [
        style({
          height: '0',
          opacity: '0',
        }),
        animate(ACCORDION_ANIMATION_DURATION + 'ms ease'),
      ]),
      transition('* => void', [
        animate(
          ACCORDION_ANIMATION_DURATION + 'ms ease',
          style({
            height: '0',
            opacity: '0',
          })
        ),
      ]),
    ]),
  ],
})
export class AccordionGroupComponent implements OnInit, OnDestroy, OnChanges {
  // keep this as it is
  @HostBinding('class.accordion__segment') baseClass = true

  @Input() isOpened = false
  @Input() disableDesktopScrollAnimation = false
  @Input() disableScrollAnimation = false

  @ViewChild('anchor') anchorElement: ElementRef

  @ContentChild(AccordionContentDirective) accordionContent: AccordionContentDirective

  @ContentChild(AccordionHeaderDirective) accordionHeader: AccordionHeaderDirective

  private screenXS = false

  constructor(
    deviceInfo: DeviceInfoService,
    @Host() private accordion: AccordionComponent,
    private windowRef: WindowRef,
    private scrollToService: ScrollToService
  ) {
    deviceInfo.screenChanges.subscribe((screenProperties: ScreenProperties) => {
      this.screenXS = screenProperties.width === 'xs'
    })
  }

  toggle() {
    const isOpenedBeforeWeChange = this.isOpened
    if (this.accordion.closeOthers) {
      this.accordion.closeAll()
    }
    this.isOpened = !isOpenedBeforeWeChange
  }

  ngOnInit() {
    this.accordion.addGroup(this)
  }

  ngOnDestroy() {
    this.accordion.removeGroup(this)
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (!this.disableScrollAnimation && ((!this.disableDesktopScrollAnimation && !this.screenXS) || this.screenXS)) {
      if (changes['isOpened'] && changes['isOpened'].currentValue === true) {
        this.scrollTo(this, this.anchorElement)
      }
    }
  }

  private scrollTo(group: AccordionGroupComponent, anchor: ElementRef) {
    setTimeout(() => {
      const window = this.windowRef.nativeWindow
      const to = anchor.nativeElement.getBoundingClientRect().top + (window.scrollY || window.pageYOffset)

      this.scrollToService.scrollTo(to, ACCORDION_SCROLL_SPEED)
    }, ACCORDION_ANIMATION_DURATION + 100)
  }
}
