import { hasTouch } from './has-touch.function'

export interface Position2d {
  x: number
  y: number
}

export function getPosition2d(event: TouchEvent | MouseEvent): Position2d | null {
  if (hasTouch() && event instanceof TouchEvent) {
    const touch = event.touches[0] || event.changedTouches[0]
    return touch ? { x: touch.clientX, y: touch.clientY } : null
  }
  if (event['clientX'] === undefined || event['clientY'] === undefined) {
    return null
  }
  return { x: (<MouseEvent>event).clientX, y: (<MouseEvent>event).clientY }
}
