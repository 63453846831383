import { Directive, HostListener, Input } from '@angular/core'
import { Modal2Ref } from './modal2-ref'

@Directive({
  selector: '[scModalClose]',
})
export class Modal2CloseDirective {
  @Input() scModalClose: any = null

  constructor(private dialogRef: Modal2Ref<any, any>) {}

  @HostListener('click')
  onClick() {
    this.dialogRef.close(this.scModalClose)
  }
}
