import { Injectable } from '@angular/core'
import { Parser } from './parser.service'

@Injectable()
export class MessageFormatParser extends Parser {
  constructor() {
    super()
  }

  process(target: any, key: string, interpolateParams?: { [key: string]: string }): string | null {
    const compiledFunction = this.getValue(target, key)

    if (compiledFunction) {
      return compiledFunction(interpolateParams)
    } else {
      return null
    }
  }

  /**
   * Gets a value from an object by composed key
   * parser.getValue({ key1: { keyA: 'valueI' }}, 'key1.keyA') ==> 'valueI'
   */
  private getValue(target: any, key: string): (interpolation?: any) => string {
    const keys = key.split('.')
    key = ''
    do {
      key += keys.shift()
      if (target !== undefined && target[key] !== undefined && (typeof target[key] === 'object' || !keys.length)) {
        target = target[key]
        key = ''
      } else if (!keys.length) {
        target = undefined
      } else {
        key += '.'
      }
    } while (keys.length)

    return target
  }
}
