import { Platform } from '@angular/cdk/platform'
import { ElementRef } from '@angular/core'

const CLASS_DRAGGING = 'sc-slide-toggle__bar--dragging'

/**
 * Renderer for the Slide Toggle component, which separates DOM modification in its own class
 */
export class SlideToggleRenderer {
  /** Reference to the thumb HTMLElement. */
  private thumbEl: HTMLElement

  /** Reference to the thumb bar HTMLElement. */
  private thumbBarEl: HTMLElement

  /** Width of the thumb bar of the slide-toggle. */
  private thumbBarWidth: number

  /** Previous checked state before drag started. */
  private previousChecked: boolean

  /** Percentage of the thumb while dragging. Percentage as fraction of 100. */
  dragPercentage: number

  /** Whether the thumb is currently being dragged. */
  dragging = false

  constructor(elementRef: ElementRef, platform: Platform) {
    // We only need to interact with these elements when we're on the browser, so only grab
    // the reference in that case.
    if (platform.isBrowser) {
      this.thumbBarEl = elementRef.nativeElement.querySelector('.sc-slide-toggle__bar')
      this.thumbEl = elementRef.nativeElement.querySelector('.sc-slide-toggle__bar__thumb')
    }
  }

  /** Initializes the drag of the slide-toggle. */
  startThumbDrag(checked: boolean): void {
    if (this.dragging) {
      return
    }

    this.thumbBarWidth = this.thumbBarEl.clientWidth - this.thumbEl.clientWidth
    this.thumbBarEl.classList.add(CLASS_DRAGGING)

    this.previousChecked = checked
    this.dragging = true
  }

  /** Resets the current drag and returns the new checked value. */
  stopThumbDrag(): boolean {
    if (!this.dragging) {
      return false
    }

    this.dragging = false
    this.thumbBarEl.classList.remove(CLASS_DRAGGING)

    // Reset the transform because the component will take care of the thumb position after drag.
    this.thumbEl.style.transform = ''

    return this.dragPercentage > 50
  }

  /** Updates the thumb containers position from the specified distance. */
  updateThumbPosition(distance: number): void {
    this.dragPercentage = this.getDragPercentage(distance)
    // Calculate the moved distance based on the thumb bar width.
    const dragX = (this.dragPercentage / 100) * this.thumbBarWidth
    this.thumbEl.style.transform = `translate3d(${dragX}px, 0, 0)`
  }

  /** Retrieves the percentage of thumb from the moved distance. Percentage as fraction of 100. */
  private getDragPercentage(distance: number) {
    let percentage = (distance / this.thumbBarWidth) * 100

    // When the toggle was initially checked, then we have to start the drag at the end.
    if (this.previousChecked) {
      percentage += 100
    }

    return Math.max(0, Math.min(percentage, 100))
  }
}
