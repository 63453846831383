import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { mapValuesDeep } from '../../static-utils/map-values-deep.function'
import { convertDates } from '../../static-utils/convert-dates.function'

@Injectable()
export class MomentInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.responseType === 'json') {
      return next.handle(req).pipe(
        map((response: HttpEvent<any>) => {
          if (response instanceof HttpResponse && response.status === 200) {
            const body = mapValuesDeep(response.body, convertDates)
            return response.clone({ body })
          } else {
            return response
          }
        })
      )
    } else {
      return next.handle(req)
    }
  }
}
