import { Component } from '@angular/core'

@Component({
  selector: 'sc-list-item',
  template: `<ng-content></ng-content>
`,
  styles: [`:host{display:block;padding-top:12px;padding-bottom:12px;border-bottom:1px solid #eee}`],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'sc-list-item',
  },
})
export class ScListItemComponent {}
