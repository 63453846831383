import { LogLevel } from './log-level.enum'
import { Logger } from './logger'
import { LogglyService } from './loggly.service'

export class LogglyLogger extends Logger {
  private logglyService: LogglyService
  private context: string

  constructor(levelGetter: () => LogLevel, name: string, logglyService: LogglyService, color?: string) {
    super(levelGetter, name, color)
    this.logglyService = logglyService
    this.context = name
  }

  info(...args) {
    this.log(LogLevel.INFO, args)
  }

  warn(...args) {
    this.log(LogLevel.WARN, args)
  }

  error(...args) {
    this.log(LogLevel.ERROR, args)
  }

  debug(...args) {
    this.log(LogLevel.DEBUG, args)
  }

  protected log(level: LogLevel, args: any[]) {
    super.log(level, args)
    // check level -> send all above DEBUG level to loggly
    if (level > LogLevel.DEBUG) {
      this.logglyService.sendMessage(level, this.context, args)
    }
  }
}
