import { CdkTableModule } from '@angular/cdk/table'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FormControlModule } from '../form-control/form-control.module'
import { IconModule } from '../icon/icon.module'
import { PagedMeta } from './data-source/paged-data-source'
import { PagedDataSourceFactory } from './data-source/paged-data-source.factory'
import { PAGED_META } from './data-source/paged-meta.injection-token'
import { DataViewComponent } from './data-view.component'
import { ScListItemComponent } from './list/list-item.component'
import { ItemsPlaceholder, ListItemDef, ScListComponent } from './list/list.component'
import { ScSort } from './sort/sort'
import { ScSortHeader } from './sort/sort-header'
import { SC_SORT_HEADER_INTL_PROVIDER } from './sort/sort-header-intl'
import { ScCell, ScCellDef, ScColumnDef, ScHeaderCell, ScHeaderCellDef } from './table/cell'
import { ScHeaderRow, ScHeaderRowDef, ScRow, ScRowDef } from './table/row'
import { DataRowOutlet, HeaderRowOutlet, ScTable } from './table/table.component'
import { SearchInputComponent } from './title-bar/search/search-input.component'
import { SelectFilterComponent } from './title-bar/select-filter/select-filter.component'
import { TitleBarSectionDirective } from './title-bar/title-bar-section.directive'
import { TitleBarComponent } from './title-bar/title-bar.component'
import { StateButtonModule } from '../state-button/state-button.module'
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module'
import { ElementVisibleModule } from '../element-visible/element-visible.module'
import { ScrollToTopModule } from '../scroll-to-top/srcoll-to-top.module'
import { LocaleModule, LoggerModule } from '@shiftcoders/core'

const EXPORTED_DECLARATIONS = [
  DataViewComponent,
  // Table
  ScTable,
  HeaderRowOutlet,
  DataRowOutlet,

  // Template definitions
  ScCellDef,
  ScHeaderCellDef,
  ScColumnDef,

  // Cell directives
  ScHeaderCell,
  ScCell,

  // Row directives
  ScHeaderRow,
  ScRow,
  ScHeaderRowDef,
  ScRowDef,

  // Sort
  ScSort,
  ScSortHeader,

  // List
  ScListComponent,
  ScListItemComponent,
  ListItemDef,
  ItemsPlaceholder,

  // Titlebar
  TitleBarComponent,
  TitleBarSectionDirective,
  SearchInputComponent,
  SelectFilterComponent,
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormControlModule,
    IconModule,
    StateButtonModule,
    CdkTableModule,
    LoadingIndicatorModule,
    ElementVisibleModule,
    ScrollToTopModule,
    LocaleModule,
    LoggerModule,
  ],
  declarations: EXPORTED_DECLARATIONS,
  exports: EXPORTED_DECLARATIONS,
  providers: [
    SC_SORT_HEADER_INTL_PROVIDER,
    // TODO IMPROVE should be singleton for whole application
    PagedDataSourceFactory,
    { provide: PAGED_META, useValue: <PagedMeta>{ pageSize: 20, offset: 0 } },
  ],
})
export class DataViewModule {}
