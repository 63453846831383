import {
  AfterContentInit,
  ContentChildren,
  Directive,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { RadioButtonComponent } from './radio-button.component'

/* tslint:disable */

/**
 * Provider Expression that allows sc-radio-group to register as a ControlValueAccessor. This
 * allows it to support [(ngModel)] and ngControl.
 */
export const RADIO_GROUP_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioGroupDirective),
  multi: true,
}

let _uniqueIdCounter = 0

@Directive({
  selector: 'sc-radio-group',
  providers: [RADIO_GROUP_CONTROL_VALUE_ACCESSOR],
})
export class RadioGroupDirective implements AfterContentInit, ControlValueAccessor {
  /**
   * Selected value for group. Should equal the value of the selected radio button if there *is*
   * a corresponding radio button with a matching value. If there is *not* such a corresponding
   * radio button, this value persists to be applied in case a new radio button is added with a
   * matching value.
   */
  private _value: any = null

  /** The HTML name attribute applied to radio buttons in this group. */
  private _name: string = `sc-radio-group-${_uniqueIdCounter++}`

  /** Disables all individual radio buttons assigned to this group. */
  private _disabled: boolean | null = false

  /** The currently selected radio button. Should match value. */
  private _selected: RadioButtonComponent | null = null

  /** Whether the `value` has been set to its initial value. */
  private _isInitialized: boolean = false

  /** The method to be called in order to update ngModel */
  private _controlValueAccessorChangeFn: (value: any) => void = value => {}

  /** onTouch function registered via registerOnTouch (ControlValueAccessor). */
  onTouched: () => any = () => {}

  /** Event emitted when the group value changes. */
  @Output() change: EventEmitter<any> = new EventEmitter<any>()

  /** Child radio buttons. */
  @ContentChildren(forwardRef(() => RadioButtonComponent))
  _radios: QueryList<RadioButtonComponent> | null = null

  @Input()
  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
    this._updateRadioButtonNames()
  }

  @Input()
  get disabled(): boolean | null {
    return this._disabled
  }

  set disabled(value) {
    // The presence of *any* disabled value makes the component disabled, *except* for false.
    this._disabled = value != null && value !== false ? true : null
  }

  @Input()
  get value(): any {
    return this._value
  }

  set value(newValue: any) {
    if (this._value != newValue) {
      // Set this before proceeding to ensure no circular loop occurs with selection.
      this._value = newValue

      this._updateSelectedRadioFromValue()

      // Only fire a change event if this isn't the first time the value is ever set.
      if (this._isInitialized) {
        this._emitChangeEvent()
      }
    }
  }

  @Input()
  get selected() {
    return this._selected
  }

  set selected(selected: RadioButtonComponent | null) {
    this._selected = selected
    this.value = selected ? selected.value : null

    if (selected && !selected.checked) {
      selected.checked = true
    }
  }

  /**
   * Initialize properties once content children are available.
   * This allows us to propagate relevant attributes to associated buttons.
   */
  ngAfterContentInit() {
    // Mark this component as initialized in AfterContentInit because the initial value can
    // possibly be set by NgModel on MdRadioGroup, and it is possible that the OnInit of the
    // NgModel occurs *after* the OnInit of the MdRadioGroup.
    this._isInitialized = true
  }

  /**
   * Mark this group as being "touched" (for ngModel). Meant to be called by the contained
   * radio buttons upon their blur.
   */
  _touch() {
    if (this.onTouched) {
      this.onTouched()
    }
  }

  private _updateRadioButtonNames(): void {
    if (this._radios) {
      this._radios.forEach(radio => {
        radio.name = this.name
      })
    }
  }

  /** Updates the `selected` radio button from the internal _value state. */
  private _updateSelectedRadioFromValue(): void {
    // If the value already matches the selected radio, do nothing.
    let isAlreadySelected = this._selected != null && this._selected.value == this._value

    if (this._radios != null && !isAlreadySelected) {
      let matchingRadio = this._radios.filter(radio => radio.value == this._value)[0]

      if (matchingRadio) {
        this.selected = matchingRadio
      } else if (this.value == null) {
        this.selected = null
        this._radios.forEach(radio => {
          radio.checked = false
        })
      }
    }
  }

  /** Dispatch change event with current selection and group value. */
  private _emitChangeEvent(): void {
    this._controlValueAccessorChangeFn(this._value)
    this.change.emit(this._value)
  }

  /**
   * Implemented as part of ControlValueAccessor.
   */
  writeValue(value: any) {
    this.value = value
  }

  /**
   * Implemented as part of ControlValueAccessor.
   */
  registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn
  }

  /**
   * Implemented as part of ControlValueAccessor.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn
  }
}
/* tslint:enable */
