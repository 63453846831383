import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal'
import { ViewportRuler } from '@angular/cdk/scrolling'
import { DOCUMENT } from '@angular/common'
import { ComponentRef, Inject, Injectable, Injector } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators'
import { MODAL2_DATA } from './modal2-data.injection-token'
import { Modal2Ref } from './modal2-ref'
import { Modal2Component } from './modal2.component'
import { readModalConfig } from './modal2.decorator'
import { Modal2Config } from './modal2.model'
import { DeviceInfoService, Logger, LoggerService, ScreenProperties } from '@shiftcoders/core'

@Injectable()
export class Modal2Service {
  private static DEFAULT_CONFIG: Modal2Config = {
    closeOnClickBackdrop: true,
    closeOnEsc: true,
    preventFromClosing: false,
    closeOnNavigate: true,
    data: {},
    size: 'sm',
    facet: 'base',
  }

  private logger: Logger
  private openDialogs: Array<Modal2Ref<any, any>> = []

  constructor(
    viewportRuler: ViewportRuler,
    router: Router,
    private loggerService: LoggerService,
    private deviceInfoService: DeviceInfoService,
    private _overlay: Overlay,
    private _injector: Injector,
    @Inject(DOCUMENT) document: Document
  ) {
    this.logger = loggerService.getInstance('Modal2Service')
    this.logger.debug(':: constructor')
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.closeAll()
    })
  }

  open<T, R>(component: ComponentType<T>, config: Modal2Config = {}): Modal2Ref<T, R> {
    const componentConfig = readModalConfig(component)
    config = { ...Modal2Service.DEFAULT_CONFIG, ...componentConfig, ...config }
    const overlay = this._overlay.create(this.overlayConfig)
    const modal: Modal2Component = this.attachModal(overlay, config)
    const modalRef: Modal2Ref<T, R> = this.attachModalContent<T, R>(component, modal, overlay, config)

    this.deviceInfoService.screenChanges
      .pipe(
        map((screenProperties: ScreenProperties) => screenProperties.width),
        distinctUntilChanged(),
        takeUntil(modalRef.beforeClosed)
      )
      .subscribe(width => modalRef.updatePosition())

    this.openDialogs.push(modalRef)

    modalRef.afterClosed.subscribe(() => this.removeOpenDialog(modalRef))

    return modalRef
  }

  /**
   * Closes all of the currently-open dialogs.
   */
  closeAll(): void {
    this.openDialogs.filter(d => d.modal.config.closeOnNavigate).forEach(d => d.close(null))
  }

  private attachModal(overlay: OverlayRef, config: Modal2Config): Modal2Component {
    const modalPortal = new ComponentPortal(Modal2Component)
    const modalComponentRef: ComponentRef<Modal2Component> = overlay.attach(modalPortal)
    modalComponentRef.instance.config = config
    return modalComponentRef.instance
  }

  private attachModalContent<T, R>(
    component: ComponentType<T>,
    modal: Modal2Component,
    overlay: OverlayRef,
    config: Modal2Config
  ): Modal2Ref<T, R> {
    const modalRef = new Modal2Ref<T, R>(this.loggerService, overlay, modal)
    modal.modalRef = modalRef
    const injector = this.createInjector(modal, modalRef, config)
    const contentRef = modal.attachComponentPortal<T>(new ComponentPortal(component, undefined, injector))
    modalRef.componentInstance = contentRef.instance

    modalRef.updatePosition()

    return modalRef
  }

  private createInjector<T, R>(
    modal: Modal2Component,
    modalRef: Modal2Ref<T, R>,
    config: Modal2Config
  ): PortalInjector {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector
    const injectionTokens = new WeakMap()
    injectionTokens
      .set(Modal2Component, modal)
      .set(MODAL2_DATA, config.data)
      .set(Modal2Ref, modalRef)
    return new PortalInjector(userInjector || this._injector, injectionTokens)
  }

  private get overlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._overlay.position().global(),
      scrollStrategy: this._overlay.scrollStrategies.block(),
      hasBackdrop: true,
      backdropClass: 'backdrop--modal',
      panelClass: 'panel--modal',
    })
  }

  /**
   * Removes a dialog from the array of open dialogs.
   * @param dialogRef Dialog to be removed.
   */
  private removeOpenDialog(dialogRef: Modal2Ref<any, any>) {
    const index = this.openDialogs.indexOf(dialogRef)
    if (index > -1) {
      this.openDialogs.splice(index, 1)
    }
  }
}
