import { Component, Input, OnInit } from '@angular/core'

// FIXME REPLACE all usages of ui-loader with this component
@Component({
  selector: 'sc-loading-indicator',
  template: `<span class="loader"></span>
`,
  styles: [`:host{display:block;position:absolute;top:50%;left:50%;text-align:center;z-index:1000;-webkit-transform:translateX(-50%) translateY(-50%);transform:translateX(-50%) translateY(-50%)}@-webkit-keyframes loader{from{-webkit-transform:rotate(0);transform:rotate(0)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes loader{from{-webkit-transform:rotate(0);transform:rotate(0)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}:host .loader{width:22px;height:22px}:host .loader:after,:host .loader:before{content:'';position:absolute;top:0;left:0;width:100%;height:100%;border-radius:50%;border-style:solid}:host .loader:before{border-color:rgba(51,51,51,.2)}:host .loader:after{border-color:currentColor transparent transparent;box-shadow:0 0 0 1px transparent;-webkit-animation:.6s linear infinite loader;animation:.6s linear infinite loader}:host(.size--sm){width:14px;height:14px}:host(.size--sm) .loader{width:14px;height:14px}:host(.size--sm):after,:host(.size--sm):before{border-width:2px}:host(.size--md){width:22px;height:22px}:host(.size--md) .loader{width:22px;height:22px}:host(.size--md):after,:host(.size--md):before{border-width:3px}:host(.size--xl){width:40px;height:40px}:host(.size--xl) .loader{width:40px;height:40px}:host(.size--xl):after,:host(.size--xl):before{border-width:4px}`],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '[class]': '"size--" + size',
  },
})
export class LoadingIndicatorComponent implements OnInit {
  @Input() size: 'xl' | 'md' | 'sm' = 'md'

  constructor() {}

  ngOnInit() {}
}
