import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'scs-guest-success',
  templateUrl: './guest-success.component.html',
  styleUrls: ['./guest-success.component.scss'],
})
export class GuestSuccessComponent implements OnInit {
  @Input() titleKey: string
  @Input() titleTranslateValue: any
  @Input() textKey: string
  @Input() textTranslateValue: any
  @Input() displayTitleOnly: boolean

  constructor() {}

  ngOnInit() {}
}
