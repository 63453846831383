import { Injectable } from '@angular/core'
import { Observable, ReplaySubject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import { isEqualWithMoment } from '../static-utils/is-equal-with-moment.function'
import { Logger } from '../logger/logger'
import { LoggerService } from '../logger/logger.service'
import { TranslateService } from './translate.service'

@Injectable()
export class LocalizedService {
  private missingTranslationSubject: ReplaySubject<string> = new ReplaySubject(1)

  readonly missingTranslation$: Observable<string> = this.missingTranslationSubject
    .asObservable()
    .pipe(distinctUntilChanged(isEqualWithMoment))

  private logger: Logger

  constructor(loggerService: LoggerService, translateService: TranslateService) {
    this.logger = loggerService.getInstance('LocalizedService')
    translateService.get('COMMONS.MISSING_TRANSLATION').subscribe((res: string) => {
      this.missingTranslationSubject.next(res)
    })
  }
}
