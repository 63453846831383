import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { TranslateLoader } from './translate-loader'
import * as MessageFormat from 'messageformat'

export class TranslateStaticLoader implements TranslateLoader {
  constructor(
    private httpClient: HttpClient,
    private path: string,
    private prefix: string = '',
    private suffix: string = '.json',
    private useIcu?: boolean
  ) {}

  /**
   * Gets the translations from the server
   * @param lang
   */
  getTranslation(lang: string): Observable<any> {
    return this.httpClient.get(`${this.path}/${this.prefix}${lang}${this.suffix}`).pipe(
      map(translations => {
        if (this.useIcu) {
          const mf = new MessageFormat(lang)
          return mf.compile(translations)
        } else {
          return translations
        }
      })
    )
  }
}
