import { ModuleWithProviders, NgModule } from '@angular/core'
import { CountryService } from './country.service'
import { LocalizedService } from './localized.service'
import { TranslateService } from './translate.service'
import { LocaleService } from './locale.service'
import { TranslatePipe } from './translate.pipe'
import { LocalizedPipe } from './localized.pipe'
import { LanguageAwarePipe } from './language-aware.pipe'
import { TranslateDirective } from './translate.directive'
import { LoggerModule } from '../logger/logger.module'
import { HttpClientModule } from '@angular/common/http'

@NgModule({
  imports: [HttpClientModule, LoggerModule],
  declarations: [TranslatePipe, TranslateDirective, LocalizedPipe, LanguageAwarePipe],
  exports: [TranslatePipe, TranslateDirective, LocalizedPipe, LanguageAwarePipe],
})
export class LocaleModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LocaleModule,
      providers: [
        // locale
        TranslateService,
        LocaleService,
        CountryService,
        LocalizedService,
      ],
    }
  }
}
