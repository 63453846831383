import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { isEqual, remove } from 'lodash'
import moment from 'moment-es6'
import { NotificationCallbackType } from './notification-callback-type.enum'
import { NotificationCenterCommand } from './notification-center-command.enum'
import { NotificationEvent } from './notification-center.event'
import { NotificationCenterService } from './notification-center.service'
import { NotificationMessage } from './notification-message.model'
import { Logger, LoggerService } from '@shiftcoders/core'

const NOTIFICATION_ANIMATION_DURATION = 150

@Component({
  selector: 'sc-notification-center',
  template: `<!-- Notification Center -->
<div class="grid">
  <div class="grid__container notification-center__container">
    <div class="grid__row notification-center__row">
      <div class="grid__col notification-center__notification" *ngFor="let message of messages">
        <sc-notification [@scNotificationState]="'active'"
                          [message]="message"
                          [timeOut]="timeOut"
                          [showProgressBar]="showProgressBar"
                          [pauseOnHover]="pauseOnHover"
                          [clickToClose]="clickToClose"
                          (remove)="onRemove($event)"
                          (clickPrimary)="onClickPrimary($event)"
                          (clickSecondary)="onClickSecondary($event)"
                          (clickLink)="onClickLink($event)">
        </sc-notification>
      </div>
    </div>
  </div>
</div>
`,
  styles: [`:host{display:block}:host .notification-center__notification{flex:0 0 100%;display:flex;flex-flow:column nowrap}html.ie10 :host .notification-center__notification,html.ie11 :host .notification-center__notification{flex-basis:auto;width:100%}:host .notification-center__notification~.notification-center__notification{margin-top:8px}`],
  animations: [
    trigger('scNotificationState', [
      state(
        'active',
        style({
          opacity: '1',
        })
      ),
      transition('void => *', [
        style({
          opacity: '0',
        }),
        animate(NOTIFICATION_ANIMATION_DURATION + 'ms ease'),
      ]),
      transition('* => void', [
        animate(
          NOTIFICATION_ANIMATION_DURATION + 'ms ease',
          style({
            opacity: '0',
          })
        ),
      ]),
    ]),
  ],
})
export class NotificationCenterComponent implements OnInit, OnDestroy {
  messages: NotificationMessage[] = []

  // default values (you can override them if needed)
  timeOut = 10000
  clickToClose = true
  showProgressBar = true
  pauseOnHover = true

  private logger: Logger
  private listener: any

  constructor(loggerService: LoggerService, private notificationCenterService: NotificationCenterService) {
    this.logger = loggerService.getInstance('NotificationCenter')
  }

  ngOnInit(): void {
    // Listen for changes in the service
    this.listener = this.notificationCenterService.emitter.subscribe((event: NotificationEvent) => {
      switch (event.command) {
        case NotificationCenterCommand.REMOVE_ALL:
          this.remove()
          break
        case NotificationCenterCommand.REMOVE:
          this.remove(event.messageId)
          break
        case NotificationCenterCommand.ADD:
          if (event.message !== undefined) {
            this.add(event.message)
          } else {
            throw new Error('there is no message defined to add to the notification center')
          }
          break
        default:
          if (event.messageId) {
            this.remove(event.messageId)
          } else if (event.message) {
            this.remove(event.message.id)
          } else {
            this.remove()
          }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.listener) {
      this.listener.unsubscribe()
    }
  }

  onRemove(message: NotificationMessage): void {
    this.remove(message.id)
  }

  onClickPrimary(message: NotificationMessage): void {
    message.subject.next(NotificationCallbackType.PRIMARY)
  }

  onClickSecondary(message: NotificationMessage): void {
    message.subject.next(NotificationCallbackType.SECONDARY)
  }

  onClickLink(message: NotificationMessage): void {
    message.subject.next(NotificationCallbackType.LINK)
  }

  // Add the new notification to the notification array
  private add(message: NotificationMessage): void {
    message.createdAt = moment()
    let index = 0
    // check if message is already displayed
    const messageDup: NotificationMessage | undefined = this.findDuplicate(message)
    if (messageDup) {
      this.logger.debug('found already displayed duplicate message: ', message)
      // we get index to replace message at correct position
      index = this.messages.indexOf(messageDup)
      // remove message so creator gets notified
      this.remove(messageDup.id)
    }
    this.messages.splice(index, 0, message)
  }

  private remove(id?: string): void {
    if (id) {
      // remove single
      const message: NotificationMessage | undefined = this.messages.find(m => m.id === id)

      if (message) {
        message.subject.complete()
        remove(this.messages, message)
      } else {
        this.logger.debug('there was no notification message found for id <%s>', id)
      }
    } else {
      // remove all
      this.messages.forEach((message: NotificationMessage) => {
        message.subject.complete()
      })

      this.messages = []
    }
  }

  private findDuplicate(message: NotificationMessage): NotificationMessage | undefined {
    return this.messages.find(m => {
      return (
        m.key === message.key &&
        m.secondaryKey === message.secondaryKey &&
        isEqual(m.interpolations, message.interpolations) &&
        isEqual(m.secondaryInterpolations, message.secondaryInterpolations) &&
        m.severity === message.severity &&
        m.routeKey === message.routeKey &&
        m.primaryKey === message.primaryKey &&
        m.route === message.route &&
        m.routeKey === message.routeKey &&
        m.routeFragment === message.routeFragment &&
        isEqual(m.routeInterpolations, message.routeInterpolations)
      )
    })
  }
}
