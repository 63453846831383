import { Injectable } from '@angular/core'
import { fromEvent, Observable, ReplaySubject } from 'rxjs'
import { debounceTime, filter, map, startWith } from 'rxjs/operators'
import * as UaParser from 'ua-parser-js'
import { Logger } from '../logger/logger'
import { LoggerService } from '../logger/logger.service'
import { WindowRef } from '../window/browser-window.ref'
import { BrowserVersion } from './browser-version.model'
import { Features } from './features.model'
import { ScreenProperties } from './screen-properties.model'
import { hasTouch } from '../static-utils/has-touch.function'

export interface Params {
  cssProperty?: string // css property name for css to be taken from, 'font-family' is the default
  pseudoEl?: ':after' | ':before' // ':before' or '::after'- if CSS need to be taken from CSS generated element
}

@Injectable()
export class DeviceInfoService {
  readonly featureChanges: Observable<Features>
  readonly browserVersionChanges: Observable<BrowserVersion>
  private _screenChanges: Observable<ScreenProperties>

  private logger: Logger
  private element: HTMLElement

  /*
   * subjects and observables
   */
  private featuresSubject: ReplaySubject<Features> = new ReplaySubject<Features>(1)
  private browserVersionSubject: ReplaySubject<BrowserVersion> = new ReplaySubject<BrowserVersion>(1)

  private window: Window
  private bodyEl: HTMLElement
  private htmlEl: HTMLElement

  static getWidthDependentPageLimit(screenProperties: ScreenProperties, rows: number = 1): number {
    switch (screenProperties.width) {
      case 'lg':
        return 4 * rows
      case 'md':
        return 3 * rows
      case 'sm':
        return 2 * rows
      case 'xs':
        return rows
      default:
        return 4 * rows
    }
  }

  constructor(loggerService: LoggerService, windowRef: WindowRef) {
    this.logger = loggerService.getInstance('DeviceInfoService')

    this.featureChanges = this.featuresSubject.asObservable()
    this.browserVersionChanges = this.browserVersionSubject.asObservable()

    this.window = windowRef.nativeWindow
    this.bodyEl = this.window.document.body
    this.htmlEl = this.window.document.documentElement

    /*
     * listen for resize to emit changes of screen properties
     */
    this._screenChanges = fromEvent(this.window, 'resize').pipe(
      debounceTime(150),
      startWith(null),
      map(this.readCssFromDom),
      filter(screen => screen !== null)
    )

    /*
     * Detect browserVersion
     */
    this.detectBrowserVersion()
  }

  get screenChanges() {
    return this._screenChanges
  }

  /**
   * Registers the resize event on the window and initially gets the css properties from given element
   * @param element
   */
  init(element: any): void {
    this.logger.debug('init()')
    this.element = element

    /*
     * check for features
     */
    const touch = hasTouch()
    const features: Features = { touch }
    this.featuresSubject.next(features)
  }

  /**
   * the following style specific stuff is inspired by https://github.com/malyw/sass-to-js/blob/master/js/src/sass-to-js.js
   */
  private readCssFromDom = (): ScreenProperties | null => {
    const params: Params = {
      cssProperty: 'content',
      pseudoEl: ':before',
    }

    if (this.element) {
      return this.getJson<ScreenProperties>(this.element, params)
    } else {
      return null
    }
  }

  /**
   * Provides JSON object from CSS property value
   */
  private getJson<T>(element, params: Params): T | null {
    let cssValue

    params.cssProperty = params.cssProperty || 'font-family'

    // get CSS value
    if (params.pseudoEl !== undefined) {
      cssValue = this.window.getComputedStyle(element, params.pseudoEl).getPropertyValue(params.cssProperty)
    } else {
      cssValue = this.window.getComputedStyle(element).getPropertyValue(params.cssProperty)
    }

    if (cssValue === null) {
      this.logger.error('CSS value for %o with params %o is empty', element, params)
      return null
    }

    // normalize
    cssValue = this.normalizeCssValue(cssValue)

    try {
      const json: T = JSON.parse(cssValue)
      return json
    } catch (err) {
      this.logger.error('Cannot parse JSON from %o with params %o', element, params)
      return null
    }
  }

  /**
   * @param CSS value string
   * @returns Normalized for JSON.stringify CSS value string
   */
  private normalizeCssValue(value: string) {
    value = value.replace(/^['"]+|\s+|\\|(;\s?})+|['"]$/g, '')
    return value
  }

  /**
   * Detects which IE version is present
   */
  private detectBrowserVersion(): void {
    const r = new UaParser().getBrowser()
    // replace any white spaces with a dash, otherwise a runtime exception is throw when we try to add the class to an
    // element
    const normalizedName = r.name.toLowerCase().replace(/\s/, '-')

    this.browserVersionSubject.next({
      name: normalizedName,
      version: r.version,
      major: +r.major,
      isIE: r.name === 'IE',
      isEdge: r.name === 'Edge',
      isSafari: r.name === 'Safari',
      isChrome: r.name === 'Chrome',
      isFirefox: r.name === 'Firefox',
    })
  }
}
