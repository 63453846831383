import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'
import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations'

export const expansionAnimation: AnimationTriggerMetadata = trigger('expansionAnimation', [
  state('collapsed', style({ height: '0px', visibility: 'hidden' })),
  state('expanded', style({ height: '*', visibility: 'visible' })),
  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
])

@Component({
  selector: 'sc-expandable',
  template: `<div [class.expandable--expanded]="expanded" class="expandable" [@expansionAnimation]="expanded ? 'expanded': 'collapsed'">
  <ng-content></ng-content>
</div>
`,
  styles: [`.expandable--expanded :host .expandable{overflow:visible}:host .expandable,:host .expandable.ng-animating{overflow:hidden}:host(.facet--compact) .expandable{display:flex;justify-content:center}`],
  animations: [expansionAnimation],
})
export class ExpandableComponent implements OnInit {
  @Input() expanded: boolean

  @Input()
  get facet() {
    return this._facet
  }

  set facet(facet) {
    this._facet = facet
    this.renderer.addClass(this.elementRef.nativeElement, `facet--${facet}`)
  }

  private _facet: 'base' | 'compact' = 'base'

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnInit() {}
}
