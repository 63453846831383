import { Component, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { share } from 'rxjs/operators'
import { HttpError, Logger, LoggerService } from '@shiftcoders/core'
import { Modal2 } from '../modal2/modal2.decorator'
import { MODAL2_DATA } from '../modal2/modal2-data.injection-token'
import { Modal2Ref } from '../modal2/modal2-ref'

@Component({
  selector: 'sc-confirm-delete',
  template: `<h3 class="modal__title" [translate]="modalData.messageKey" [translateValues]="modalData.messageValues"></h3>

<div class="modal__btn-bar btn-bar btn-bar--center">
  <div class="btn-bar__row">
    <div class="btn-bar__col">
      <button type="button" [scModalClose] class="btn btn--secondary">
        {{ 'COMMONS.CANCEL' | translate}}
      </button>
    </div>
    <div class="btn-bar__col">
      <sc-state-button facet="error"
                       (scsClick)="confirmDelete()"
                       [worker$]="worker"
                       labelTranslate="{{modalData.btnKey ? modalData.btnKey : 'COMMONS.DELETE'}}">
      </sc-state-button>
    </div>
  </div>
</div>

<div class="modal__btn-bar" *ngIf="error">
  <sc-inline-message [error]="error"></sc-inline-message>
</div>
`,
})
@Modal2({
  size: 'xs',
})
export class ConfirmDeleteComponent {
  worker: Observable<any>
  error: HttpError<any>

  private logger: Logger

  constructor(
    loggerService: LoggerService,
    @Inject(MODAL2_DATA) public modalData,
    private modal: Modal2Ref<ConfirmDeleteComponent, boolean>
  ) {
    this.logger = loggerService.getInstance('ConfirmDeleteComponent')
  }

  confirmDelete(): void {
    if (this.modalData && this.modalData.deleteFn !== undefined) {
      this.worker = this.modalData.deleteFn(...this.modalData.deleteFnArgs).pipe(share())

      this.worker.subscribe(
        // SUCCESS
        () => {
          this.logger.debug('item was deleted')
          this.modal.close(true)
        },
        // ERROR
        (httpError: HttpError<any>) => {
          this.error = httpError
        }
      )
    }
  }
}
