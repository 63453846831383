import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core'
import { Logger, LoggerService } from '@maprix/core'

@Component({
  selector: 'scs-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  @Input() facet: 'base' | 'winner' = 'base'
  @Input() sectors: number[]
  @Input() activeSector: number
  /* tslint:disable:no-input-rename */
  @Input('class') classes: string

  viewBoxSize = 32
  strokeWidth = 5
  dashOffsets: number[] = []
  dashArrays: string[] = []

  private logger: Logger
  private adjustedSectors: number[] = []

  // merges existing classes on the component defined from outside with facet
  @HostBinding('class')
  get hostClasses() {
    return `${this.classes} ${this.facet}`
  }

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('PieChartComponent')
  }

  ngOnInit() {
    const diameter: number = this.viewBoxSize - this.strokeWidth
    const circumference: number = diameter * Math.PI

    this.adjustedSectors = this.sectors.map((s: number) => {
      return circumference / 100 * s
    })

    this.sectors.forEach((sector: number, index: number) => {
      this.dashOffsets.push(this.runningDashoffset(index))
      this.dashArrays.push(this.runningDasharray(index))
    })
  }

  // because we need the possibility to
  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes && changes['facet'] && changes['facet'].currentValue) {
  //     if (!changes['facet'].isFirstChange()) {
  //       this.renderer.removeClass(this.elementRef.nativeElement, changes['facet'].previousValue);
  //     }
  //     this.renderer.addClass(this.elementRef.nativeElement, changes['facet'].currentValue);
  //   }
  // }

  private runningDashoffset(idx: number): number {
    const correction: number = [100, 0].includes(this.sectors[idx]) ? 0 : 1

    return this.adjustedSectors.reduce((prevVal: number, currVal: number, currIdx: number, sectors: number[]) => {
      return currIdx >= idx ? prevVal : prevVal + currVal
    }, correction)
  }

  private runningDasharray(idx: number): string {
    const correction: number = [100, 0].includes(this.sectors[idx]) ? 0 : 1
    return `${this.adjustedSectors[idx] - correction} 100`
  }
}
