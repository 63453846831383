import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core'
import { DeviceInfoService, Features, Logger, LoggerService } from '@shiftcoders/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'sc-auto-complete',
  template: `<div *ngIf="isOpen" class="auto-complete"
     [ngClass]="{ 'auto-complete--is-open': isOpen }">
  <div class="auto-complete__container">
    <ul class="auto-complete__items u-reset" #list>
      <li class="auto-complete__item" *ngFor="let item of source;" on-mouseover="onMouseOverItem(item)">
        <a href="javascript:void(0)"
           (click)="onAddItem(item)"
           class="auto-complete__nav-link u-reset"
           [ngClass]="{ 'auto-complete__nav-link--selected': item === selectedItem }">

          <sc-avatar *ngIf="!item.provider && !hideAutoCompleteAvatar" facet="auto-complete"
                     [imageUrl]="item.profilePictureThumbnail"
                     [displayName]="getDisplayNameForInitials(item)">
          </sc-avatar>
          <p *ngIf="!item.provider" class="auto-complete__item--text" [class.auto-complete__item--text-nm]="hideAutoCompleteAvatar">{{getDisplayName(item)}}</p>

          <!-- invited but not yet registered external -->
          <sc-avatar *ngIf="item.provider === 'external' && !hideAutoCompleteAvatar" facet="auto-complete"
                     [imageUrl]="null"
                     displayName="I"
                     avatarColor="#128b15">
          </sc-avatar>
          <p *ngIf="item.provider === 'external'" class="auto-complete__item--text" [class.auto-complete__item--text-nm]="hideAutoCompleteAvatar">{{getDisplayName(item)}}</p>

        </a>
      </li>
    </ul>
  </div>
</div>

`,
  styles: [`:host .auto-complete{overflow-x:hidden;overflow-y:scroll;-webkit-overflow-scrolling:touch;background-color:#fff;box-shadow:0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12);position:absolute;left:10px;right:10px;margin-top:0;padding-top:0;padding-bottom:0;max-height:210px;z-index:102}@media (max-width:767px){:host .auto-complete{max-height:120px}}:host .auto-complete__item{display:flex;min-height:48px}:host .auto-complete__item--text-wrap{display:flex}:host .auto-complete__item--text{margin-left:8px;width:100%;text-align:left;align-self:center}:host .auto-complete__item--text-nm{margin-left:0}:host .auto-complete__nav-link{display:flex;padding:12px 16px;flex:1;color:#333;width:100%;height:100%;align-items:flex-start;text-align:left;min-height:48px;transition:color .2s ease,background-color .2s ease,border-color .2s ease,opacity .2s ease;border-bottom:1px solid #eee}html.no-touchevents :host .auto-complete__nav-link:focus,html.no-touchevents :host .auto-complete__nav-link:hover,html.no-touchevents :host .auto-complete__nav-link[data-focus]{background-color:#e2ecf6}html.touchevents :host .auto-complete__nav-link:active,html.touchevents :host .auto-complete__nav-link[data-focus]{background-color:#e2ecf6}:host.auto-complete .auto-complete__container{transition:visibility 0s linear .2s,opacity .2s ease 0s;visibility:hidden;opacity:0}:host.auto-complete .auto-complete__nav-link--selected{background-color:#e2ecf6}:host.auto-complete.auto-complete--is-open .auto-complete__container{transition:visibility 0s linear 0s,opacity .2s ease 0s;visibility:inherit;opacity:1}`],
})
export class AutoCompleteComponent implements OnChanges, OnDestroy {
  @ViewChild('list') listElRef: any
  @Input() source: any[]
  @Input() displayFn: (item: any) => string // optional display fn
  @Input() hideAutoCompleteAvatar: boolean

  @Output() addItem: EventEmitter<any> = new EventEmitter<any>()
  @Output() releaseFocus: EventEmitter<any> = new EventEmitter<any>()

  selectedItem: any = null
  isOpen = false

  private logger: Logger
  private hasTouch = false
  private onDestroy: Subject<void> = new Subject()

  constructor(loggerService: LoggerService, deviceInfo: DeviceInfoService) {
    this.logger = loggerService.getInstance('AutoCompleteComponent')

    deviceInfo.featureChanges.pipe(takeUntil(this.onDestroy)).subscribe((features: Features) => {
      this.hasTouch = features.touch
    })
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes['source']) {
      this.selectedItem = null
      this.isOpen = this.source && this.source.length > 0
      if (this.isOpen) {
        this.onReleaseFocus()
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  getDisplayNameForInitials(item: any) {
    if (item && item.givenName && item.familyName) {
      return item.givenName.substring(0, 1) + item.familyName.substring(0, 1)
    } else {
      return this.getDisplayName(item)
    }
  }

  getDisplayName(item: any) {
    return this.displayFn ? this.displayFn(item) : item['fullName']
  }

  /*
   * host event listener for keydown events
   */
  @HostListener('keydown', ['$event'])
  onKeydown(e: KeyboardEvent) {
    if (e.keyCode !== 13) {
      e.preventDefault()
    }
    if (e.keyCode === 40) {
      e.preventDefault()
      e.stopPropagation()
      // down key, select next in autoComplete
      if (this.selectedItem) {
        if (this.source.indexOf(this.selectedItem) !== -1) {
          const currentIdx: number = this.source.indexOf(this.selectedItem)
          const max: number = this.source.length
          if (currentIdx + 1 < max) {
            this.selectedItem = this.source[currentIdx + 1]
            this.handleFocus()
          }
        }
      } else if (this.source[0]) {
        this.selectedItem = this.source[0]
        this.handleFocus()
      }
    } else if (e.keyCode === 38) {
      e.preventDefault()
      e.stopPropagation()
      // up key, select previous in autoComplete
      if (this.selectedItem) {
        if (this.source.indexOf(this.selectedItem) !== -1) {
          const currentIdx: number = this.source.indexOf(this.selectedItem)
          if (currentIdx > 0) {
            this.selectedItem = this.source[currentIdx - 1]
            this.handleFocus()
          } else {
            this.selectedItem = null
            this.handleFocus()
          }
        }
      }
    } else if (e.keyCode !== 13) {
      this.selectedItem = null
      e.preventDefault()
      this.handleFocus()
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.selectedItem === null) {
      this.selectedItem = this.source[0]
      this.handleFocus()
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.selectedItem = null
    this.onReleaseFocus()
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(e: MouseEvent) {
    if (
      this.isOpen &&
      e.srcElement !== null &&
      !e.srcElement.classList.contains('tag-input__text-input') &&
      !e.srcElement.classList.contains('tag-input--focused')
    ) {
      this.close()
      this.onReleaseFocus()
    }
  }

  onAddItem(item: any): void {
    if (!this.hasTouch) {
      this.addItem.emit(item)
    }
  }

  onReleaseFocus(): void {
    this.releaseFocus.emit(null)
  }

  onMouseOverItem(item: any): void {
    if (this.hasTouch) {
      this.addItem.emit(item)
    } else {
      this.selectedItem = item
      this.handleFocus()
    }
  }

  open(): void {
    this.selectedItem = this.source[0]
    this.isOpen = true
    this.handleFocus()
  }

  close(): void {
    this.selectedItem = null
    this.isOpen = false
  }

  removeFocus(): void {
    this.selectedItem = null
  }

  // used to set the focus on a selected item (possible to add selected entry by enter key)
  // or release the focus and focus the tag-input input
  private handleFocus() {
    setTimeout(() => {
      if (this.selectedItem) {
        const anchorEl: HTMLAnchorElement | null = this.listElRef.nativeElement.querySelector(
          '.auto-complete__nav-link--selected'
        )
        if (anchorEl) {
          anchorEl.focus()
        }
      } else {
        this.onReleaseFocus()
      }
    }, 0)
  }
}
