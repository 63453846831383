import { Injectable } from '@angular/core'
import { Observable, combineLatest, ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { HeaderUser, MenuItem } from './header.model'

@Injectable()
export abstract class HeaderProvider {
  /* subjects to use in the inheriting class */

  protected userSubject = new ReplaySubject<HeaderUser>(1)
  protected userMenuSubject = new ReplaySubject<MenuItem[]>(1)
  protected mainMenuSubject = new ReplaySubject<MenuItem[]>(1)

  /* menu items to be provided from the inheriting class */

  abstract defaultMenuItem: MenuItem
  abstract previewMenuItem: MenuItem | null

  /* observables used by the header-component */

  readonly user$: Observable<HeaderUser> = this.userSubject.asObservable()
  readonly mainMenuItems$: Observable<MenuItem[]> = this.mainMenuSubject.asObservable()
  readonly userMenuItems$: Observable<MenuItem[]> = this.userMenuSubject.asObservable()

  readonly allMenuItems$: Observable<MenuItem[]> = combineLatest(this.mainMenuItems$, this.userMenuItems$).pipe(
    map<[MenuItem[], MenuItem[]], MenuItem[]>(([mainMenu, userMenu]) => mainMenu.concat(userMenu))
  )
}
