import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LoopingCoreModule } from '../../../looping-core.module'
import { AvatarModule } from '../avatar/avatar.module'
import { PrototypeAvatarComponent } from './prototype-avatar.component'

@NgModule({
  imports: [CommonModule, AvatarModule, RouterModule, LoopingCoreModule],
  declarations: [PrototypeAvatarComponent],
  exports: [PrototypeAvatarComponent],
})
export class PrototypeAvatarModule {}
