import { A11yModule } from '@angular/cdk/a11y'
import { ObserversModule } from '@angular/cdk/observers'
import { PlatformModule } from '@angular/cdk/platform'
import { NgModule } from '@angular/core'
import { LoggerModule, UiEventModule } from '@shiftcoders/core'
import { SlideToggleComponent } from './slide-toggle.component'

@NgModule({
  imports: [PlatformModule, ObserversModule, A11yModule, LoggerModule, UiEventModule],
  exports: [SlideToggleComponent],
  declarations: [SlideToggleComponent],
})
export class SlideToggleModule {}
