import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { finalize, map, share, tap } from 'rxjs/operators'

// FIXME MAT->SC refactor code style

/** Configuration for an icon, including the URL and possibly the cached SVG element. */
class SvgIconConfig {
  svgElement: SVGElement | null = null

  constructor(public url: string) {}
}

/** Returns the cache key to use for an icon namespace and name. */
// const iconKey = (namespace: string, name: string) => namespace + ':' + name

/**
 * Service to register and display icons used by the <sc-icon> component.
 * - Registers icon URLs by namespace and name.
 * - Registers icon set URLs by namespace.
 * - Registers aliases for CSS classes, for use with icon fonts.
 * - Loads icons from URLs and extracts individual icons from icon sets.
 */
// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class IconRegistry {
  /** Cache for icons loaded by direct URLs. */
  private cachedIconsByUrl = new Map<string, SVGElement>()

  /** In-progress icon fetches. Used to coalesce multiple requests to the same URL. */
  private inProgressUrlFetches = new Map<string, Observable<string>>()

  constructor(private httpClient: HttpClient) {}

  /**
   * Returns an Observable that produces the icon (as an <svg> DOM element) from the given URL.
   * The response from the URL may be cached so this will not always cause an HTTP request, but
   * the produced element will always be a new copy of the originally fetched icon. (That is,
   * it will not contain any modifications made to elements previously returned).
   */
  getSvgIconFromUrl(url: string): Observable<SVGElement> {
    if (this.cachedIconsByUrl.has(url)) {
      return of(cloneSvg(this.cachedIconsByUrl.get(url)))
    }
    return this.loadSvgIconFromConfig(new SvgIconConfig(url)).pipe(
      tap(svg => this.cachedIconsByUrl.set(url, svg)),
      map(svg => cloneSvg(svg))
    )
  }

  /**
   * Loads the content of the icon URL specified in the SvgIconConfig and creates an SVG element
   * from it.
   *
   * USED
   */
  private loadSvgIconFromConfig(config: SvgIconConfig): Observable<SVGElement> {
    return this.fetchUrl(config.url).pipe(map(svgText => this.createSvgElementForSingleIcon(svgText, config)))
  }

  /**
   * Creates a DOM element from the given SVG string, and adds default attributes.
   *
   * USED
   */
  private createSvgElementForSingleIcon(responseText: string, config: SvgIconConfig): SVGElement {
    const svg = this.svgElementFromString(responseText)
    this.setSvgAttributes(svg, config)
    return svg
  }

  /**
   * Creates a DOM element from the given SVG string.
   */
  private svgElementFromString(str: string): SVGElement {
    // creating an element from an HTML string.
    const div = document.createElement('DIV')
    div.innerHTML = str
    const svg = <SVGElement>div.querySelector('svg')
    if (!svg) {
      // throw new MdIconSvgTagNotFoundError();
    }
    return svg
  }

  /**
   * Sets the default attributes for an SVG element to be used as an icon.
   */
  private setSvgAttributes(svg: SVGElement, config: SvgIconConfig): SVGElement {
    if (!svg.getAttribute('xmlns')) {
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    }
    svg.setAttribute('fit', '')
    svg.setAttribute('height', '100%')
    svg.setAttribute('width', '100%')
    svg.setAttribute('preserveAspectRatio', 'xMidYMid meet')
    svg.setAttribute('focusable', 'false') // Disable IE11 default behavior to make SVGs focusable.
    return svg
  }

  /**
   * Returns an Observable which produces the string contents of the given URL. Results may be
   * cached, so future calls with the same URL may not cause another HTTP request.
   */
  private fetchUrl(url: string): Observable<string> {
    // Store in-progress fetches to avoid sending a duplicate request for a URL when there is
    // already a request in progress for that URL. It's necessary to call share() on the
    // Observable returned by http.get() so that multiple subscribers don't cause multiple XHRs.
    if (this.inProgressUrlFetches.has(url)) {
      return this.inProgressUrlFetches.get(url)
    }

    // Observable. Figure out why and fix it.
    const req = this.httpClient.get(url, { responseType: 'text' }).pipe(
      finalize(() => {
        this.inProgressUrlFetches.delete(url)
      }),
      share()
    )
    this.inProgressUrlFetches.set(url, req)
    return req
  }
}

/** Clones an SVGElement while preserving type information. */
// USED
function cloneSvg(svg: SVGElement) {
  return <SVGElement>svg.cloneNode(true)
}
