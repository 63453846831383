import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core'
import { Logger } from '../logger/logger'
import { LoggerService } from '../logger/logger.service'
import { LocalizedService } from './localized.service'
import { TranslateService } from './translate.service'

/**
 * FIXME SG MISSING DOC
 */
@Pipe({
  name: 'localized',
})
export class LocalizedPipe implements PipeTransform {
  value = ''
  lastValue: { [k: string]: string }
  lastConfig: { lang: string; showMissing: boolean }
  missingTranslation: string
  defaultLocale: string | null
  private logger: Logger

  constructor(
    loggerService: LoggerService,
    localizedService: LocalizedService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.logger = loggerService.getInstance('LocalizedPipe')

    localizedService.missingTranslation$.subscribe(mt => {
      this.missingTranslation = mt
      this.changeDetectorRef.markForCheck()
    })

    // FIXME implement this
    // customerSettingsService.customerSettings$.pipe(first()).subscribe((settings: CustomerSettingDTO | null) => {
    //   if (settings && settings.defaultLocale) {
    //     this.defaultLocale = settings.defaultLocale
    //   } else {
    //     this.defaultLocale = null
    //   }
    // })
  }

  /*
  * the value should be a localized object containing <locale string> <value string> key value pairs for ex.
  * {de: 'ein Test', en: 'a test'}
  *
  * if no config object is passed in, the currently selected user/client language is used and if not found in localized
  * object the default language is taken from customer settings.
  * If config is passed the given lang (null -> currentLang) ist taken and if showMissing is set to true (or if default
  * lang was not found on object), the default 'COMMONS.MISSING_TRANSLATION' translated string is returned.
  * If showMissing is false the default language will be shown
  *
  * */
  transform(
    value: { [k: string]: string },
    config: { lang: string | null; showMissing: boolean } = {
      lang: this.translateService.currentLang,
      showMissing: false,
    }
  ): string | { [k: string]: string } {
    if (!value) {
      return value
    }

    config.lang = config.lang ? config.lang : this.translateService.currentLang

    if (this.equals(value, this.lastValue) && this.equals(config, this.lastConfig)) {
      return this.value
    } else if (!value[config.lang]) {
      // show fallback lang (defaultLocale) if available both in settings and on localized object
      if (this.defaultLocale && value[this.defaultLocale]) {
        this.value = value[this.defaultLocale]
      } else {
        this.value = this.missingTranslation
      }
      if (config.showMissing) {
        // show the missing translation key (already translated)
        this.value = `${this.value}`
      }
    } else {
      this.value = value[config.lang]
    }

    this.lastValue = value
    this.lastConfig = config

    return this.value
  }

  /**
   * @name equals
   *
   * @description
   * Determines if two objects or two values are equivalent.
   *
   * Two objects or values are considered equivalent if at least one of the following is true:
   *
   * * Both objects or values pass `===` comparison.
   * * Both objects or values are of the same type and all of their properties are equal by
   *   comparing them with `equals`.
   *
   * @param o1 Object or value to compare.
   * @param o2 Object or value to compare.
   * @returns True if arguments are equal.
   */

  /* tslint:disable */
  private equals(o1: any, o2: any): boolean {
    if (o1 === o2) return true
    if (o1 === null || o2 === null) return false
    if (o1 !== o1 && o2 !== o2) return true // NaN === NaN
    let t1 = typeof o1,
      t2 = typeof o2,
      length: number,
      key: any,
      keySet: any
    if (t1 == t2 && t1 == 'object') {
      if (Array.isArray(o1)) {
        if (!Array.isArray(o2)) return false
        if ((length = o1.length) == o2.length) {
          for (key = 0; key < length; key++) {
            if (!this.equals(o1[key], o2[key])) return false
          }
          return true
        }
      } else {
        if (Array.isArray(o2)) {
          return false
        }
        keySet = Object.create(null)
        for (key in o1) {
          if (!this.equals(o1[key], o2[key])) {
            return false
          }
          keySet[key] = true
        }
        for (key in o2) {
          if (!(key in keySet) && typeof o2[key] !== 'undefined') {
            return false
          }
        }
        return true
      }
    }
    return false
  }
}
