// tslint:disable:max-classes-per-file
export function _window(): Window {
  return (typeof window !== 'undefined' && window) || <any>{}
}

export abstract class WindowRef {
  get nativeWindow(): any {
    throw new Error('not yet implemented')
  }
}

export class BrowserWindowRef extends WindowRef {
  constructor() {
    super()
  }

  get nativeWindow(): Window {
    return _window()
  }
}
