import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'

@Pipe({
  name: 'momentFormat',
})
export class MomentFormatPipe implements PipeTransform {
  transform(date: moment.Moment, format: string = 'L') {
    let formatted: string | undefined
    if (moment.isMoment(date)) {
      formatted = date.format(format)
    }

    return formatted
  }
}
