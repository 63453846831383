import { isEqualWith } from 'lodash'
import moment from 'moment-es6'

export function isEqualWithMoment(value, otherValue): boolean {
  return isEqualWith(value, otherValue, (val, otherVal) => {
    if (moment.isMoment(val) && moment.isMoment(otherVal) && (<moment.Moment>val).isSame(<moment.Moment>otherVal)) {
      return true
    }
  })
}
