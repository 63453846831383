import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ClientIdService } from './client-id.service'
import { RuntimeConfiguration } from '../runtime-configuration/runtime-configuration'

@Injectable()
export class ClientIdInterceptor implements HttpInterceptor {
  // FIXME should we extract this into providable property
  private static CLIENT_ID_HEADER_NAME = 'X-FLUX-Client-Id'

  constructor(private clientIdService: ClientIdService, private runtimeConfiguration: RuntimeConfiguration) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add the client id header to match server- and client logs only for our own backend(s)
    if (this.runtimeConfiguration.isMicroServiceUrl(req.url)) {
      req = req.clone({
        headers: req.headers.set(ClientIdInterceptor.CLIENT_ID_HEADER_NAME, this.clientIdService.getClientId()),
      })
    }
    return next.handle(req)
  }
}
