export enum HttpErrorCode {
  KEY_PREFIX = 'HTTP_ERROR.',

  /* ------------------------------------------------------------------
   *
   *      EXTERNAL
   *
   ------------------------------------------------------------------ */
  DEFAULT_EXTERNAL_EXCEPTION = '4XX:900:DEFAULT_EXTERNAL_EXCEPTION',

  /* ------------------------------------------------------------------
   *
   *      CORE
   *
   ------------------------------------------------------------------ */

  /* ------------------------------------------------------------------
   *      400
   ------------------------------------------------------------------ */
  DEFAULT_CLIENT_EXCEPTION = '4XX:000:DEFAULT_CLIENT_EXCEPTION',
  GENERAL_BAD_REQUEST = '400:001:GENERAL_BAD_REQUEST',
  BEAN_VALIDATION_FAILED = '400:002:BEAN_VALIDATION_FAILED',

  /* ------------------------------------------------------------------
   *      401
   ------------------------------------------------------------------ */

  /* ------------------------------------------------------------------
   *      500
   ------------------------------------------------------------------ */
  GENERAL_APPLICATION_ERROR = '500:001:DEFAULT_APPLICATION_ERROR',
  BEAN_VALIDATION_ERROR = '500:002:BEAN_VALIDATION_ERROR',
}

export function getHttpErrorKeyForCode(code: string): string {
  return HttpErrorCode.KEY_PREFIX + code.toLocaleUpperCase()
}
