import { HttpHeaders } from '@angular/common/http'

export class HttpError<T> extends Error {
  code: T
  message: string
  status: number
  details: { [key: string]: string }
  headers: HttpHeaders
}

export function isHttpError(error: any): error is HttpError<any> {
  // those are the required fields of an http error
  return (
    error instanceof HttpError ||
    (!!error && !!(<HttpError<any>>error).code && !!(<HttpError<any>>error).message && !!(<HttpError<any>>error).status)
  )
}
