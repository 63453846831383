import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChange,
  ViewEncapsulation,
} from '@angular/core'
import { IconRegistry } from './icon-registry.service'

// FIXME tslint disable
/* tslint:disable */

/**
 * Copied from material MdIcon Directive but got rid of unused functionality
 * Component to display an icon. It can be used in the following ways:
 * - Specify the svgSrc input to load an SVG icon from a URL. The SVG content is directly inlined
 *   as a child of the <sc-icon> component, so that CSS styles can easily be applied to it.
 *   The URL is loaded via an XMLHttpRequest, so it must be on the same domain as the page or its
 *   server must be configured to allow cross-domain requests.
 *   Example:
 *     <sc-icon svgSrc="assets/arrow.svg"></sc-icon>
 */
@Component({
  template: '<ng-content></ng-content>',
  selector: 'sc-icon',
  styles: [`:host{display:flex}`],
  host: {
    role: 'img',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScIcon implements OnChanges, OnInit {
  @Input() svgSrc: string

  constructor(private _element: ElementRef, private _renderer: Renderer2, private _mdIconRegistry: IconRegistry) {}

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    const changedInputs = Object.keys(changes)
    // Only update the inline SVG icon if the inputs changed, to avoid unnecessary DOM operations.
    if (changedInputs.indexOf('svgIcon') !== -1 || changedInputs.indexOf('svgSrc') !== -1) {
      if (this.svgSrc) {
        this._mdIconRegistry.getSvgIconFromUrl(this.svgSrc).subscribe(
          svg => this._setSvgElement(svg),
          (err: any) => {
            console.log(`Error retrieving icon: ${err}`)
          }
        )
      }
    }
  }

  ngOnInit() {}

  private _setSvgElement(svg: SVGElement | null) {
    const layoutElement = this._element.nativeElement
    // Remove existing child nodes and add the new SVG element.
    // We would use renderer.detachView(Array.from(layoutElement.childNodes)) here,
    // but it fails in IE11: https://github.com/angular/angular/issues/6327
    layoutElement.innerHTML = ''
    // NOTE RENDERER2 projectNodes was removed in Renderer2 -> alternate solution
    // this._renderer.projectNodes(layoutElement, [svg]);
    this._renderer.appendChild(layoutElement, svg)
  }
}

/* tslint:enable */
