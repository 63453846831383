import { DOCUMENT } from '@angular/common'
import { ElementRef, Inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router'
import { filter, map } from 'rxjs/operators'
import { Subscription } from 'rxjs'
import { Logger } from '../logger/logger'
import { LoggerService } from '../logger/logger.service'
import { WindowRef } from '../window/browser-window.ref'
import { ScrollToService } from './scroll-to.service'

// https://github.com/angular/angular/issues/10929 for a more advanced scroll position management as inspiration
@Injectable()
export class RouterScrollService {
  private logger: Logger
  private fragment: string | null
  private subscription: Subscription
  private elements: Map<string, HTMLElement> = new Map()

  constructor(
    loggerService: LoggerService,
    private router: Router,
    private windowRef: WindowRef,
    private scrollTo: ScrollToService,
    // TODO document should by typed, but then the build will fail when generating aot information
    @Inject(DOCUMENT) private document: any
  ) {
    this.logger = loggerService.getInstance('RouterScrollService')
  }

  init(): void {
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        filter(event => {
          const rootSnapshot = this.router.routerState.snapshot.root
          if (rootSnapshot.children && rootSnapshot.children.length) {
            // get active route and check if there is an flag present which prevents the scrollTo service to do its work
            const activatedRoute = this.lastChild(rootSnapshot.firstChild)
            if (activatedRoute && activatedRoute.data) {
              const scrollToTop = activatedRoute.data['scrollToTop'] !== false
              if (!scrollToTop && rootSnapshot.fragment) {
                // do not filter out if there is a fragment present (even when scrollToTop is set to false)
                return true
              }
              return scrollToTop
            }
          } else {
            this.logger.warn('cannot check for route data because the root route has no children')
          }

          return true
        }),
        map(event => this.router.routerState.snapshot.root.fragment)
      )
      .subscribe(fragment => {
        this.fragment = fragment

        if (fragment) {
          this.logger.debug('found fragment', fragment)

          if (this.elements.has(fragment)) {
            const elem = this.elements.get(fragment)
            this.logger.debug('scroll element into view', this.elements.get(fragment))
            if (elem) {
              this.scrollToElement(elem)
            }
          }
        } else {
          this.logger.debug('no fragment -> scroll to top')
          // not using the animated scroll here
          this.scrollTo.scrollToNoAnimation(0)
        }
      })
  }

  destroy(): void {
    this.subscription.unsubscribe()
    this.fragment = null
    this.elements.clear()
  }

  elementReady(element: ElementRef): void {
    if (!element.nativeElement.id) {
      throw new Error('element needs an id')
    }

    if (this.fragment && this.fragment === element.nativeElement.id) {
      this.logger.debug('scrolling to element with id', this.fragment)
      this.scrollToElement(element.nativeElement)
    }

    this.elements.set(element.nativeElement.id, element.nativeElement)
  }

  elementDestroyed(element: ElementRef): void {
    this.elements.delete(element.nativeElement.id)
  }

  private scrollToElement(element: HTMLElement): void {
    const to = this.findPosition(element)
    // TODO MW LOW extract the distance from header height (provide variable via css-to-js)
    this.scrollTo.scrollTo(to.y - 74) // subtract the header height
  }

  private findPosition(el: HTMLElement) {
    const window: Window = this.windowRef.nativeWindow

    const body: HTMLElement = this.document.documentElement || this.document.body
    const scrollX = window.pageXOffset || body.scrollLeft
    const scrollY = window.pageYOffset || body.scrollTop
    const x: number = el.getBoundingClientRect().left + scrollX
    const y: number = el.getBoundingClientRect().top + scrollY

    return { x, y }
  }

  lastChild(child: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (child.children && child.children.length) {
      return this.lastChild(child.firstChild)
    }

    return child
  }
}
