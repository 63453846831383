// copied from https://github.com/angular/material2/blob/master/src/lib/radio/radio.ts
import { UniqueSelectionDispatcher } from '@angular/cdk/collections'
import { Component, EventEmitter, HostBinding, Input, Optional, Output } from '@angular/core'
import { RadioGroupDirective } from './radio-group.directive'

/* tslint:disable */

let _uniqueIdCounter = 0

@Component({
  selector: 'sc-radio-button',
  template: `<input #input type="radio"
       class="form-control__input"
       [id]="id"
       [checked]="checked"
       [disabled]="disabled"
       [name]="name"
       (change)="_onInputChange($event)"
       (focus)="_onInputFocus()"
       (blur)="_onInputBlur()"
       (click)="_onInputClick($event)">

<label [attr.for]="id" class="form-control__label-wrap">
  <span class="form-control__box">
    <span class="form-control__box-icon"></span>
  </span>
  <span class="form-control__label">
    <ng-content></ng-content>
  </span>
</label>
`,
})
export class RadioButtonComponent {
  @HostBinding('class.form-control__bool-wrap') mainClass = true

  @HostBinding('class.sc-radio-focused') _isFocused: boolean

  /** Whether this radio is checked. */
  private _checked: boolean = false

  /** The unique ID for the radio button. */
  @Input() id: string = `sc-radio-button${_uniqueIdCounter++}`

  /** Analog to HTML 'name' attribute used to group radios for unique selection. */
  @Input() name: string

  /** Whether this radio is disabled. */
  private _disabled: boolean | null

  /** Value assigned to this radio.*/
  private _value: any = null

  /** The parent radio group. May or may not be present. */
  radioGroup: RadioGroupDirective

  /** Event emitted when the group value changes. */
  @Output() change: EventEmitter<any> = new EventEmitter<any>()

  constructor(@Optional() radioGroup: RadioGroupDirective, public radioDispatcher: UniqueSelectionDispatcher) {
    // Assertions. Ideally these should be stripped out by the compiler.

    this.radioGroup = radioGroup

    radioDispatcher.listen((id: string, name: string) => {
      if (id != this.id && name == this.name) {
        this.checked = false
      }
    })
  }

  get inputId(): string {
    return `${this.id}-input`
  }

  // FIXME where is this defined? should be self contained withing component styles
  @HostBinding('class.md-radio-checked')
  @Input()
  get checked(): boolean {
    return this._checked
  }

  set checked(newCheckedState: boolean) {
    if (newCheckedState) {
      // Notify all radio buttons with the same name to un-check.
      this.radioDispatcher.notify(this.id, this.name)
    }

    this._checked = newCheckedState

    if (newCheckedState && this.radioGroup && this.radioGroup.value != this.value) {
      this.radioGroup.selected = this
    }
  }

  /** MdRadioGroup reads this to assign its own value. */
  @Input()
  get value(): any {
    return this._value
  }

  set value(value: any) {
    if (this._value != value) {
      if (this.radioGroup != null && this.checked) {
        this.radioGroup.value = value
      }
      this._value = value
    }
  }

  // FIXME where is this defined? should be self contained withing component styles
  @HostBinding('class.md-radio-disabled')
  @Input()
  get disabled(): boolean | null {
    return this._disabled || (this.radioGroup != null && this.radioGroup.disabled)
  }

  set disabled(value: boolean | null) {
    // The presence of *any* disabled value makes the component disabled, *except* for false.
    this._disabled = value != null && value !== false ? true : null
  }

  ngOnInit() {
    if (this.radioGroup) {
      // If the radio is inside a radio group, determine if it should be checked
      this.checked = this.radioGroup.value === this._value
      // Copy name from parent radio group
      this.name = this.radioGroup.name
    }
  }

  /** Dispatch change event with current value. */
  private _emitChangeEvent(): void {
    this.change.emit(this._value)
  }

  /**
   * We use a hidden native input field to handle changes to focus state via keyboard navigation,
   * with visual rendering done separately. The native element is kept in sync with the overall
   * state of the component.
   */
  _onInputFocus() {
    this._isFocused = true
  }

  _onInputBlur() {
    this._isFocused = false

    if (this.radioGroup) {
      this.radioGroup._touch()
    }
  }

  _onInputClick(event: Event) {
    // We have to stop propagation for click events on the visual hidden input element.
    // By default, when a user clicks on a label element, a generated click event will be
    // dispatched on the associated input element. Since we are using a label element as our
    // root container, the click event on the `radio-button` will be executed twice.
    // The real click event will bubble up, and the generated click event also tries to bubble up.
    // This will lead to multiple click events.
    // Preventing bubbling for the second event will solve that issue.
    event.stopPropagation()
  }

  /**
   * Triggered when the radio button received a click or the input recognized any change.
   * Clicking on a label element, will trigger a change event on the associated input.
   */
  _onInputChange(event: Event) {
    // We always have to stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    // emit its event object to the `change` output.
    event.stopPropagation()

    this.checked = true
    this._emitChangeEvent()

    if (this.radioGroup) {
      this.radioGroup._touch()
    }
  }
}
/* tslint:enable */
