import { ErrorHandler, Injectable } from '@angular/core'
import { LogLevel } from '../logger/log-level.enum'
import { LogglyService } from '../logger/loggly.service'

/**
 * Extends the angular core ErrorHandler and sends js errors to loggly.
 */
@Injectable()
export class LogglyErrorHandler extends ErrorHandler {
  constructor(private logglyService: LogglyService) {
    super()
  }

  handleError(error) {
    // submit error to loggly
    this.logglyService.sendMessage(LogLevel.ERROR, 'BrowserJsException', [error])

    // call super.handleError to print error the angular way to the console
    super.handleError(error)
  }
}
