import { AfterContentInit, Component, HostBinding, Input, OnChanges, SimpleChange } from '@angular/core'
import { Logger, LoggerService } from '@shiftcoders/core'
import { AccordionGroupComponent } from './accordion-group.component'

@Component({
  selector: 'sc-accordion',
  template: `<ng-content></ng-content>
`,
  styles: [`:host{display:block}:host /deep/ .accordion__heading{position:relative;display:block}:host /deep/ .accordion__btn{padding:6px 0}:host /deep/ .accordion__segment-title{z-index:1;position:relative}:host /deep/ .accordion__body{display:block}:host /deep/ .accordion__anchor{pointer-events:none;opacity:0;width:20px;height:20px;position:absolute;left:0}@media (min-width:992px){:host /deep/ .accordion__anchor{top:-74px}:host(.upload) /deep/ .accordion__heading{display:none}}@media (max-width:991px){:host /deep/ .accordion__anchor{top:-54px}:host(.upload) /deep/ .accordion__body{overflow:hidden}}:host(.upload) /deep/ .accordion__heading{border-bottom:1px solid transparent}@media (max-width:767px){:host(.admin) /deep/ .accordion__body{overflow:hidden}}:host(.admin) /deep/ .accordion__heading{border-bottom:1px solid transparent}@media (min-width:768px){:host(.admin) /deep/{margin:-24px 0}:host(.admin) /deep/ .accordion__heading{display:none}:host(.admin) /deep/ .accordion__segment{padding:60px 0}:host(.admin) /deep/ .accordion__segment~.accordion__segment{border-top:1px solid #eee}}:host(.admin) /deep/ .accordion__segment{display:block}`],
})
export class AccordionComponent implements AfterContentInit, OnChanges {
  @Input() facet: 'base' | 'upload' | 'admin' = 'base'
  @Input() closeOthers = true
  @Input() expandAll = false

  private accordionGroups: AccordionGroupComponent[] = []

  private logger: Logger

  @HostBinding('attr.role') role = 'tablist'

  @HostBinding('class')
  get facetClass(): string {
    return this.facet
  }

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('AccordionComponent')
  }

  ngAfterContentInit() {
    if (this.expandAll) {
      this.openAll()
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes['expandAll'] && changes['expandAll'].currentValue === true) {
      this.openAll()
    }
  }

  addGroup(group: AccordionGroupComponent) {
    this.logger.debug('Added AccordionGroupComponent...')
    this.accordionGroups.push(group)
  }

  removeGroup(group: AccordionGroupComponent) {
    this.logger.debug('Removed AccordionGroupComponent...')
    const i = this.accordionGroups.indexOf(group)
    this.accordionGroups.splice(i, 1)
  }

  openAll() {
    this.logger.debug('Opening all accordionGroups : %O', this.accordionGroups)
    this.toggleAll(true)
  }

  closeAll() {
    this.logger.debug('Closing all accordionGroups : %O', this.accordionGroups)
    this.toggleAll(false)
  }

  private toggleAll(toggle: boolean) {
    this.accordionGroups.forEach(group => {
      group.isOpened = toggle
    })
  }
}
