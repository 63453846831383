import moment from 'moment-es6'
import { Observable, Subject } from 'rxjs'
import { NotificationCallbackType } from './notification-callback-type.enum'
import { NotificationMessageOptions } from './notification-message-options.model'
import { NotificationSeverity } from './notification-severity.enum'

export class NotificationMessage {
  subject: Subject<NotificationCallbackType>
  id: string
  facet: string | undefined
  key: string | undefined
  interpolations: any
  severity: NotificationSeverity | undefined
  route: string | undefined
  routeKey: string | undefined
  routeInterpolations: any
  routeFragment: string | undefined
  createdAt: moment.Moment
  primaryKey: string | undefined
  primaryInterpolations: any
  secondaryKey: string | undefined
  secondaryInterpolations: any
  hasActions: boolean
  sticky: boolean | undefined
  duration: number | undefined
  clickToClose: boolean | undefined
  showProgressBar: boolean | undefined
  pauseOnHover: boolean | undefined

  constructor(private showFn: (message: NotificationMessage) => Observable<NotificationCallbackType>) {
    this.subject = new Subject<NotificationCallbackType>()
  }

  withOptions(options: NotificationMessageOptions): NotificationMessage {
    this.facet = options.facet
    this.key = options.key
    this.interpolations = options.interpolations
    this.severity = options.severity
    this.route = options.route
    this.routeKey = options.routeKey
    this.routeInterpolations = options.routeInterpolations
    this.routeFragment = options.routeFragment
    this.primaryKey = options.primaryKey
    this.primaryInterpolations = options.primaryInterpolations
    this.secondaryInterpolations = options.secondaryInterpolations
    this.secondaryKey = options.secondaryKey
    this.sticky = options.sticky
    this.duration = options.duration
    this.clickToClose = options.clickToClose
    this.showProgressBar = options.showProgressBar
    this.pauseOnHover = options.pauseOnHover

    return this
  }

  setFacet(facet?: string): NotificationMessage {
    this.facet = facet
    return this
  }

  setId(id: string): NotificationMessage {
    this.id = id
    return this
  }

  setKey(key: string, interpolations?: any): NotificationMessage {
    this.key = key
    this.interpolations = interpolations
    return this
  }

  setSeverity(severity: NotificationSeverity): NotificationMessage {
    this.severity = severity
    return this
  }

  setPrimaryKey(primaryKey: string, interpolations?: any): NotificationMessage {
    this.primaryKey = primaryKey
    this.primaryInterpolations = interpolations
    return this
  }

  setSecondaryKey(secondaryKey: string, interpolations?: any): NotificationMessage {
    this.secondaryKey = secondaryKey
    this.secondaryInterpolations = interpolations
    return this
  }

  setRoute(route: string): NotificationMessage {
    this.route = route
    return this
  }

  setRouteKey(routeKey: string, interpolations?: any): NotificationMessage {
    this.routeKey = routeKey
    this.routeInterpolations = interpolations
    return this
  }

  setRouteFragment(fragment: string): NotificationMessage {
    this.routeFragment = fragment
    return this
  }

  success(): NotificationMessage {
    this.severity = NotificationSeverity.SUCCESS
    return this
  }

  error(): NotificationMessage {
    this.severity = NotificationSeverity.ERROR
    return this
  }

  warn(): NotificationMessage {
    this.severity = NotificationSeverity.WARNING
    return this
  }

  setSticky(sticky: boolean): NotificationMessage {
    this.sticky = sticky
    return this
  }

  setDuration(duration: number): NotificationMessage {
    this.duration = duration
    return this
  }

  setClickToClose(clickToClose: boolean): NotificationMessage {
    this.clickToClose = clickToClose
    return this
  }

  setShowProgressBar(showProgressBar: boolean): NotificationMessage {
    this.showProgressBar = showProgressBar
    return this
  }

  setPauseOnHover(pauseOnHover: boolean): NotificationMessage {
    this.pauseOnHover = pauseOnHover
    return this
  }

  show(): Observable<NotificationCallbackType> {
    return this.showFn(this)
  }
}
