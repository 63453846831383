import { ModuleWithProviders, NgModule } from '@angular/core'
import { ScrollToService } from './scroll-to.service'
import { RouterScrollService } from './router-scroll-to.service'

@NgModule({})
export class ScrollToModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ScrollToModule,
      providers: [ScrollToService, RouterScrollService],
    }
  }
}
