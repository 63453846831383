import { isString } from 'lodash'
import moment from 'moment-es6'
import { REGEX_DATE_STRING, REGEX_DATE_STRING_WITH_MS } from './regex'

export function convertDates(value, key, object) {
  if (isString(value) && (REGEX_DATE_STRING.test(value) || REGEX_DATE_STRING_WITH_MS.test(value))) {
    return moment(value)
  } else {
    return value
  }
}
