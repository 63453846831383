import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NotificationCenterComponent } from './notification-center.component'
import { NotificationCenterService } from './notification-center.service'
import { NotificationComponent } from './notification.component'
import { IconModule } from '../icon/icon.module'
import { LocaleModule, LoggerModule } from '@shiftcoders/core'

@NgModule({
  imports: [CommonModule, IconModule, LoggerModule, RouterModule, LocaleModule],
  declarations: [NotificationComponent, NotificationCenterComponent],
  exports: [NotificationCenterComponent, NotificationComponent],
})
export class NotificationCenterModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: NotificationCenterModule,
      providers: [NotificationCenterService],
    }
  }
}
