import { ModuleWithProviders, NgModule } from '@angular/core'
import { DeviceInfoService } from './device-info.service'

@NgModule({})
export class DeviceInfoModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DeviceInfoModule,
      providers: [DeviceInfoService],
    }
  }
}
