import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LoadingIndicatorComponent } from './loading-indicator.component'

@NgModule({
  imports: [CommonModule],
  declarations: [LoadingIndicatorComponent],
  exports: [LoadingIndicatorComponent],
})
export class LoadingIndicatorModule {}
