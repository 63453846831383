import { NgModule } from '@angular/core'
import { PlatformInformationDirective } from './platform-information.directive'
import { DeviceInfoModule } from '@shiftcoders/core'

@NgModule({
  imports: [DeviceInfoModule],
  declarations: [PlatformInformationDirective],
  exports: [PlatformInformationDirective],
})
export class PlatformInformationModule {}
