import { Injectable } from '@angular/core'
import { isEqualWithMoment, Logger, LoggerService } from '@shiftcoders/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

export type HeaderBackgroundColor = 'purple' | 'blue' | 'turquoise'

export enum ActionType {
  NAVIGATE_STEP_INDICATOR_ACTION,
  NAVIGATE_BACK_TO,
}

export interface StepIndicator {
  backgroundColor: HeaderBackgroundColor
  stepLabel: StepLabels
  stepIndicatorActionKey: string
  stepIndicatorActionIcon: string
}

export interface HeaderState {
  showSurveyPreviewBar: boolean

  /*
   * base:
   * logo            |      ---          | avatar
   *
   * navigateTo:
   * a label        |      ---          | avatar
   *
   * stepIndicator:
   * circle icon    |  step indicator   | avatar
   *
   */
  facet: 'base' | 'navigateTo' | 'stepIndicator'

  navigateItem: null | { labelKey: String; svgUrl: String }

  stepIndicator: null | StepIndicator
}

export type StepLabel = string | { key: string; interpolateValues: { [key: string]: string } }

export interface StepLabels {
  part_1: StepLabel
  part_2: StepLabel
  part_3: StepLabel
}

const initState: HeaderState = {
  showSurveyPreviewBar: false,
  facet: 'base',
  stepIndicator: null,
  navigateItem: null,
}

@Injectable()
export class HeaderService {
  private logger: Logger

  private stateSubject = new BehaviorSubject<HeaderState>(initState)
  readonly state$: Observable<HeaderState> = this.stateSubject
    .asObservable()
    .pipe(distinctUntilChanged(isEqualWithMoment))

  get state(): HeaderState {
    return this.stateSubject.value
  }

  private actionSubject = new Subject<ActionType | any>()

  readonly actions$: Observable<ActionType | any> = this.actionSubject.asObservable()

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('HeaderService')
  }

  emitAction(action: ActionType | any): void {
    this.logger.debug('emit action: ', action)
    this.actionSubject.next(action)
  }

  activateFacetBase() {
    const newState: HeaderState = {
      ...(<HeaderState>this.stateSubject.value), // current state of preview bar
      facet: 'base',
      stepIndicator: null,
      navigateItem: null,
    }
    this.stateSubject.next(newState)
  }

  activateFacetStepIndicator(param: StepIndicator) {
    const newState: HeaderState = {
      ...(<HeaderState>this.stateSubject.value), // current state of preview bar
      facet: 'stepIndicator',
      navigateItem: null,
      stepIndicator: param,
    }
    this.stateSubject.next(newState)
  }

  activateFacetNavigateTo(labelKey: string, svgUrl: string) {
    const newState: HeaderState = {
      ...(<HeaderState>this.stateSubject.value), // current state of preview bar
      facet: 'navigateTo',
      stepIndicator: null,
      navigateItem: { labelKey, svgUrl },
    }
    this.stateSubject.next(newState)
  }

  hidePreviewBar() {
    const newState = { ...(<HeaderState>this.stateSubject.value), showSurveyPreviewBar: false }
    this.stateSubject.next(newState)
  }

  showSurveyPreviewBar() {
    const newState = { ...(<HeaderState>this.stateSubject.value), showSurveyPreviewBar: true }
    this.stateSubject.next(newState)
  }
}
