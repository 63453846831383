import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment-timezone'

@Pipe({
  name: 'momentFormat',
})
export class MomentFormatPipe implements PipeTransform {
  transform(date: moment.Moment, format: string = 'L') {
    let formatted: string | undefined
    if (moment.isMoment(date)) {
      formatted = date.format(format)
    } else {
      // TODO provide possibility to get the current environment
      // if (!environment.production) {
      //   throw new Error('given date is not a moment instance')
      // }
    }

    return formatted
  }
}
