// FIXME TSLINT
// tslint:disable:max-classes-per-file
import { EventEmitter, Injectable, Optional } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { map, publishLast, refCount } from 'rxjs/operators'
import { Logger } from '../logger/logger'
import { LoggerService } from '../logger/logger.service'
import { Parser } from './parser/parser.service'
import { TranslateLoader } from './loader/translate-loader'

export abstract class MissingTranslationHandler {
  /**
   * A function that handles missing translations.
   * If it returns a value, then this value is used.
   * If it return an observable, the value returned by this observable will be used (except if the method was "instant").
   * If it doesn't return then the key will be used as a value
   *
   * @param key the missing key
   * @returns a value or an observable
   */
  abstract handle(key: string): string
}

@Injectable()
export class TranslateService {
  /**
   * The lang currently used
   */
  currentLang: string

  /**
   * An EventEmitter to listen to lang changes events
   */
  onLangChange: EventEmitter<string> = new EventEmitter<string>()

  private pending?: Observable<string>

  private defaultLang: string

  private logger: Logger

  constructor(
    loggerService: LoggerService,
    public currentLoader: TranslateLoader,
    @Optional() private missingTranslationHandler: MissingTranslationHandler,
    private parser: Parser
  ) {
    this.logger = loggerService.getInstance('TranslateService')
    this.logger.debug('constructor')
  }

  /**
   * Sets the default language to use as a fallback
   * @param lang
   */
  setDefaultLang(lang: string): void {
    this.defaultLang = lang
  }

  /**
   * Changes the lang currently used
   */
  use(lang: string): void {
    this.logger.debug('Use lang called')

    this.getTranslation(lang).subscribe(
      () => {
        this.logger.debug('Translation file for lang event --> ', lang)
        this.langChanged(lang)
      },
      err => {
        this.logger.error(err)
      },
      () => {
        this.logger.debug('Get translation completed')
      }
    )
  }

  /**
   * Gets the translated value of a key (or an array of keys)
   * @param key
   * @param interpolateParams
   * @returns {any} the translated key, or an object of translated keys
   */
  get(key: string, interpolateParams?: { [key: string]: string }): Observable<string>
  get(keys: string[], interpolateParams?: { [key: string]: string }): Observable<string[]>

  get(key: string | string[], interpolateParams?: { [key: string]: string }): Observable<string | string[]> {
    if (!key) {
      throw new Error('Parameter "key" required')
    }

    if (this.pending) {
      return this.pending.pipe(
        map(translations => {
          return this.getParsedResult(translations, key, interpolateParams)
        })
      )
    } else {
      throw new Error('no translation was loaded yet')
    }
  }

  /**
   * Gets an object of translations for a given language with the current loader
   */
  private getTranslation(lang: string): Observable<any> {
    // get translations (replay stream)
    this.pending = this.currentLoader.getTranslation(lang).pipe(
      publishLast(),
      refCount()
    )
    return this.pending
  }

  /**
   * Returns the parsed result of the translations
   */
  private getParsedResult(
    translations: any,
    key: string | string[],
    interpolateParams?: { [key: string]: string }
  ): string | string[] {
    if (key instanceof Array) {
      return key.map((toTranslate, idx) => this.parseKey(translations, toTranslate, interpolateParams))
    } else {
      return this.parseKey(translations, key, interpolateParams)
    }
  }

  private langChanged(lang: string): void {
    this.currentLang = lang
    this.onLangChange.emit(lang)
  }

  private parseKey(translations: any, key: string, interpolateParams?: { [key: string]: string }): string {
    const compiled = this.parser.process(translations, key, interpolateParams)

    let res: string
    if (compiled) {
      res = compiled
    } else {
      if (this.missingTranslationHandler) {
        res = this.missingTranslationHandler.handle(key)
      } else {
        res = key
      }
    }

    return res || key
  }
}
