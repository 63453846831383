import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { RuntimeConfiguration } from '../runtime-configuration/runtime-configuration'
import { HttpError, isHttpError } from './http-error.model'
import { HttpErrorCode } from './http-error-code'
import { convertDates } from '../static-utils/convert-dates.function'
import { mapValuesDeep } from '../static-utils/map-values-deep.function'
import { HttpErrorService } from './http-error.service'

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private runtimeConfiguration: RuntimeConfiguration, private httpErrorService: HttpErrorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpErrorCode>> {
    return next.handle(req).pipe(
      catchError(
        (errorResponse: HttpErrorResponse, caught: Observable<any>): Observable<HttpError<HttpErrorCode>> => {
          const httpError = new HttpError<any>()
          httpError.headers = errorResponse.headers
          httpError.status = errorResponse.status
          // only for responses from our own backend(s)
          if (this.runtimeConfiguration.isMicroServiceUrl(req.url) && isHttpError(errorResponse.error)) {
            httpError.code = errorResponse.error.code
            httpError.message = errorResponse.error.message
            httpError.details = mapValuesDeep(errorResponse.error.details, convertDates)
          } else {
            // external failing calls
            httpError.code = HttpErrorCode.DEFAULT_EXTERNAL_EXCEPTION
            httpError.message = `Http Error: Message: ${errorResponse.message}, ${errorResponse.error}`
          }
          this.httpErrorService.handleDefaultErrors(httpError)
          return throwError(httpError)
        }
      )
    )
  }
}
