import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ExpandableComponent } from './expandable.component'

@NgModule({
  imports: [CommonModule],
  declarations: [ExpandableComponent],
  exports: [ExpandableComponent],
})
export class ExpandableModule {}
