import { Injectable } from '@angular/core'
import { Observable, ReplaySubject } from 'rxjs'
import { NotificationCallbackType } from './notification-callback-type.enum'
import { NotificationCenterCommand } from './notification-center-command.enum'
import { NotificationEvent } from './notification-center.event'
import { NotificationMessageOptions } from './notification-message-options.model'
import { NotificationMessage } from './notification-message.model'
import { NotificationSeverity } from './notification-severity.enum'
import { Logger, LoggerService } from '@shiftcoders/core'

@Injectable()
export class NotificationCenterService {
  /*
   * we need to use a replay subject because we want to make sure all the notifications created before the
   * notification-center.component ngInit was called, will be displayed
   */
  emitter: ReplaySubject<NotificationEvent> = new ReplaySubject<NotificationEvent>(5)

  private logger: Logger

  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getInstance('NotificationCenterService')
  }

  success(
    key: string,
    interpolations?: any,
    options?: NotificationMessageOptions
  ): Observable<NotificationCallbackType> {
    const builder: NotificationMessage = this.builder()
      .setKey(key, interpolations)
      .setSeverity(NotificationSeverity.SUCCESS)

    if (options !== undefined) {
      builder.withOptions(options)
    }

    return builder.show()
  }

  error(key: string, interpolations?: any, options?: NotificationMessageOptions): Observable<NotificationCallbackType> {
    const builder: NotificationMessage = this.builder()
      .setKey(key, interpolations)
      .setSeverity(NotificationSeverity.ERROR)

    if (options !== undefined) {
      builder.withOptions(options)
    }

    return builder.show()
  }

  warn(key: string, interpolations?: any, options?: NotificationMessageOptions): Observable<NotificationCallbackType> {
    const builder: NotificationMessage = this.builder()
      .setKey(key, interpolations)
      .setSeverity(NotificationSeverity.WARNING)

    if (options !== undefined) {
      builder.withOptions(options)
    }

    return builder.show()
  }

  saved(): Observable<NotificationCallbackType> {
    return this.builder()
      .setDuration(2000)
      .setFacet('compact')
      .setKey('COMMONS.SAVED')
      .setClickToClose(false)
      .setShowProgressBar(false)
      .success()
      .show()
  }

  builder(): NotificationMessage {
    return new NotificationMessage(this.addMessage)
  }

  /**
   * If an id is provided the matching message will be removed, if id is not provided all messages will be removed
   * @param id {string} optional
   */
  remove(id?: string): void {
    if (id) {
      this.emitter.next({ command: NotificationCenterCommand.REMOVE, messageId: id })
    } else {
      this.emitter.next({ command: NotificationCenterCommand.REMOVE_ALL })
    }
  }

  private addMessage = (message: NotificationMessage): Observable<NotificationCallbackType> => {
    message.hasActions =
      message.route !== undefined || message.primaryKey !== undefined || message.secondaryKey !== undefined

    // if no custom id was defined, generate one for internal usage
    if (message.id === undefined) {
      message.id = Math.random()
        .toString(36)
        .substring(3)
    }

    const event: NotificationEvent = {
      command: NotificationCenterCommand.ADD,
      message,
    }

    this.emitter.next(event)
    return message.subject.asObservable()
  }
}
