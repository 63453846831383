import {
  AfterContentInit,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { isEqual } from 'lodash'
import { merge, Observable, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators'
import { PagedDataSource } from './data-source/paged-data-source'
import { ScListComponent } from './list/list.component'
import { ScSort, Sort } from './sort/sort'
import { ScTable } from './table/table.component'
import { TitleBarComponent } from './title-bar/title-bar.component'
import { DeviceInfoService, Logger, LoggerService } from '@shiftcoders/core'

let _uniqueIdCounter = 0

/*
 * TODO MISSING FEATURES
 *
 * - fetch new filter counts when a criteria changes (search, other filter)
 */
@Component({
  selector: 'sc-data-view',
  template: `<!-- scroll to top anchor -->
<div [id]="id" *ngIf="showScrollToTop"></div>

<!-- title-bar -->
<ng-content select="sc-title-bar"></ng-content>

<!-- loader -->
<div class="data-view-container">
  <div class="data-view-loading" *ngIf="!showScrollToTop && (debouncedLoading$ | async)">
    <sc-loading-indicator size="xl"></sc-loading-indicator>
  </div>

  <!-- table desktop view -->
  <ng-content *ngIf="!isXs" select="sc-table"></ng-content>

  <!-- list mobile view -->
  <ng-content *ngIf="isXs" select="sc-list"></ng-content>


  <!-- auto load more trigger -->
  <div *ngIf="hasMore && autoLoadMore" [scElementVisible]="126" (visible)="onElementVisible()"></div>
  <!-- load more button -->
  <div class="state-button" *ngIf="hasMore && !autoLoadMore">
    <sc-state-button facet="secondary"
                     labelTranslate="COMMONS.LOAD_MORE"
                     (scsClick)="dataSource.nextPage()"
                     [loading$]="dataSource?.loading$"></sc-state-button>
  </div>

  <ng-container *ngIf="dataSource">
    <!-- scroll to top button -->
    <sc-scroll-to-top [elementId]="id" *ngIf="showScrollToTop && ((dataSource.count$ | async) > 0)"></sc-scroll-to-top>

    <!-- no data to display -->
    <div *ngIf="(dataSource.count$ | async) === 0" class="default-empty-container">
      <p translate="COMMONS.TABLE_EMPTY_DEFAULT"></p>
    </div>
  </ng-container>
</div>


`,
  styles: [`:host{display:block;text-align:left}:host .state-button{text-align:center;margin-top:24px}:host .default-empty-container{min-height:48px;border-bottom:1px solid #eee;text-align:center;font-family:line-to-circular,"Helvetica Neue",Helvetica,Arial,sans-serif;font-weight:400;font-style:normal;font-size:1.2rem;line-height:1.33333;display:flex;justify-content:center;align-items:center}:host .data-view-container{position:relative}:host .data-view-container .data-view-loading{position:absolute;top:0;right:0;left:0;bottom:0;width:100%;height:100%;background-color:rgba(251,251,251,.8);z-index:1;display:flex;align-items:center;justify-content:center}`],
})
export class DataViewComponent<T> implements OnInit, OnDestroy, AfterContentInit, OnChanges {
  isXs: boolean
  id = `sc-data-view${_uniqueIdCounter++}`

  private logger: Logger
  private onDestroy: Subject<void> = new Subject()
  private loading = false

  @Input() dataSource?: PagedDataSource<T>
  @Input() autoLoadMore: boolean
  @Input() showScrollToTop: boolean

  @ContentChild(TitleBarComponent) titleBar?: TitleBarComponent
  @ContentChild(ScTable) tableView?: ScTable<any>
  @ContentChild(ScListComponent) listView?: ScListComponent<any>
  @ContentChild(ScSort) sort?: ScSort

  hasMore: boolean

  debouncedLoading$: Observable<boolean>

  // TODO IMPROVE do some checks sorting online in titlebar but not on table, etc.
  constructor(loggerService: LoggerService, deviceInfo: DeviceInfoService) {
    this.logger = loggerService.getInstance('DataViewComponent')
    deviceInfo.screenChanges.subscribe(s => {
      this.isXs = s.width === 'xs'
    })
  }

  ngOnInit() {
    // this.logger.debug('ngOnInit', this.titleBar)
  }

  ngOnDestroy(): void {
    // this.dataSource.disconnect(null)
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  ngAfterContentInit(): void {
    // this.logger.debug('ngAfterContentInit', this.titleBar)
    this.setupDataSource()

    /*
     * listen for changes inside the titlebar which are data relevant
     */
    if (this.titleBar) {
      this.titleBar.change.subscribe(value => {
        this.setupDataSource()
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('dataSource' in changes && !changes['dataSource'].firstChange) {
      this.setupDataSource()
    }
  }

  /*
   * trigger auto-scroll when element is scrolled into view
   */
  onElementVisible() {
    if (this.hasMore && !this.loading) {
      this.dataSource.nextPage()
    }
  }

  private setupDataSource(): void {
    if (this.dataSource && this.dataSource instanceof PagedDataSource) {
      // FIXME TYPINGS
      let search$: Observable<any>
      let filter$: Observable<any>
      let sort$: Observable<any>
      if (this.titleBar) {
        search$ = this.titleBar.search$
        filter$ = this.titleBar.filter$
      }

      /*
       * register sort changes on data source (can be either be triggered from table headers of select)
       */
      if (this.sort || (this.titleBar && this.titleBar.sort$)) {
        const obs: Array<Observable<Sort>> = []

        if (this.sort) {
          obs.push(this.sort.sortChange)
        }

        if (this.titleBar && this.titleBar.sort$) {
          obs.push(this.titleBar.sort$)
        }

        if (obs.length === 1) {
          sort$ = obs[0]
        } else {
          sort$ = merge(...obs).pipe(
            // sort values with direction none are treated same as null values for now
            map<Sort | null, Sort | null>(sort => (sort && sort.direction === 'NONE' ? null : sort)),
            distinctUntilChanged(isEqual),
            tap(values => {
              this.logger.debug('sort values changed', values)
            })
          )
        }
      }

      this.dataSource.init({ search$, filter$, sort$ })

      /*
       * synchronize sort from table headers and sort in title bar
       */

      // from sort to titlebar
      if (this.sort && this.titleBar) {
        this.titleBar.syncWithSort(this.sort.sortChange)
      }

      // from titlebar to sort
      if (this.sort && this.titleBar && this.titleBar.sort$) {
        this.sort.syncWithSort(this.titleBar.sort$)
      }

      this.dataSource.hasMore$
        .pipe(
          takeUntil(this.onDestroy),
          filter(hasMoreState => {
            return !hasMoreState.pending
          })
        )
        .subscribe(hasMoreState => (this.hasMore = hasMoreState.data))

      this.debouncedLoading$ = this.dataSource.loading$.asObservable().pipe(debounceTime(250))
      this.dataSource.loading$
        .asObservable()
        .pipe(takeUntil(this.onDestroy))
        .subscribe(loading => {
          this.loading = loading
        })
    }

    /*
     * register the data source with table view
     */
    if (this.tableView) {
      this.tableView.dataSource = this.dataSource
    }

    /*
     * register the data source with list view
     */
    if (this.listView) {
      this.listView.dataSource = this.dataSource
    }
  }
}
