import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Logger, LoggerService } from '@maprix/core'
import { Subscription } from 'rxjs'
import { Enums } from '../../helpers/enums'
import { FeedbackStyle } from '../../models/prototype/feedback-style.enum'
import { Prototype } from '../../models/prototype/prototype.model'
import { AuthUser } from '../../models/user/auth-user'
import { AuthService } from '../../services/auth/auth.service'

enum FeedbackStyleIncludingOld {
  COMMUNITY,
  GROUP,
  CRITERIA,
  EXTERNAL_GROUP,
  ENTERPRISE_PUBLIC_COMMUNITY,
}

@Component({
  selector: 'scs-prototype-avatar',
  templateUrl: './prototype-avatar.component.html',
  styleUrls: ['./prototype-avatar.component.scss'],
})
export class PrototypeAvatarComponent implements OnInit, OnDestroy {
  @Input() prototype: Prototype
  @Input() guestMode: boolean

  isOwner = false
  feedbackStyleView: any = FeedbackStyle
  prototypeTypeTranslation: string

  private logger: Logger
  private authUserSubscription: Subscription

  constructor(loggerService: LoggerService, private router: Router, private authService: AuthService) {
    this.logger = loggerService.getInstance('PrototypeAvatarComponent')
  }

  ngOnInit() {
    this.prototypeTypeTranslation = `ENUMS.FEEDBACK_STYLE.${Enums.fromNumber(
      FeedbackStyleIncludingOld,
      this.prototype.feedbackStyle
    )}`

    if (this.authService.isLoggedIn()) {
      this.authUserSubscription = this.authService.authUserChanges.subscribe((authUser: AuthUser | null) => {
        if (authUser !== null && this.prototype.owner !== undefined && this.prototype.owner.id !== undefined) {
          this.isOwner = this.prototype.owner.id === authUser.subId
        } else {
          this.isOwner = false
        }
      })
    }
  }

  ngOnDestroy() {
    if (this.authUserSubscription) {
      this.authUserSubscription.unsubscribe()
    }
  }

  showColon(): boolean {
    return (
      (this.isOwner &&
        this.prototype.feedbackStyle === FeedbackStyle.GROUP &&
        this.prototype.groups &&
        this.prototype.groups.length > 0) ||
      (this.isOwner &&
        this.prototype.feedbackStyle === FeedbackStyle.CRITERIA &&
        this.prototype.criterias &&
        this.prototype.criterias.length > 0)
    )
  }

  navigateToProfile() {
    if (!this.guestMode) {
      this.router.navigate(['profile', this.prototype.owner.id])
    }
  }
}
