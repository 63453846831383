import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { FormControlModule } from '../form-control/form-control.module'
import { FileSelectorComponent } from './file-selector.component'

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormControlModule],
  declarations: [FileSelectorComponent],
  exports: [FileSelectorComponent],
})
export class FileSelectorModule {}
