import { Directive, Input, TemplateRef } from '@angular/core'
import { TitleBarSectionName } from './models/title-bar-section-name'

@Directive({
  selector: '[scTitleBarSection]',
})
export class TitleBarSectionDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('scTitleBarSection') sectionName: TitleBarSectionName

  constructor(public templateRef: TemplateRef<any>) {}
}
