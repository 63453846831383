import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { fromEvent, Subject } from 'rxjs'
import { debounceTime, startWith, takeUntil } from 'rxjs/operators'
import { WindowRef } from '@shiftcoders/core'

// FIXME REFACTOR INTO DIRECTIVES
@Directive({
  selector: '[scElementVisible]',
})
export class ElementVisibleDirective implements AfterViewInit, OnDestroy {
  @Output() visible: EventEmitter<void>

  @Input() scElementVisible: number // offset to the actual element

  private elementPos: number
  private elementHeight: number

  private scrollPos: number
  private windowHeight: number

  private window: Window
  private bodyEl: HTMLElement

  private onDestroy: Subject<void> = new Subject()

  constructor(windowRef: WindowRef, private element: ElementRef) {
    this.window = windowRef.nativeWindow
    this.bodyEl = this.window.document.body
    this.visible = new EventEmitter<void>()
  }

  ngAfterViewInit() {
    this.subscribe()
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  private saveDimensions() {
    const offsetDistance: number = this.scElementVisible && this.scElementVisible > 0 ? this.scElementVisible : 0
    this.elementPos = this.getOffsetTop(this.element.nativeElement)
    if (this.elementPos - offsetDistance >= 0) {
      this.elementPos = this.elementPos - offsetDistance
    }
    this.elementHeight = this.element.nativeElement.offsetHeight
    this.windowHeight = this.window.innerHeight || this.bodyEl.clientHeight
  }

  private saveScrollPos() {
    this.scrollPos = this.window.scrollY || this.window.pageYOffset
  }

  private getOffsetTop(element: any) {
    let offsetTop = element.offsetTop || 0
    if (element.offsetParent) {
      offsetTop += this.getOffsetTop(element.offsetParent)
    }
    return offsetTop
  }

  private checkVisibility() {
    if (this.isVisible()) {
      // double check dimensions due to async loaded contents
      this.saveDimensions()
      if (this.isVisible()) {
        this.visible.emit()
      }
    }
  }

  private isVisible() {
    return (
      this.scrollPos >= this.elementPos || this.scrollPos + this.windowHeight >= this.elementPos + this.elementHeight
    )
  }

  private subscribe() {
    fromEvent(this.window, 'scroll')
      .pipe(
        takeUntil(this.onDestroy),
        debounceTime(250),
        startWith(null)
      )
      .subscribe(event => {
        this.saveScrollPos()
        this.checkVisibility()
      })

    fromEvent(this.window, 'resize')
      .pipe(
        takeUntil(this.onDestroy),
        debounceTime(250),
        startWith(null)
      )
      .subscribe(() => {
        this.saveDimensions()
        this.checkVisibility()
      })
  }
}
