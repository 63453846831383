import { OverlayModule } from '@angular/cdk/overlay'
import { PortalModule } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { Modal2CloseDirective } from './modal2-close.directive'
import { Modal2Component } from './modal2.component'
import { Modal2Service } from './modal2.service'
import { IconModule } from '../icon/icon.module'
import { LoggerModule, LocaleModule } from '@shiftcoders/core'

@NgModule({
  imports: [
    // ng
    CommonModule,
    // cdk
    OverlayModule,
    PortalModule,
    // sc
    IconModule,
    LoggerModule,
    LocaleModule,
  ],
  declarations: [Modal2Component, Modal2CloseDirective],
  entryComponents: [Modal2Component],
  exports: [Modal2CloseDirective],
  providers: [Modal2Service],
})
export class Modal2Module {}
