import { Pipe, PipeTransform } from '@angular/core'
import { LocaleService } from './locale.service'

@Pipe({
  name: 'languageAware',
})
export class LanguageAwarePipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}

  transform(input: { [locale: string]: string }): string {
    return input[this.localeService.currentLocale]
  }
}
