// FIXME TSLINT
// tslint:disable
import { CdkTable, RowOutlet } from '@angular/cdk/table'
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  IterableDiffers,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core'

/**
 * Provides a handle for the table to grab the view container's ng-container to insert data rows.
 * @docs-private
 */
@Directive({ selector: '[rowOutlet]' })
export class DataRowOutlet implements RowOutlet {
  constructor(public viewContainer: ViewContainerRef, public elementRef: ElementRef) {}
}

/**
 * Provides a handle for the table to grab the view container's ng-container to insert the header.
 * @docs-private
 */
@Directive({ selector: '[headerRowOutlet]' })
export class HeaderRowOutlet implements RowOutlet {
  constructor(public viewContainer: ViewContainerRef, public elementRef: ElementRef) {}
}

@Component({
  selector: 'sc-table',
  exportAs: 'sc-table',
  template: `
    <ng-container headerRowOutlet></ng-container>
    <ng-container rowOutlet></ng-container>
    <ng-container footerRowOutlet></ng-container>
  `,
  styles: [`:host{display:block}:host(.hidden){display:none}:host /deep/ .sc-header-row{min-height:56px;border-bottom:1px solid #eee}:host /deep/ .sc-row{min-height:48px;border-bottom:1px solid #eee}:host /deep/ .sc-header-row,:host /deep/ .sc-row{display:flex;align-items:center;padding:0;box-sizing:border-box;page-break-inside:avoid}:host /deep/ .sc-header-row::after,:host /deep/ .sc-row::after{display:inline-block;min-height:inherit;content:''}:host /deep/ .sc-cell,:host /deep/ .sc-header-cell{flex:1;overflow:hidden;word-wrap:break-word}:host /deep/ .sc-cell{font-family:line-to-circular,"Helvetica Neue",Helvetica,Arial,sans-serif;font-weight:400;font-style:normal;font-size:1.4rem;line-height:1.28571;color:#333;padding-top:16px;padding-bottom:16px}@media (max-width:767px){:host /deep/ .sc-cell{font-size:1.2rem;line-height:1.33333}}:host /deep/ .sc-cell.sc-cell--gutter-small{padding-right:16px}@media (max-width:991px){:host /deep/ .sc-cell.sc-cell--gutter-small{padding-right:8px}}:host /deep/ .sc-cell.sc-cell--gutter-medium{padding-right:24px}@media (max-width:991px){:host /deep/ .sc-cell.sc-cell--gutter-medium{padding-right:16px}}:host /deep/ .sc-cell.sc-cell--gutter-big{padding-right:40px}@media (max-width:991px){:host /deep/ .sc-cell.sc-cell--gutter-big{padding-right:20px}}:host /deep/ .sc-header-cell{padding-right:16px;font-family:line-to-circular,"Helvetica Neue",Helvetica,Arial,sans-serif;font-weight:400;font-style:normal;font-size:1.2rem;line-height:1.33333;color:#8c8c8c;white-space:nowrap}`],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'sc-table',
  },
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// TODO INVESTIGATE when targeting es2015 in tsconfig.json (root) an error occurs
// because the Table has no deps when the create class is called
// tslint:disable-next-line:component-class-suffix
export class ScTable<T> extends CdkTable<T> {
  // TODO(andrewseguin): Remove this explicitly set constructor when the compiler knows how to
  // properly build the es6 version of the class. Currently sets ctorParameters to empty due to a
  // fixed bug.
  // https://github.com/angular/tsickle/pull/760 - tsickle PR that fixed this
  // https://github.com/angular/angular/pull/23531 - updates compiler-cli to fixed version
  constructor(
    protected _differs: IterableDiffers,
    protected _changeDetectorRef: ChangeDetectorRef,
    protected _elementRef: ElementRef,
    @Attribute('role') role: string
  ) {
    super(_differs, _changeDetectorRef, _elementRef, role)
  }
}
