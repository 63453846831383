import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'
import { isError, isString } from 'lodash'
import { ClientIdService } from '../client-id/client-id.service'
import { WindowRef } from '../window/browser-window.ref'
import { LogLevel } from './log-level.enum'
import { LOGGLY_CONFIG } from './loggly-config.injection-token'
import { LogglyConfig } from './loggly-config.model'
import { LogglyData } from './loggly-data.model'
import { RuntimeConfiguration } from '../runtime-configuration/runtime-configuration'
import { ProjectStage } from '../runtime-configuration/project-stage.enum'
import { Enums } from '../static-utils/enums'
import { HttpError, isHttpError } from '../http/http-error.model'
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the moment-es6 module with a default export.
// TODO: See if we can clean this up at some point.
import moment from 'moment-es6'

@Injectable()
export class LogglyService {
  private window: Window
  private enabled: boolean

  private static sprintFn(format) {
    const stringy = format[0]
    let i = 1

    return stringy.replace(/%((%)|s|i|f|o|c)/g, m => {
      // m is the matched format, e.g. %s, %i, etc.
      let val: string | number = ''
      if (m[2]) {
        val = m[2]
      } else {
        const val2 = format[i]
        // A switch statement so that the formatter can be extended. Default is %s
        switch (m) {
          case '%i':
            val = parseInt(val2, 10)
            break
          case '%f':
            val = parseFloat(val2)
            break
          case '%o':
            try {
              val = JSON.stringify(val2)
            } catch (e) {
              val = 'NOT SERIALIZABLE TO JSON'
              // tslint:disable-next-line:no-console
              console.warn('Make sure logged object is serializable to json for loggly %o', e)
            }
            break
          case '%c':
            val = ''
            break
          default:
            val = format[i]
        }
        i++
      }
      return val
    })
  }

  private static currentWindowResolution(window: Window): string {
    return `${window.innerWidth} x ${window.innerHeight}`
  }

  constructor(
    window: WindowRef,
    private runtimeConfiguration: RuntimeConfiguration,
    private clientIdService: ClientIdService,
    private httpClient: HttpClient,
    @Optional()
    @Inject(LOGGLY_CONFIG)
    private logglyConfig: LogglyConfig
  ) {
    this.window = window.nativeWindow
    // enable loggly logger on NONE dev envs and if a loggly config was provided
    this.enabled = this.logglyConfig && this.runtimeConfiguration.projectStage > ProjectStage.DEVELOPMENT
  }

  sendMessage(level: LogLevel, context: string, args: any[]) {
    const modifiedArguments: any[] = [].slice.call(args)
    const now: moment.Moment = moment()
    let msg: { message: string; name: string }

    const logglyData: LogglyData = {
      level: Enums.fromNumber(LogLevel, level),
      timestamp: now.toISOString(),
      logger: context,
      requestInfo: {
        clientId: this.clientIdService.getClientId(),
        userAgent: navigator.userAgent,
        resolution: LogglyService.currentWindowResolution(this.window),
        // replace with new URL('bla').pathname once ie11 is out
        url: this.window.location.pathname,
      },
      instanceInfo: {
        applicationName: this.runtimeConfiguration.appName,
        spaceName: Enums.fromNumber(ProjectStage, this.runtimeConfiguration.projectStage).toLowerCase(),
      },
    }

    if (isError(modifiedArguments[0])) {
      // error case
      msg = modifiedArguments[0]
      logglyData.message = msg.message
      logglyData.errorName = msg.name
      this.postToLoggly(logglyData)
    } else {
      // use a formatting function if supplied argument is string, will fail otherwise
      if (isString(modifiedArguments[0])) {
        logglyData.message = LogglyService.sprintFn(modifiedArguments)
        // check if HttpError (from us) and use specific code as error name and the stringify HttpError object as exception
        const httpError: HttpError<any> = modifiedArguments.find(value => isHttpError(value))
        if (httpError) {
          logglyData.errorName = <string>httpError.code
          logglyData.exception = JSON.stringify(httpError)
        }
        this.postToLoggly(logglyData)
      } else {
        // tslint:disable-next-line:no-console
        console.warn('make sure to supply string as first argument in log statements')
      }
    }
  }

  private postToLoggly(logglyData: LogglyData) {
    if (this.enabled) {
      const headers = new HttpHeaders().set('Content-Type', 'text/plain')

      this.httpClient
        .post(
          `${this.logglyConfig.collectorDomain}${this.runtimeConfiguration.logglyToken}${this.logglyConfig.tag}`,
          logglyData,
          { headers }
        )
        .subscribe(
          () => {
            // submitted to loggly
          },
          (error: any) => {
            // tslint:disable-next-line:no-console
            console.warn('log could not be submitted to loggly: ', error)
          }
        )
    }
  }
}
