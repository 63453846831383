import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'firstKey',
})
export class FirstKeyPipe implements PipeTransform {
  transform(obj: any) {
    if (!obj) {
      return null
    }

    const keys: string[] = Object.keys(obj)
    if (keys && keys.length > 0) {
      return keys[0]
    }
    return null
  }
}
