import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IconModule } from '../icon/icon.module'
import { StateButtonComponent } from './state-button.component'
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module'
import { LocaleModule, LoggerModule } from '@shiftcoders/core'

@NgModule({
  imports: [CommonModule, IconModule, LoggerModule, LocaleModule, LoadingIndicatorModule],
  declarations: [StateButtonComponent],
  exports: [StateButtonComponent],
})
export class StateButtonModule {}
