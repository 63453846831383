// TODO remove tslint disable rules
// tslint:disable:max-classes-per-file
import { CookieOptions } from './cookie-options.model'
import { LocalStorageOptionArgs } from './local-storage-option-args'
import { WebStorageType } from './web-storage-type.enum'

export class LocalStorageOptions {
  private static DEFAULT_PREFIX = 'app'

  prefix: string
  storageType: WebStorageType
  defaultToCookie: boolean
  cookieOptions: CookieOptions

  constructor(options: LocalStorageOptionArgs) {
    if (options.prefix === undefined) {
      this.prefix = LocalStorageOptions.DEFAULT_PREFIX
    } else {
      this.prefix = options.prefix
    }

    if (options.defaultToCookie === undefined) {
      this.defaultToCookie = true
    } else {
      this.defaultToCookie = options.defaultToCookie
    }

    if (options.storageType === undefined) {
      this.storageType = WebStorageType.LocalStorage
    } else {
      this.storageType = options.storageType
    }

    if (options.cookieOptions === undefined) {
      this.cookieOptions = {
        expiry: 30,
        path: '/',
        secure: true,
      }
    } else {
      this.cookieOptions = options.cookieOptions
    }
  }

  // returns the name of the web storage implementation
  getStorageTypeImplName(): string {
    switch (this.storageType) {
      case WebStorageType.Cookie:
        return 'cookie'
      case WebStorageType.LocalStorage:
        return 'localStorage'
      case WebStorageType.SessionStorage:
        return 'sessionStorage'
      default:
        return 'localStorage'
    }
  }

  merge(options: LocalStorageOptions): LocalStorageOptions {
    this.prefix = options.prefix !== undefined ? options.prefix : this.prefix
    this.storageType = options.storageType !== undefined ? options.storageType : this.storageType
    this.defaultToCookie = options.defaultToCookie !== undefined ? options.defaultToCookie : this.defaultToCookie
    this.cookieOptions = options.cookieOptions !== undefined ? options.cookieOptions : this.cookieOptions
    return options
  }
}

export class DefaultLocalStorageOptions extends LocalStorageOptions {
  constructor() {
    const defaultOptions: LocalStorageOptionArgs = {
      prefix: 'ls',
      defaultToCookie: true,
      storageType: WebStorageType.LocalStorage,
      cookieOptions: { expiry: 30, path: '/', secure: true },
    }

    super(defaultOptions)
  }
}
