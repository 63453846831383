import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Modal2Service } from '../modal2/modal2.service'
import { ConfirmDeleteComponent } from './confirm-delete-modal.component'

@Injectable()
export class ConfirmDeleteService {
  constructor(private modalService: Modal2Service) {}

  open(
    messageKey: string,
    messageValues: any,
    deleteFn: (...args: any[]) => Observable<any>,
    deleteFnArgs: any[],
    btnKey?: string
  ): Observable<boolean> {
    const data = {
      messageKey,
      messageValues,
      deleteFn,
      deleteFnArgs,
      btnKey,
    }

    return this.modalService.open<ConfirmDeleteComponent, boolean>(ConfirmDeleteComponent, { data }).beforeClosed
  }
}
