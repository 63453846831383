import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { DeviceInfoModule, LocaleModule, LoggerModule } from '@shiftcoders/core'
import { AvatarModule } from '../avatar/avatar.module'
import { IconModule } from '../icon/icon.module'
import { ScMenuModule } from '../menu/menu.module'
import { NotificationCenterModule } from '../notification-center/notification-center.module'
import { HeaderLogoDirective } from './header-logo.directive'
import { HeaderComponent } from './header.component'
import { HeaderService } from './header.service'
import { MobileNavBoardComponent } from './mobile-nav-board/mobile-nav-board.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DeviceInfoModule,
    LocaleModule,
    LoggerModule,
    AvatarModule,
    IconModule,
    NotificationCenterModule,
    ScMenuModule,
  ],
  declarations: [HeaderComponent, HeaderLogoDirective, MobileNavBoardComponent],
  entryComponents: [MobileNavBoardComponent],
  exports: [HeaderComponent, HeaderLogoDirective],
})
export class HeaderModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: HeaderModule,
      providers: [HeaderService],
    }
  }
}
