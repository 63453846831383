import { Injectable } from '@angular/core'
import { Parser } from './parser.service'

@Injectable()
export class DefaultParser extends Parser {
  private static templateMatcher = new RegExp('{{\\s?([^{}\\s]*)\\s?}}', 'g')

  constructor() {
    super()
  }

  process(target: any, key: string, interpolateParams?: { [key: string]: string }): string | null {
    return this.interpolate(this.getValue(target, key), interpolateParams)
  }

  /**
   * Gets a value from an object by composed key
   * parser.getValue({ key1: { keyA: 'valueI' }}, 'key1.keyA') ==> 'valueI'
   */
  private getValue(target: any, key: string): string {
    const keys = key.split('.')
    key = ''
    do {
      key += keys.shift()
      if (target !== undefined && target[key] !== undefined && (typeof target[key] === 'object' || !keys.length)) {
        target = target[key]
        key = ''
      } else if (!keys.length) {
        target = undefined
      } else {
        key += '.'
      }
    } while (keys.length)

    return target
  }

  /**
   * Interpolates a string to replace parameters
   * "This is a {{ key }}" ==> "This is a value", with params = { key: "value" }
   */
  private interpolate(expr: string, params?: any): string {
    if (typeof expr !== 'string' || !params) {
      return expr
    }

    return expr.replace(DefaultParser.templateMatcher, (substring: string, b: string) => {
      const r = this.getValue(params, b)
      return typeof r !== 'undefined' ? r : substring
    })
  }
}
