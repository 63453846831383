import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationEnd, Router } from '@angular/router'
import { isBoolean, isEmpty, without } from 'lodash'
import { Observable, of } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { Logger } from '../logger/logger'
import { LoggerService } from '../logger/logger.service'
import { TranslateService } from '../locale/translate.service'
import { WindowRef } from '../window/browser-window.ref'

export interface RouteData {
  skipMetaGeneration?: boolean | string[]
}

/*
 * Can be used to update any meta tag in the head part of the document. Right now the api just exposes methods to change
 * title, description, image.
 *
 * Internally we listen to the EndNavigation event on the Router service, to update the meta tag depending on active route.
 * (tags: image, url, description, title). The translation key for the title is generated using the path replacing '/' with '_' and
 * removing the params of last route so for example /profile/j3kla8-ajds8a-46asdf -> profile or profile/settings -> profile_settings
 *
 * If we need to provide some extra information in the title we set the skipMetaGeneration flag to false on route data, where we define
 * the routing. And define the title in the component where we wanna provide the extra information.
 */

// UNIVERSAL can't be used with universal
@Injectable()
export class MetaService {
  private static TAG_DESCRIPTION = 'description'

  // og tags
  private static TAG_OG_TITLE = 'og:title'
  private static TAG_OG_DESCRIPTION = 'og:description'
  private static TAG_OG_URL = 'og:url'
  private static TAG_OG_IMAGE = 'og:image'

  private options: { titleSuffix: string | undefined } = {
    titleSuffix: ' | Looping',
  }

  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private titleService: Title,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private windowRef: WindowRef,
    private router: Router,
    private meta: Meta
  ) {
    this.logger = loggerService.getInstance('MetaService')

    const navigationEndObs: Observable<Event> = this.router.events.pipe(filter(event => event instanceof NavigationEnd))

    const activatedRouteObs: Observable<ActivatedRouteSnapshot> = navigationEndObs.pipe(
      map(() => this.getLastChild(this.activatedRoute.snapshot))
    )

    // auto generate title key and update title
    activatedRouteObs
      .pipe(
        filter(current => !this.shouldSkip(current, 'title')),
        map(current => this.pathToTitleKey(current, windowRef.nativeWindow.location.pathname))
      )
      .subscribe(path => this.setTitle(path))

    // restore default image
    activatedRouteObs
      .pipe(filter(current => !this.shouldSkip(current, 'image')))
      .subscribe(() =>
        this.setImage(this.windowRef.nativeWindow.location.origin + '/assets/img/social-sharing/share_ent.png')
      )

    // restore default description
    activatedRouteObs
      .pipe(filter(current => !this.shouldSkip(current, 'description')))
      .subscribe(() => this.setDescription('DESCRIPTION'))

    // update url on route changes
    navigationEndObs.subscribe(() => {
      this.setTag(MetaService.TAG_OG_URL, location.toString())
    })
  }

  setTitle(path: string, dontTranslate?: boolean) {
    let obs: Observable<string>
    if (dontTranslate) {
      obs = of(path)
    } else {
      obs = <Observable<string>>this.translate.get('META.TITLE.' + path.toUpperCase())
    }

    obs.subscribe((title: string) => {
      if (this.options.titleSuffix) {
        title = title + this.options.titleSuffix
      }

      this.logger.debug('set title for path <%s> / <%s>', path, title)
      this.titleService.setTitle(title)
      // update the of tag for social crawlers too
      this.setTag(MetaService.TAG_OG_TITLE, title, true)
    })
  }

  resetDescription(): void {
    this.setDescription('DESCRIPTION')
  }

  setDescription(translateKey: string, dontTranslate?: boolean) {
    this.setTag(MetaService.TAG_OG_DESCRIPTION, translateKey, dontTranslate)
    this.setTag(MetaService.TAG_DESCRIPTION, translateKey, dontTranslate)
  }

  resetImage(): void {
    this.setImage(this.windowRef.nativeWindow.location.origin + '/assets/img/social-sharing/share_ent.png')
  }

  setImage(imageUrl: string) {
    this.setTag(MetaService.TAG_OG_IMAGE, imageUrl)
  }

  private setTag(tagName: string, value: string, dontTranslate?: boolean): void {
    if (tagName === MetaService.TAG_OG_URL || tagName === MetaService.TAG_OG_IMAGE) {
      dontTranslate = true
    }

    let obs: Observable<string>
    if (dontTranslate) {
      obs = of(value)
    } else {
      obs = <Observable<string>>this.translate.get('META.' + value)
    }

    obs.subscribe((value2: string) => {
      this.logger.debug('set tag <%s> to <%s>', tagName, value2)
      this.setOrCreateElement(tagName, value2)
    })
  }

  private setOrCreateElement(name: string, value: string): void {
    if (this.meta.getTag(`name="${name}"`)) {
      this.meta.updateTag({ name, content: value })
    } else if (this.meta.getTag(`property="${name}"`)) {
      this.meta.updateTag({ property: name, content: value })
    } else {
      this.meta.addTag({ property: name, content: value })
    }
  }

  /**
   * walks down the url tree and finds the last child to return the path param, right now we support just one route
   * with path param. If we have a complex path with than one param existing in the tree, the logic needs to be updated
   */
  private getLastChild(child: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (child.firstChild) {
      return this.getLastChild(child.firstChild)
    } else {
      return child
    }
  }

  private shouldSkip(current: ActivatedRouteSnapshot, metaProperty: 'title' | 'image' | 'description'): boolean {
    if (!isEmpty(current.data) && (<RouteData>current.data).skipMetaGeneration) {
      const skipMetaGeneration = (<RouteData>current.data).skipMetaGeneration
      if (isBoolean(skipMetaGeneration)) {
        return <boolean>skipMetaGeneration
      } else {
        // skip if the skipMetaGeneration array contains the current meta property
        return (<string[]>skipMetaGeneration).length !== 0 && skipMetaGeneration.indexOf(metaProperty) !== -1
      }
    } else {
      return false
    }
  }

  private pathToTitleKey(current: ActivatedRouteSnapshot, routePath: string): string {
    const params: any = current.params

    let path: string[] = routePath.split('/')

    // remove first element
    path.shift()

    if (!isEmpty(params)) {
      // remove all params from path tree
      path = without(path, params)
    }

    return path.join('_')
  }
}
