import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

/**
 * Provider Expression that allows sc-checkbox to register as a ControlValueAccessor. This allows it
 * to support [(ngModel)] and reactive forms.
 */
export const CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line:no-use-before-declare
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true,
}

let _uniqueIdCounter = 0

/**
 * How to use:
 *
 * # Within a form
 * Use either the reactive or non-reactive way with ngModel
 *
 * # Without Form
 * - Use the checked attribute to bind to (two way binding)
 * - React to the change event which fires whenever the checked value changes
 */
@Component({
  selector: 'sc-checkbox',
  template: `<div class="form-control__bool-wrap">
  <input [id]="id" class="form-control__input"
         type="checkbox"
         (click)="onClick($event)"
         (change)="onChange($event)"
         (focus)="onFocus()"
         (blur)="onBlur()"
         [checked]="checked"
         [disabled]="disabled"
         [required]="required">
  <label [attr.for]="id" class="form-control__label-wrap">
    <span class="form-control__box">
      <span class="form-control__box-icon icon icon--sm">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g fill="none" fill-rule="evenodd" transform="translate(0 -25)">
            <rect width="20" height="20" y="25"/>
            <polygon fill="currentColor" points="8 40 3 35.192 4.4 33.846 8 37.308 15.6 30 17 31.346"/>
          </g>
        </svg>
      </span>
    </span>
    <span class="form-control__label">
      <ng-content></ng-content>
    </span>
  </label>
</div>
`,
  providers: [CHECKBOX_VALUE_ACCESSOR],
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
  // two way binding for the checked attribute
  @Input() checked: boolean
  @Input() id = `sc-checkbox${_uniqueIdCounter++}`

  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>()

  private _disabled: boolean | null = false
  private _required: boolean | null = false

  @Input()
  get disabled() {
    return this._disabled
  }

  set disabled(value: boolean | null) {
    // any presence of a disabled attribute value should disable the component except for false value
    this._disabled = value != null && value !== false ? true : null
  }

  // does not make sense with type checkbox, how should we handle this?
  // see https://github.com/angular/angular/commit/2bf1bbc071f8280e56751184be7444d3e573a148
  // it makes sense when using directly with ngModel with the new validator
  @Input()
  get required() {
    return this._required
  }

  set required(value: boolean | null) {
    this._required = value != null && value !== false ? true : null
  }

  ngOnInit(): void {}

  /*
   * Value Accessor method implementations
   */
  writeValue(value: any): void {
    // make sure the value is a boolean -> converts null, undefined, etc. to false
    this.checked = !!value
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn
  }

  toggle() {
    this.checked = !this.checked
  }

  /*
   * whenever the checked state of the input changes
   */
  onChange(event: Event): void {
    // We always have to stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    // emit its event object to the `change` output.
    event.stopPropagation()

    if (!this.disabled) {
      this.toggle()

      this.emitChangeEvent()
    }
  }

  onClick(event: Event): void {
    // We have to stop propagation for click events on the visual hidden input element.
    // By default, when a user clicks on a label element, a generated click event will be
    // dispatched on the associated input element. Since we are using a label element as our
    // root container, the click event on the `checkbox` will be executed twice.
    // The real click event will bubble up, and the generated click event also tries to bubble up.
    // This will lead to multiple click events.
    // Preventing bubbling for the second event will solve that issue.
    event.stopPropagation()
  }

  onFocus(): void {}

  onBlur(): void {
    this.onTouchedFn()
  }

  // default implementation required for non form usage
  private onChangeFn: (value: boolean) => void = (value: boolean) => {}

  // default implementation required for non form usage
  private onTouchedFn: () => void = () => {}

  private emitChangeEvent() {
    this.onChangeFn(this.checked)
    this.checkedChange.emit(this.checked)
    this.change.emit(this.checked)
  }
}
