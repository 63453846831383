import { Injectable } from '@angular/core'
import { RuntimeConfiguration } from '../runtime-configuration/runtime-configuration'
import { LogLevel } from './log-level.enum'
import { LogglyLogger } from './loggly-logger'
import { LogglyService } from './loggly.service'

@Injectable()
export class LoggerService {
  // found no possible way to make level RxJs style with a subject -> subscriptions stayed alive -> memory leak!
  private level: LogLevel = LogLevel.DEBUG
  private loggers: { [key: string]: number } = {}

  constructor(private logglyService: LogglyService, private runtimeConfiguration: RuntimeConfiguration) {
    if (runtimeConfiguration.isProduction()) {
      this.level = LogLevel.OFF
    }
  }

  getInstance(name: string, color?: string) {
    if (this.loggers[name]) {
      name += '_' + this.loggers[name]++
    } else {
      this.loggers[name] = 2
    }

    return new LogglyLogger(this.getLevel, name, this.logglyService, color)
  }

  getLevel = () => {
    return this.level
  }
}
