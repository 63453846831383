import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IconModule } from '../icon/icon.module'
import { ScrollToTopComponent } from './scroll-to-top.component'
import { LocaleModule, LoggerModule, ScrollToModule, WindowModule } from '@shiftcoders/core'

@NgModule({
  imports: [CommonModule, LoggerModule, IconModule, WindowModule, ScrollToModule, LocaleModule],
  declarations: [ScrollToTopComponent],
  exports: [ScrollToTopComponent],
})
export class ScrollToTopModule {}
