import { ModuleWithProviders, NgModule } from '@angular/core'
import { MetaService } from './meta.service'

@NgModule({})
export class MetaModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: MetaModule,
      providers: [MetaService],
    }
  }
}
