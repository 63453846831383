import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { isEqual } from 'lodash'
import { Observable, of } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { FilterDefinition } from '../models/filter-definition'

@Component({
  selector: 'sc-select-filter',
  template: `<form [formGroup]="form">
  <!--

    mobile

  -->
  <div class="select">
    <sc-form-control class="form__field form-control--select"
                     [formControlField]="form.get('filter')">
      <ng-container *ngIf="filterDefinitions">
        <select [class.form-control__input--is-empty]="form.get('filter').value === ''"
                formControlName="filter">
          <option [ngValue]="null" disabled>{{placeholder ? (placeholder | translate) :
            ('DATA_VIEW.FILTERING.PLACEHOLDER' | translate) }}
          </option>
          <option *ngFor="let filter of filterDefinitions | async; let idx = index" [ngValue]="filter.value"
                  [translate]="filter.labelKey"
                  [translateValues]="filter.count">
          </option>
        </select>
      </ng-container>
      <span class="form-control__action form-control__action--select"></span>
    </sc-form-control>
  </div>

  <!--

    desktop

  -->
  <ul class="u-reset grid__row toggle-items">
    <li class="grid__col nav-filters__item" *ngFor="let filter of filterDefinitions | async">
      <button class="toggle-item"
              [attr.data-disabled]="form.get('filter').value === filter ? '' : null"
              [ngClass]="{'toggle-item--is-active': form.get('filter').value === filter.value }"
              (click)="setFilter(filter)">
        <span class="toggle-item__inner-wrap">
          <span class="toggle-item__title"
                [translate]="filter.labelKey">
          </span>
          <span class="toggle-item__count">
            {{ filter.count }}
          </span>
        </span>
      </button>
    </li>
  </ul>
</form>
`,
  styles: [`:host{display:inline-block}:host .nav-filters__container{padding:12px 40px}:host .nav-filters__items{margin:-6px -20px}:host .nav-filters__item{padding:6px 20px}:host .toggle-item{transition:color .2s ease}html :host .toggle-item{color:#8c8c8c}html.no-touchevents :host .toggle-item:focus,html.no-touchevents :host .toggle-item:hover,html.no-touchevents :host .toggle-item[data-focus]{color:#06c}html.touchevents :host .toggle-item:active,html.touchevents :host .toggle-item[data-focus]{color:#06c}html.no-touchevents :host .toggle-item.toggle-item--is-active:focus,html.no-touchevents :host .toggle-item.toggle-item--is-active:hover,html.no-touchevents :host .toggle-item.toggle-item--is-active[data-focus]{cursor:default;color:#333}html.touchevents :host .toggle-item.toggle-item--is-active:active,html.touchevents :host .toggle-item.toggle-item--is-active[data-focus]{cursor:default;color:#333}html :host .toggle-item.toggle-item--is-active{cursor:default;color:#333}:host .toggle-item__inner-wrap{display:flex;flex-flow:row nowrap;align-items:baseline}:host .toggle-item__title{font-family:line-to-circular,"Helvetica Neue",Helvetica,Arial,sans-serif;font-weight:700;font-style:normal}:host .toggle-item__count{font-family:line-to-circular,"Helvetica Neue",Helvetica,Arial,sans-serif;font-weight:500;font-style:normal;margin-left:4px;font-size:1.2rem}:host(.facet--always-show-select) .toggle-items{display:none}@media (min-width:992px){:host(.facet--base) .select{display:none}}@media (max-width:991px){:host(.facet--base) .toggle-items{display:none}}`],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '[class]': '(this.outsideClasses ? this.outsideClasses + " " : "") + "titlebar__col " + "facet--" + this.facet',
  },
})
export class SelectFilterComponent<T> implements OnInit, OnChanges {
  private static CONTROL_NAME = 'filter'

  // tslint:disable-next-line:no-input-rename
  @Input('class') outsideClasses: string

  @Input() facet: 'base' | 'always-show-select' = 'base'

  @Input() placeholder: string

  @Input() filterName: string

  @Input() startValue: T

  @Input()
  set filterDefinitions(filterDefinitions: Observable<Array<FilterDefinition<T>>> /* | FilterDefinition[] */) {
    if (filterDefinitions instanceof Observable) {
      this._filterDefinitions = filterDefinitions
    } else {
      this._filterDefinitions = of(<Array<FilterDefinition<T>>>filterDefinitions)
    }
  }

  get filterDefinitions() {
    return this._filterDefinitions
  }

  _filterDefinitions?: Observable<Array<FilterDefinition<T>>>

  form: FormGroup = new FormGroup({
    [SelectFilterComponent.CONTROL_NAME]: new FormControl(),
  })

  filter$: Observable<null | T> = this.form.valueChanges.pipe(map(value => value[SelectFilterComponent.CONTROL_NAME]))

  constructor() {}

  ngOnInit() {
    if (!this.filterName) {
      throw new Error('please define the filter name as an attribute on the sc-select-filter component')
    }

    this.initStartValue()
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('filterDefinitions' in changes || 'startValue' in changes) {
      this.initStartValue()
    }
  }

  setFilter(filter: FilterDefinition<T>): void {
    this.form.get(SelectFilterComponent.CONTROL_NAME).setValue(filter.value)
  }

  private initStartValue() {
    if (this.form && this.startValue) {
      this.filterDefinitions.pipe(first()).subscribe((filterDefinitions: Array<FilterDefinition<T>>) => {
        if (filterDefinitions) {
          const startValue = filterDefinitions.find(filterDefinition =>
            isEqual(filterDefinition.value, this.startValue)
          )
          this.form.get(SelectFilterComponent.CONTROL_NAME).setValue(startValue ? startValue.value : null)
        }
      })
    }
  }
}
