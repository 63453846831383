import { LogLevel } from './log-level.enum'
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the moment-es6 module with a default export.
// TODO: See if we can clean this up at some point.
import moment from 'moment-es6'

export class Logger {
  private name: string
  private color: string
  private levelGetter: () => LogLevel

  /* tslint:disable:no-bitwise */
  private static stringToColor(str): string {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = '#'
    for (let k = 0; k < 3; k++) {
      const value = (hash >> (k * 8)) & 0xff
      color += ('00' + value.toString(16)).substr(-2)
    }
    return color
  }

  constructor(levelGetter: () => LogLevel, name: string, color?: string) {
    this.name = name
    this.levelGetter = levelGetter
    this.color = color || Logger.stringToColor(name)
  }

  info(...args) {
    this.log(LogLevel.INFO, args)
  }

  warn(...args) {
    this.log(LogLevel.WARN, args)
  }

  error(...args) {
    this.log(LogLevel.ERROR, args)
  }

  debug(...args) {
    this.log(LogLevel.DEBUG, args)
  }

  protected log(level: LogLevel, argument) {
    if (this.isLevelEnabled(level)) {
      const now = moment().format('HH:mm:ss:SSS')
      const firstArgument = argument.splice(0, 1)[0]

      if (typeof firstArgument === 'string') {
        // we have a string with potential message format
        argument.splice(0, 0, `%c${now} - ${this.name} :: ${firstArgument}`, `color:${this.color}`)
      } else {
        argument.splice(0, 0, `%c${now} - ${this.name} ::`, `color:${this.color}`, firstArgument)
      }

      switch (level) {
        case LogLevel.DEBUG:
          console.debug.apply(console, argument)
          break
        case LogLevel.ERROR:
          console.error.apply(console, argument)
          break
        case LogLevel.INFO:
          console.info.apply(console, argument)
          break
        case LogLevel.WARN:
          console.warn.apply(console, argument)
          break
        default:
          console.log.apply(console, argument)
          break
      }
    }
  }

  protected isLevelEnabled(level: LogLevel) {
    return level >= this.levelGetter()
  }
}
