import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IconModule } from '../icon/icon.module'
import { AvatarComponent } from './avatar.component'
import { InitialsPipe } from './initials.pipe'

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [AvatarComponent, InitialsPipe],
  exports: [AvatarComponent],
})
export class AvatarModule {}
