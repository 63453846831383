import { AbstractControl } from '@angular/forms'
import { RuntimeConfiguration } from '@maprix/core'
import { HttpError } from '../../models/http/http-error'
import { HttpErrorCodes } from '../../models/http/http-error-codes'
import { MicroService } from '../../services/config/runtime-configuration/micro-service.enum'
import { AppHttp } from '../../services/http/app-http.service'

/**
 * Due to stupid ng implementation we implement the validation with promises, which works as expected. Not as
 * shiny as reactive shizzle but it works.
 */
export class UniqueEmailAndCompanyValidator {
  private timeout
  private appHttp: AppHttp
  private authUrl: string

  constructor(appHttp: AppHttp, runtimeConfiguration: RuntimeConfiguration) {
    this.appHttp = appHttp
    this.authUrl = runtimeConfiguration.getUrlForRestService(MicroService.AUTH)
  }

  validate = (control: AbstractControl) => {
    clearTimeout(this.timeout)
    return new Promise<null | { [key: string]: boolean }>((resolve, reject) => {
      this.timeout = setTimeout(() => {
        let email = control.value

        this.appHttp.get(this.authUrl + 'public/registration/checkemail', { params: { email } }).subscribe(
          () => {
            // check for domain
            if (email.indexOf('@') !== -1) {
              email = email.split('@')[1]
            }
            this.appHttp.get(this.authUrl + 'public/registration/checkdomain', { params: { domain: email } }).subscribe(
              res => {
                resolve(null)
              },
              (error: HttpError) => {
                switch (error.code) {
                  case HttpErrorCodes.ORGANIZATION_ALREADY_EXISTS:
                    resolve({ scsUniqueDomain: true })
                    break
                  case HttpErrorCodes.DOMAIN_BLACK_LISTED:
                    resolve({ scsDomainBlackListed: true })
                    break
                  default:
                    resolve({ scsUniqueDomain: true })
                }
              }
            )
          },
          err => {
            resolve({ scsUniqueEmail: true })
          }
        )
      }, 300)
    })
  }

  /*
   * REACTIVE IMPLEMENTATION FOR LATER USAGE, the observable never completes so the control never changes from PENDING to VALID / INVALID
   */
  // private inputChanges: Subject<any>;
  // private validatorStatusChanges: Observable<any>;
  //
  // constructor(appHttp: AppHttp, runtimeConfiguration: RuntimeConfiguration) {
  //   let authUrl: string = runtimeConfiguration.getUrlForService(MicroService.AUTH);
  //
  //   this.inputChanges = new Subject();
  //   this.validatorStatusChanges = this.inputChanges
  //     .debounceTime(1000)
  //     .distinctUntilChanged()
  //     .switchMap(email => {
  //       let searchParams: URLSearchParams = new URLSearchParams();
  //       searchParams.set('email', email);
  //
  //       return appHttp.get(authUrl + 'public/registration/checkemail', { search: searchParams })
  //         .switchMap(res => {
  //           // email check was successful
  //           console.log('checkemail -> ok', res.body);
  //           // check for domain
  //           let searchParams: URLSearchParams = new URLSearchParams();
  //
  //           if (email.indexOf('@') !== -1) {
  //             email = email.split('@')[1];
  //           }
  //
  //           searchParams.set('domain', email);
  //           return appHttp.get(authUrl + 'public/registration/checkdomain', { search: searchParams })
  //             .map(res => {
  //               console.log('res from checkdomain', res.body);
  //               return null;
  //             })
  //             .catch((err: HttpError, caught: Observable<any>): Observable<any> => {
  //               console.log('err from checkdomain');
  //               let reason: string = 'scsUniqueDomain';
  //               return Observable.of({ [reason]: true });
  //             });
  //         })
  //         .catch((err: HttpError, caught: Observable<any>): Observable<any> => {
  //           console.log('err from checkemail');
  //           let reason: string = 'scsUniqueEmail';
  //           return Observable.of({ [reason]: true });
  //         })
  //     })
  //     .map(res => null)
  //     .catch((err: HttpError, caught: Observable<any>): Observable<any>=> {
  //       let reason: string = 'scsUniqueEmail';
  //       return Observable.of({ [reason]: true });
  //     })
  //     .do(v => console.log("mapped", v))
  //     // this does the trick, so only one call to the backend is done
  //     .share();
  // }
  //
  // validate = (control: AbstractControl)=> {
  //   // An immediate timeout is set because the next has to occur after the
  //   // validator chain is subscribed to.
  //   setTimeout(() => this.inputChanges.next(control.value), 0);
  //   return this.validatorStatusChanges;
  // };
}
