import { Injectable } from '@angular/core'
import { fromEvent, merge, Observable } from 'rxjs'
import { filter, finalize } from 'rxjs/operators'
import { WindowRef } from '../window/browser-window.ref'

@Injectable()
export class UIEventService {
  // Map<string, Map<FromEventTarget, Observable<UIEvent>>> -> FromEventTarget is internal source of rxjs
  private observables: Map<string, Map<any, Observable<UIEvent>>> = new Map()
  private window: Window

  constructor(windowRef: WindowRef) {
    this.window = windowRef.nativeWindow
  }

  forEvent(types: string | string[], target?: any): Observable<UIEvent> {
    if (Array.isArray(types)) {
      const obs: Array<Observable<UIEvent>> = (<string[]>types).map(type => this.forSingleEvent(type, target))
      return merge(...obs)
    } else {
      return this.forSingleEvent(types, target)
    }
  }

  private forSingleEvent(type: string, target?: any): Observable<UIEvent> {
    // init the type object to store the observables linked to the event target
    if (!this.observables.has(type)) {
      this.observables.set(type, new Map())
    }

    // if no target is defined, use the document
    if (!target) {
      target = this.window.document
    }

    if (!this.observables.get(type).has(target)) {
      let target$: Observable<UIEvent> = fromEvent(target, type).pipe(
        finalize<UIEvent>(() => {
          this.observables.get(type).delete(target)
        })
      )

      if (type === 'mouseout') {
        target$ = target$.pipe(filter(event => event.target === this.window.document))
      }

      this.observables.get(type).set(target, target$)
    }

    return this.observables.get(type).get(target)
  }
}
