import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { Logger } from '../logger/logger'
import { LoggerService } from '../logger/logger.service'
import { WindowRef } from '../window/browser-window.ref'

const DURATION_MIN = 0.1
const DURATION_MAX = 0.8

@Injectable()
export class ScrollToService {
  private logger: Logger
  private window: Window

  // TODO document should by typed, but then the build will fail when generating aot information
  constructor(loggerService: LoggerService, windowRef: WindowRef, @Inject(DOCUMENT) private document: any) {
    this.logger = loggerService.getInstance('MetaModule')
    this.window = windowRef.nativeWindow
  }

  scrollTo(scrollTargetY: number = 0, speed: number = 2000): void {
    const scrollY = this.window.scrollY || this.window.pageYOffset
    const currentTime = 0
    const time = Math.max(DURATION_MIN, Math.min(Math.abs(scrollY - scrollTargetY) / speed, DURATION_MAX))

    this.tick(currentTime, time, scrollTargetY, scrollY)
  }

  scrollToNoAnimation(scrollTarget: number | string | HTMLElement, offsetY: number = 0): void {
    this.window.scrollTo(this.getScrollTargetY(scrollTarget, offsetY), 0)
  }

  scrollToElement(scrollTargetEl: HTMLElement, offset: number = 0, speed: number = 2000) {
    let scrollTargetY: number
    if (scrollTargetEl && scrollTargetEl instanceof HTMLElement) {
      scrollTargetY = this.getScrollTargetY(scrollTargetEl)
    } else {
      this.logger.warn('could not find HTMLElement with will scroll to top (y = 0)', scrollTargetEl)
      scrollTargetY = 0
    }
    this.scrollTo(scrollTargetY - offset, speed)
  }

  private tick(currentTime: number, time: number, scrollTargetY: number, scrollY: number) {
    currentTime += 1 / 60

    const p = currentTime / time
    const t = this.easingFn(p)

    if (p < 1) {
      this.window.requestAnimationFrame(() => {
        this.tick(currentTime, time, scrollTargetY, scrollY)
      })
      this.window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t)
    } else {
      this.window.scrollTo(0, scrollTargetY)
    }
  }

  scrollModalToTop(container: HTMLElement) {
    container.scrollTop = 0
  }

  scrollModalTo(scrollTargetY: number = 0, speed: number = 2000, container: HTMLElement): void {
    const scrollY = container.scrollTop
    const currentTime = 0
    const time = Math.max(DURATION_MIN, Math.min(Math.abs(scrollY - scrollTargetY) / speed, DURATION_MAX))

    this.tickModal(currentTime, time, scrollTargetY, scrollY, container)
  }

  getScrollTargetY(scrollTarget: number | string | HTMLElement, offsetY: number = 0): number {
    let scrollTargetY: number
    const body: HTMLElement = this.document.documentElement || this.document.body

    if (typeof scrollTarget === 'string' || scrollTarget instanceof HTMLElement) {
      // find the element with id query selector
      let htmlElement: Element | null
      if (typeof scrollTarget === 'string') {
        htmlElement = body.querySelector(`#${scrollTarget}`)
      } else {
        htmlElement = scrollTarget
      }

      if (!htmlElement) {
        this.logger.warn('could not find HTMLElement with id -> will scroll to top (y = 0)', scrollTarget)
        scrollTargetY = 0
      } else {
        const scrollY = this.window.pageYOffset || body.scrollTop
        scrollTargetY = htmlElement.getBoundingClientRect().top + scrollY
      }
    } else {
      scrollTargetY = scrollTarget
    }

    scrollTargetY -= offsetY

    return scrollTargetY
  }

  private tickModal(currentTime: number, time: number, scrollTargetY: number, scrollY: number, container: HTMLElement) {
    currentTime += 1 / 60

    const p = currentTime / time
    const t = this.easingFn(p)

    container.scrollTop = scrollTargetY
    if (p < 1) {
      this.window.requestAnimationFrame(() => {
        this.tickModal(currentTime, time, scrollTargetY, scrollY, container)
      })

      container.scrollTop = scrollY + (scrollTargetY - scrollY) * t
    } else {
      container.scrollTop = scrollTargetY
    }
  }

  private easingFn(pos) {
    return -0.5 * (Math.cos(Math.PI * pos) - 1)
  }
}
