// tslint:disable:max-classes-per-file
import { CollectionViewer, DataSource } from '@angular/cdk/collections'
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  EmbeddedViewRef,
  Input,
  isDevMode,
  IterableChangeRecord,
  IterableDiffer,
  IterableDiffers,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  TrackByFunction,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { Observable, of, Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { PagedDataSource } from '../data-source/paged-data-source'
import { Logger, LoggerService } from '@shiftcoders/core'

/** Context provided to the row cells */
export interface ScListItemOutletContext<T> {
  /** Data for the row that this cell is located within. */
  $implicit: T

  /** Index location of the row that this cell is located within. */
  index?: number

  /** Length of the number of total rows. */
  count?: number

  /** True if this cell is contained in the first row. */
  first?: boolean

  /** True if this cell is contained in the last row. */
  last?: boolean

  /** True if this cell is contained in a row with an even-numbered index. */
  even?: boolean

  /** True if this cell is contained in a row with an odd-numbered index. */
  odd?: boolean
}

/**
 * Class used to conveniently type the embedded view ref for rows with a context.
 * @docs-private
 */
abstract class ListItemRef<T> extends EmbeddedViewRef<ScListItemOutletContext<T>> {}

@Directive({ selector: '[scListItemDef]' })
// tslint:disable-next-line:directive-class-suffix
export class ListItemDef<T> {
  constructor(public template: TemplateRef<ScListItemOutletContext<T>>) {}
}

/**
 * Provides a handle for the table to grab the view container's ng-container to insert data rows.
 * @docs-private
 */
@Directive({ selector: '[scItemsPlaceholder]' })
// tslint:disable-next-line:directive-class-suffix
export class ItemsPlaceholder {
  constructor(public viewContainer: ViewContainerRef) {}
}

@Component({
  selector: 'sc-list',
  template: `<ng-container scItemsPlaceholder></ng-container>
`,
  styles: [`:host{display:block}`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// tslint:disable-next-line:max-classes-per-file
export class ScListComponent<T> implements CollectionViewer, OnInit, OnDestroy, AfterContentChecked {
  /** Latest data provided by the data source. */
  data: T[]

  private logger: Logger

  /** Subject that emits when the component has been destroyed. */
  private onDestroy = new Subject<void>()

  /** Subscription that listens for the data provided by the data source. */
  private renderChangeSubscription: Subscription | null

  /** Differ used to find the changes in the data provided by the data source. */
  private dataDiffer: IterableDiffer<T>

  viewChange: Observable<{ start: number; end: number }>

  /**
   * The list's source of data, which can be provided in three ways (in order of complexity):
   *   - Simple data array (each object represents one table row)
   *   - Stream that emits a data array each time the array changes
   *   - `DataSource` object that implements the connect/disconnect interface.
   *
   * If a data array is provided, the list must be notified when the array's objects are
   * added, removed, or moved. This can be done by calling the `renderRows()` function which will
   * render the diff since the last list render. If the data array reference is changed, the list
   * will automatically trigger an update to the rows.
   *
   * When providing an Observable stream, the list will trigger an update automatically when the
   * stream emits a new array of data.
   *
   * Finally, when providing a `DataSource` object, the list will use the Observable stream
   * provided by the connect function and trigger updates when that stream emits new data array
   * values. During the list's ngOnDestroy or when the data source is removed from the list, the
   * list will call the DataSource's `disconnect` function (may be useful for cleaning up any
   * subscriptions registered during the connect process).
   */
  private _dataSource: DataSource<T>

  @Input()
  get dataSource(): DataSource<T> {
    return this._dataSource
  }

  set dataSource(value: DataSource<T>) {
    if (this._dataSource !== value) {
      this.switchDataSource(value)
    }
  }

  /**
   * Tracking function that will be used to check the differences in data changes. Used similarly
   * to `ngFor` `trackBy` function. Optimize row operations by identifying a row based on its data
   * relative to the function to know if a row should be added/removed/moved.
   * Accepts a function that takes two parameters, `index` and `item`.
   */
  private _trackByFn: TrackByFunction<T>

  @Input()
  get trackBy(): TrackByFunction<T> {
    return this._trackByFn
  }

  set trackBy(fn: TrackByFunction<T>) {
    if (isDevMode() && fn != null && typeof fn !== 'function' && <any>console && <any>console.warn) {
      this.logger.warn(`trackBy must be a function, but received ${JSON.stringify(fn)}.`)
    }
    this._trackByFn = fn
  }

  // Placeholders within the table's template where the header and data rows will be inserted.
  @ViewChild(ItemsPlaceholder) itemsPlaceholder: ItemsPlaceholder

  @ContentChildren(ListItemDef) listItemDefs: QueryList<ListItemDef<T>>

  constructor(
    loggerService: LoggerService,
    public changeDetectorRef: ChangeDetectorRef,
    private _differs: IterableDiffers
  ) {
    this.logger = loggerService.getInstance('ScListComponent')
  }

  ngOnInit() {
    this.dataDiffer = this._differs.find([]).create(this._trackByFn)
  }

  ngAfterContentChecked() {
    // If there is a data source and row definitions, connect to the data source unless a
    // connection has already been made.
    if (this.dataSource && this.listItemDefs.length > 0 && !this.renderChangeSubscription) {
      this.observeRenderChanges()
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
    if (this.dataSource instanceof PagedDataSource) {
      this.dataSource.disconnect(<CollectionViewer>this)
    }
  }

  /** Set up a subscription for the data provided by the data source. */
  private observeRenderChanges() {
    // If no data source has been set, there is nothing to observe for changes.
    if (!this.dataSource) {
      return
    }

    let dataStream: Observable<T[]> | undefined

    // Check if the datasource is a DataSource object by observing if it has a connect function.
    // Cannot check this.dataSource['connect'] due to potential property renaming, nor can it
    // checked as an instanceof DataSource<T> since the table should allow for data sources
    // that did not explicitly extend DataSource<T>.
    if ((this.dataSource as DataSource<T>).connect instanceof Function) {
      dataStream = (this.dataSource as DataSource<T>).connect(<CollectionViewer>this)
    } else if (this.dataSource instanceof Observable) {
      dataStream = <Observable<T[]>>this.dataSource
    } else if (Array.isArray(this.dataSource)) {
      dataStream = of(<T[]>this.dataSource)
    }

    this.renderChangeSubscription = dataStream.pipe(takeUntil(this.onDestroy)).subscribe(data => {
      this.data = data
      this.renderListItems()
    })
  }

  /**
   * Renders rows based on the table's latest set of data, which was either provided directly as an
   * input or retrieved through an Observable stream (directly or from a DataSource).
   * Checks for differences in the data since the last diff to perform only the necessary
   * changes (add/remove/move rows).
   *
   * If the table's data source is a DataSource or Observable, this will be invoked automatically
   * each time the provided Observable stream emits a new data array. Otherwise if your data is
   * an array, this function will need to be called to render any changes.
   */
  private renderListItems() {
    const changes = this.dataDiffer.diff(this.data)
    if (!changes) {
      return
    }

    const viewContainer = this.itemsPlaceholder.viewContainer
    changes.forEachOperation((record: IterableChangeRecord<T>, adjustedPreviousIndex: number, currentIndex: number) => {
      if (record.previousIndex == null) {
        this.insertItem(record.item, currentIndex)
      } else if (currentIndex == null) {
        viewContainer.remove(adjustedPreviousIndex)
      } else {
        const view = <EmbeddedViewRef<T>>viewContainer.get(adjustedPreviousIndex)
        viewContainer.move(view, currentIndex)
      }
    })

    // Update the meta context of a row's context data (index, count, first, last, ...)
    this.updateRowIndexContext()

    // Update items that did not get added/removed/moved but may have had their identity changed,
    // e.g. if trackBy matched data on some property but the actual data reference changed.
    changes.forEachIdentityChange((record: IterableChangeRecord<T>) => {
      const rowView = <EmbeddedViewRef<ScListItemOutletContext<T>>>viewContainer.get(record.currentIndex)
      rowView.context.$implicit = record.item
    })
  }

  /**
   * Create the embedded view for the data row template and place it in the correct index location
   * within the data row view container.
   */
  private insertItem(itemData: T, index: number) {
    const row = this.getListItemDef(itemData, index)

    // Row context that will be provided to both the created embedded row view and its cells.
    const context: ScListItemOutletContext<T> = { $implicit: itemData }

    // TODO(andrewseguin): add some code to enforce that exactly one
    //   CdkCellOutlet was instantiated as a result  of `createEmbeddedView`.
    this.itemsPlaceholder.viewContainer.createEmbeddedView(row.template, context, index)

    this.changeDetectorRef.markForCheck()
  }

  /**
   * Updates the index-related context for each row to reflect any changes in the index of the rows,
   * e.g. first/last/even/odd.
   */
  private updateRowIndexContext() {
    const viewContainer = this.itemsPlaceholder.viewContainer
    for (let index = 0, count = viewContainer.length; index < count; index++) {
      const viewRef = viewContainer.get(index) as ListItemRef<T>
      viewRef.context.index = index
      viewRef.context.count = count
      viewRef.context.first = index === 0
      viewRef.context.last = index === count - 1
      viewRef.context.even = index % 2 === 0
      viewRef.context.odd = !viewRef.context.even
    }
  }

  /**
   * Finds the matching row definition that should be used for this row data. If there is only
   * one row definition, it is returned. Otherwise, find the row definition that has a when
   * predicate that returns true with the data. If none return true, return the default row
   * definition.
   */
  // TODO REVIEW should we implement a when functionality? see https://github.com/angular/material2/blob/master/src/cdk/table/row.ts#L92
  getListItemDef(data: T, i: number): ListItemDef<T> {
    if (this.listItemDefs.length > 1) {
      throw new Error('you provided multiple list item definition which is not supported at the moment')
    }

    return this.listItemDefs.first
  }

  private switchDataSource(value: DataSource<T>) {
    this.data = []

    if (this.dataSource instanceof DataSource) {
      this.dataSource.disconnect(<CollectionViewer>this)
    }

    // Stop listening for data from the previous data source.
    if (this.renderChangeSubscription) {
      this.renderChangeSubscription.unsubscribe()
      this.renderChangeSubscription = null
    }

    if (!value) {
      if (this.dataDiffer) {
        this.dataDiffer.diff([])
      }
    }

    this._dataSource = value
  }
}
