import { Component, HostBinding, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core'
import { isString } from 'lodash'
import { Observable } from 'rxjs'
import { HttpError, TranslateService, Logger, LoggerService } from '@shiftcoders/core'

@Component({
  selector: 'sc-inline-message',
  template: `<div *ngIf="(error || success ||Â warning) && message">
  <span class="inline-message__data" [innerHTML]="message"></span>
</div>
`,
  styles: [`:host{display:block;font-family:line-to-circular,"Helvetica Neue",Helvetica,Arial,sans-serif;font-weight:400;font-style:normal;font-size:1.2rem;line-height:1.33333;padding:4px 8px 5px}:host(.success){color:#fff;background-color:rgba(18,139,21,.9)}:host(.warning){color:#333;background-color:rgba(255,252,150,.9)}:host(.error){color:#fff;background-color:rgba(242,64,51,.9)}:host(.error) /deep/ a{font-weight:700;color:#fff}`],
})
export class InlineMessageComponent implements OnChanges {
  @Input()
  @HostBinding('class.success')
  success: string | undefined

  @Input()
  @HostBinding('class.warning')
  warning: string | undefined

  @Input()
  @HostBinding('class.error')
  error: HttpError<any> | string | undefined
  @Input() details: { [key: string]: any } | undefined

  message: string

  private logger: Logger

  constructor(loggerService: LoggerService, private translateService: TranslateService) {
    this.logger = loggerService.getInstance('Error')
  }

  ngOnChanges(changes: SimpleChanges): any {
    const errorChange: SimpleChange = changes['error']
    const successChange: SimpleChange = changes['success']
    const warningChange: SimpleChange = changes['warning']

    let obs: Observable<string | string[]> | undefined

    if (errorChange && errorChange.currentValue) {
      const error: HttpError<any> | string = errorChange.currentValue
      if (error.hasOwnProperty('code')) {
        obs = this.translateService.get(
          'HTTP_ERROR.' + (<HttpError<any>>this.error).code,
          (<HttpError<any>>this.error).details
        )
      } else if (isString(error)) {
        obs = this.translateService.get(`${error}`, this.details)
      }
    } else if (successChange && successChange.currentValue) {
      obs = this.translateService.get(<string>successChange.currentValue, this.details)
    } else if (warningChange && warningChange.currentValue) {
      obs = this.translateService.get(<string>warningChange.currentValue, this.details)
    }

    if (obs !== undefined) {
      obs.subscribe(message => (this.message = <string>message))
    }
  }
}
