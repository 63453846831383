import { Directive, Input, OnInit, Renderer2 } from '@angular/core'
import { BrowserVersion, DeviceInfoService, Features, WindowRef } from '@shiftcoders/core'

@Directive({
  selector: '[scPlatformInformation]',
})
export class PlatformInformationDirective implements OnInit {
  private static DEFAULT_APP_NAME = 'app-root'

  // tslint:disable:no-input-rename
  @Input('scPlatformInformation') appRootName: string

  private htmlEl: HTMLElement

  constructor(windowRef: WindowRef, renderer: Renderer2, private deviceInfo: DeviceInfoService) {
    const window = windowRef.nativeWindow
    this.htmlEl = window.document.documentElement

    // init device info
    this.deviceInfo.featureChanges.subscribe((features: Features) => {
      renderer.addClass(this.htmlEl, features.touch ? 'touchevents' : 'no-touchevents')
    })

    this.deviceInfo.browserVersionChanges.subscribe((browser: BrowserVersion) => {
      const classes: string[] = [browser.name, `${browser.name}${browser.major}`]
      if (browser.isIE) {
        classes.push('lt-msedge')
        classes.push(browser.major < 8 ? 'lt-ie8' : `ie${browser.major}`)
      }
      classes.forEach(value => renderer.addClass(this.htmlEl, value))
    })
  }

  ngOnInit(): void {
    const appRoot = this.htmlEl
      .getElementsByTagName(this.appRootName ? this.appRootName : PlatformInformationDirective.DEFAULT_APP_NAME)
      .item(0)
    if (!appRoot) {
      throw new Error(
        `appRoot was not found! Please check scPlatformInformation input if app is named different than "${
          PlatformInformationDirective.DEFAULT_APP_NAME
        }"`
      )
    }
    this.deviceInfo.init(appRoot)
  }
}
