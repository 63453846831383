import { ServiceTypeName } from './service-type-name.enum'

export class ServiceType {
  constructor(
    public name: ServiceTypeName,
    public protocol: string,
    public protocolSecure: string,
    public uri: string
  ) {}
}
