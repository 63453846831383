import { isObject, mapValues } from 'lodash'

export function mapValuesDeep(value, callback) {
  if (Array.isArray(value)) {
    return (<any[]>value).map(arrItem => {
      return mapValuesDeep(arrItem, callback)
    })
  } else {
    if (isObject(value)) {
      return mapValues(value, v => mapValuesDeep(v, callback))
    } else {
      return callback(value)
    }
  }
}
