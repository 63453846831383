import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IconModule } from '@maprix/components'
import { LocaleModule } from '@maprix/core'
import { GuestSuccessComponent } from './guest-success.component'

@NgModule({
  imports: [CommonModule, LocaleModule, IconModule],
  declarations: [GuestSuccessComponent],
  exports: [GuestSuccessComponent],
})
export class GuestSuccessModule {}
