// Original from : https://github.com/sclausen/ng2-translate/blob/master/src/translate.directive.ts
// fixed some problems

import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SecurityContext,
  SimpleChange,
  SimpleChanges,
} from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Subscription } from 'rxjs'
import { TranslateService } from './translate.service'

@Directive({
  /* tslint:disable */
  selector: '[translate]',
  /* tslint:enable */
})
export class TranslateDirective implements OnInit, OnChanges, OnDestroy {
  @Input() translate: string

  @Input() translateValues: { [key: string]: string }

  private translationKey: string

  private langChange: Subscription

  constructor(
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /**
   * preserves the key from the translate attribute or from innerHTML
   */
  ngOnInit() {
    this.translationKey = this.translate ? this.translate : this.elementRef.nativeElement.innerHTML
    this.updateValue(this.translationKey)
    this.langChange = this.translateService.onLangChange.subscribe(event => {
      this.updateValue(this.translationKey)
    })
  }

  ngOnDestroy() {
    this.langChange.unsubscribe()
  }

  /**
   * updates the translation if the interpolation params change
   * @param  changes
   */
  ngOnChanges(changes: SimpleChanges) {
    const translateKeyChange: SimpleChange = changes['translate']
    const translateValueChange: SimpleChange = changes['translateValues']

    let updateRequired: boolean | undefined
    let translationKey: string | undefined
    if (translateValueChange && translateValueChange.currentValue && this.translationKey) {
      updateRequired = true
      translationKey = this.translationKey
    }

    if (translateKeyChange && translateKeyChange.currentValue) {
      updateRequired = true
      translationKey = translateKeyChange.currentValue
    }

    if (updateRequired && translationKey) {
      this.updateValue(translationKey)
    }
  }

  /**
   * updates the translation
   * @param  translationKey
   */
  updateValue(translationKey: string) {
    if (this.translateValues) {
      Object.keys(this.translateValues).forEach(valueKey => {
        this.translateValues[valueKey] = this.domSanitizer.sanitize(
          SecurityContext.HTML,
          this.translateValues[valueKey]
        )
      })
    }

    const temp = this.translateService.get(translationKey, this.translateValues)
    temp.subscribe((res: string) => {
      this.elementRef.nativeElement.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, res)
      this.changeDetectorRef.markForCheck()
    })
  }
}
