import { ModuleWithProviders, NgModule } from '@angular/core'
import { LogglyErrorHandler } from './loggly-error-handler.service'

@NgModule({})
export class LogglyErrorHandlerModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LogglyErrorHandlerModule,
      providers: [LogglyErrorHandler],
    }
  }
}
