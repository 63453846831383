import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { sortBy } from 'lodash'
import moment from 'moment-es6'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { HttpError } from '../http/http-error.model'
import { Logger } from '../logger/logger'
import { LoggerService } from '../logger/logger.service'
import { TIME_ZONE_IDS } from './time-zone-ids'

export interface Country {
  code: string
  localeDisplayName: string
}

export interface TimeZone {
  offset: number
  name: string
  displayName: string
}

@Injectable()
export class CountryService {
  private static DEFAULT_TIMEZONE = 'Europe/Zurich'

  static COUNTRY_CODES = [
    'CH',
    'AT',
    'BE',
    'BG',
    'CY',
    'HR',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LI',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'US',
    'GB',
    'EU',
  ]

  static NONE_UST_COUNTRY_CODES = ['CH', 'LI', 'US']

  private static CLDR_URL = 'cldr/main/'
  private static CLDR_URL_POSTFIX = '/territories.json'

  private logger: Logger
  private timezoneZoneIds: string[] = TIME_ZONE_IDS

  constructor(loggerService: LoggerService, private httpClient: HttpClient) {
    this.logger = loggerService.getInstance('CountryService')
  }

  hasUst(countryCode: string): boolean {
    return !CountryService.NONE_UST_COUNTRY_CODES.includes(countryCode)
  }

  getLocaleDisplayName(lang: string, code: string): Observable<string> {
    return this.generateCountryList(lang).pipe(
      map((countries: Country[]) => {
        const country: Country[] = countries.filter(c => c.code === code)
        return country[0].localeDisplayName
      })
    )
  }

  getLocaleDisplayNameInExisting(code: string, countries: Country[]): string {
    const country: Country[] = countries.filter(c => c.code === code)
    // if no country was found just return the code (should not happen in normal circumstances)
    return country && country[0] ? country[0].localeDisplayName : code
  }

  generateCountryList(lang: string): Observable<Country[]> {
    const langShort: string = lang.split('-')[0]
    const url = CountryService.CLDR_URL + langShort + CountryService.CLDR_URL_POSTFIX

    return this.httpClient.get<Country[]>(url).pipe(
      catchError((error: HttpError<any>) => {
        return throwError('could not fetch the country cldr list')
      }),
      map((response: any) => {
        const territories = response.main[langShort].localeDisplayNames.territories
        const countryList: Country[] = []
        CountryService.COUNTRY_CODES.forEach((code: string) => {
          const country: Country = { code, localeDisplayName: territories[code] }
          countryList.push(country)
        })
        // sort country list alphabetically by value
        return countryList.sort((a: Country, b: Country) => {
          return a.localeDisplayName.localeCompare(b.localeDisplayName, undefined, { sensitivity: 'base' })
        })
      })
    )
  }

  getTimeZoneSelectList(): TimeZone[] {
    let timeZoneSelectList: TimeZone[] = []
    const currentDate: string = moment().format('YYYY-MM-DD')
    this.timezoneZoneIds.forEach((name: string) => {
      const offset: string = moment(currentDate)
        .tz(name)
        .format('Z')
      // utc offset as number in minutes -> divided with 60 gives hours -> used for sorting
      const offsetNumber: number =
        moment(currentDate)
          .tz(name)
          .utcOffset() / 60

      const timeZone: TimeZone = {
        offset: offsetNumber,
        name,
        displayName: '(' + offset + ') ' + name,
      }
      timeZoneSelectList.push(timeZone)
    })

    // sort list based on offset & name
    timeZoneSelectList = sortBy(timeZoneSelectList, ['offset', 'name'])
    return timeZoneSelectList
  }

  getGuessedCurrentTimeZone(): string {
    let guess: string = moment.tz.guess()
    const zoneIds: TimeZone[] = this.getTimeZoneSelectList()
    if (!zoneIds.find(zoneId => zoneId.name === guess)) {
      // guess did not match available zoneIds, replace guess with available zoneId with same offset
      const currentDate: string = moment().format('YYYY-MM-DD')
      const offsetNumber: number =
        moment(currentDate)
          .tz(name)
          .utcOffset() / 60
      const zoneId = zoneIds.find(id => id.offset === offsetNumber)
      if (zoneId) {
        guess = zoneId.name
      } else {
        //    should not happen, but lets just be safe and set Europe/Zurich as default
        guess = CountryService.DEFAULT_TIMEZONE
      }
    }
    return guess
  }
}
