import { ModuleWithProviders, NgModule } from '@angular/core'
import { LogglyService } from './loggly.service'
import { LoggerService } from './logger.service'
import { HttpClientModule } from '@angular/common/http'

@NgModule({
  imports: [HttpClientModule],
})
export class LoggerModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LoggerModule,
      providers: [LoggerService, LogglyService],
    }
  }
}
