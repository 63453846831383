import { ESCAPE } from '@angular/cdk/keycodes'
import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay'
import { Observable, Subject } from 'rxjs'
import { filter, take } from 'rxjs/operators'
import { Modal2Component } from './modal2.component'
import { Logger, LoggerService } from '@shiftcoders/core'

export class Modal2Ref<T, R> {
  private logger: Logger

  private _beforeClosed: Subject<R> = new Subject<R>()
  private _afterClosed: Subject<R> = new Subject<R>()

  componentInstance: T
  result: R
  preventFromClosing: boolean = this.modal.config.preventFromClosing

  get positionStrategy(): GlobalPositionStrategy {
    return this.overlayRef.getConfig().positionStrategy as GlobalPositionStrategy
  }

  get beforeClosed(): Observable<R> {
    return this._beforeClosed.asObservable()
  }

  get afterClosed(): Observable<R> {
    return this._afterClosed.asObservable()
  }

  constructor(loggerService: LoggerService, private overlayRef: OverlayRef, public modal: Modal2Component) {
    this.logger = loggerService.getInstance('Modal2Ref')
    this.initListeners()
  }

  private initListeners() {
    if (this.modal.config.closeOnClickBackdrop) {
      // When the dialog backdrop is clicked, we want to close it.
      this.overlayRef
        .backdropClick()
        .pipe(
          filter(() => !this.modal.config.preventFromClosing),
          take(1)
        )
        .subscribe(() => this.close(null))
    }

    if (this.modal.config.closeOnEsc) {
      this.overlayRef
        .keydownEvents()
        .pipe(
          filter(event => event.keyCode === ESCAPE && !this.preventFromClosing),
          take(1)
        )
        .subscribe(() => this.close(null))
    }

    this.modal.animationStateChanged
      .pipe(
        filter(event => event.toState === 'exit' && event.phaseName === 'start'),
        take(1)
      )
      .subscribe(this.onExitStart)

    this.modal.animationStateChanged
      .pipe(
        filter(event => event.toState === 'exit' && event.phaseName === 'done'),
        take(1)
      )
      .subscribe(this.onExitDone)
  }

  close(result: R | null) {
    this.logger.debug('modal closes with value: ', result)
    this.result = result
    this.modal.startExitAnimation()
  }

  updatePosition() {
    this.positionStrategy.centerVertically()
    this.positionStrategy.centerHorizontally()
    this.overlayRef.updatePosition()
  }

  scrollToTop() {
    this.modal.scrollToTop()
  }

  private onExitStart = () => {
    this._beforeClosed.next(this.result)
    this._beforeClosed.complete()
    this.overlayRef.detachBackdrop()
  }

  private onExitDone = () => {
    this.overlayRef.dispose()
    this._afterClosed.next(this.result)
    this._afterClosed.complete()
    this.componentInstance = null
  }
}
