export const SUPPORTED_IMAGE_MIME_TYPES = { jpg: 'image/jpeg', jpeg: 'image/jpeg', png: 'image/png' }
export const CONTENT_TYPE_HEADER = 'Content-Type'

function getGuessedFileType(file: File): string {
  const names = file.name.split('.')
  return names[names.length - 1].toLowerCase()
}

export function getGuessedMimeType(file: any): string | null {
  if (file instanceof Blob) {
    return (<Blob>file).type
  } else if (file instanceof File) {
    return SUPPORTED_IMAGE_MIME_TYPES[getGuessedFileType(file)]
  } else {
    return null
  }
}
