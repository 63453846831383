import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { LocaleModule, LoggerModule } from '@shiftcoders/core'
import { InlineMessageModule } from '../inline-message/inline-message.module'
import { Modal2Module } from '../modal2/modal2.module'
import { StateButtonModule } from '../state-button/state-button.module'
import { ConfirmDeleteComponent } from './confirm-delete-modal.component'
import { ConfirmDeleteService } from './confirm-delete.service'

@NgModule({
  imports: [CommonModule, Modal2Module, StateButtonModule, InlineMessageModule, LoggerModule, LocaleModule],
  providers: [ConfirmDeleteService],
  declarations: [ConfirmDeleteComponent],
  entryComponents: [ConfirmDeleteComponent],
  exports: [ConfirmDeleteComponent],
})
export class ConfirmDeleteModalModule {}
