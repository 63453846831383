export class FormControlInputType {
  nodeName: 'input' | 'sc-checkbox' | 'sc-radio-group' | 'sc-slide-toggle'
  main: string
  sub: string
  dir?: string

  constructor(
    nodeName: 'input' | 'sc-checkbox' | 'sc-radio-group' | 'sc-slide-toggle',
    main: string,
    sub: string,
    dir: string
  ) {
    this.nodeName = nodeName
    this.main = main
    this.sub = sub
    this.dir = dir
  }
}
