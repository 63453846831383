import { animate, state, style, transition, trigger } from '@angular/animations'
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { Logger, LoggerService } from '@shiftcoders/core'
import { FormControlErrorDisplayStrategy } from './form-control-error-display-strategy.enum'
import { FormControlInputType } from './form-control-input-type'

const ACCORDION_ANIMATION_DURATION = 200

@Component({
  selector: 'sc-form-control',
  template: `<!-- label -->
<label #label *ngIf="show && labelTranslate"
       class="form-control__input-label" [class.form-control__input-label--disabled]="disabled">
    <span>{{labelTranslate | translate}}<span class="form-control__input-label--addon"
                                              *ngIf="requiredAddon && !disabled">{{requiredAddon}}</span></span>
</label>

<!-- control (input, textarea, select, etc.) -->
<div #contentdiv [ngClass]="{'form-control__input-container': !isComplexBoolControl}" *ngIf="show">
  <ng-content></ng-content>
  <span *ngIf="isSelectControl" class="form-control__action form-control__action--select"></span>
</div>

<span class="form-control__message form-control__message--error" [@scFormControlState]="'active'"
      *ngIf="formControlField && formControlField.status !== 'PENDING' && !formControlField.valid && isErrorVisible() && !disabled">
    <span *ngIf="(formControlField.errors | firstKey) === 'required' && !isComplexBoolControl"
          translate="{{requiredTranslate ? requiredTranslate : labelTranslate ||Â placeholder ? 'FORM.VALIDATION.REQUIRED' : 'FORM.VALIDATION.REQUIRED_NO_LABEL'}}"
          [translateValues]="requiredTranslateValues? requiredTranslateValues : {'input': labelTranslate ? (labelTranslate | translate) : placeholder}">
    </span>

    <span *ngIf="(formControlField.errors | firstKey) === 'required' && isComplexBoolControl"
          translate="{{requiredBoolTranslate ? requiredBoolTranslate : 'FORM.VALIDATION.REQUIRED_BOOL'}}"
          [translateValues]="requiredBoolTranslateValues? requiredBoolTranslateValues : null">
    </span>
    <span *ngIf="(formControlField.errors | firstKey) === 'minlength'"
          translate="{{minLengthTranslate ? minLengthTranslate : 'FORM.VALIDATION.MIN_LENGTH'}}"
          [translateValues]="minLengthTranslateValues? minLengthTranslateValues : {'actual': formControlField.errors.minlength.actualLength, 'min': formControlField.errors.minlength.requiredLength}">
    </span>
    <span *ngIf="(formControlField.errors | firstKey) === 'maxlength'"
          translate="{{maxLengthTranslate ? maxLengthTranslate : 'FORM.VALIDATION.MAX_LENGTH'}}"
          [translateValues]="maxLengthTranslateValues? maxLengthTranslateValues : {'actual': formControlField.errors.maxlength.actualLength, 'max': formControlField.errors.maxlength.requiredLength}">
    </span>
    <span *ngIf="(formControlField.errors | firstKey) === 'min'"
          translate="{{minTranslate ? minTranslate : 'FORM.VALIDATION.MIN'}}"
          [translateValues]="minTranslateValues? minTranslateValues : {'min': formControlField.errors.min.min}">
    </span>
    <span *ngIf="(formControlField.errors | firstKey) === 'max'"
          translate="{{maxTranslate ? maxTranslate : 'FORM.VALIDATION.MAX'}}"
          [translateValues]="maxTranslateValues? maxTranslateValues : {'max': formControlField.errors.max.max}">
    </span>
    <span *ngIf="(formControlField.errors | firstKey) === 'range'"
          translate="{{rangeTranslate ? rangeTranslate: 'FORM.VALIDATION.RANGE'}}"
          [translateValues]="rangeTranslateValues? rangeTranslateValues : {'min': formControlField.errors.range.min, 'max': formControlField.errors.range.max}">
    </span>
    <span *ngIf="(formControlField.errors | firstKey) === 'pattern'"
          translate="{{patternTranslate ? patternTranslate : labelTranslate ||Â placeholder ? 'FORM.VALIDATION.PATTERN' : 'FORM.VALIDATION.REQUIRED_NO_LABEL'}}"
          [translateValues]="patternTranslateValues? patternTranslateValues : {'input': labelTranslate ? (labelTranslate | translate) : placeholder}">
    </span>
    <span *ngIf="(formControlField.errors | firstKey) === 'scsUniqueEmail'"
          translate="{{uniqueEmailTranslate ? uniqueEmailTranslate : 'FORM.VALIDATION.UNIQUE_EMAIL'}}"
          [translateValues]="uniqueEmailTranslateValues? uniqueEmailTranslateValues : null">
    </span>
    <span *ngIf="(formControlField.errors | firstKey) === 'scsUniqueDomain'"
          translate="{{uniqueDomainTranslate ? uniqueDomainTranslate : 'FORM.VALIDATION.UNIQUE_DOMAIN'}}"
          [translateValues]="uniqueDomainTranslateValues? uniqueDomainTranslateValues : null">
    </span>
  <span *ngIf="(formControlField.errors | firstKey) === 'scsEnterpriseDomain'"
        translate="{{enterpriseDomainTranslate ? enterpriseDomainTranslate : 'FORM.VALIDATION.ENTERPRISE_DOMAIN'}}"
        [translateValues]="enterpriseDomainTranslateValues ? enterpriseDomainTranslateValues : null">
    </span>
    <span *ngIf="(formControlField.errors | firstKey) === 'scsDomainBlackListed'"
          translate="{{domainBlackListedTranslate ? domainBlackListedTranslate : 'FORM.VALIDATION.DOMAIN_BLACK_LISTED'}}"
          [translateValues]="domainBlackListedTranslateValues ? domainBlackListedTranslateValues : null">
    </span>
    <span *ngIf="(formControlField.errors | firstKey) === 'uniqueList'"
          translate="{{uniqueListTranslate ? uniqueListTranslate: 'FORM.VALIDATION.UNIQUE_LIST'}}"
          [translateValues]="uniqueListTranslateValues? uniqueListTranslateValues : {'current': formControlField.errors.uniqueList.current}">
    </span>
    <span *ngIf="(formControlField.errors | firstKey) === 'matDatepickerParse' || (formControlField.errors | firstKey) === 'matDatepickerMin'
        || (formControlField.errors | firstKey) === 'matDatepickerMax' || (formControlField.errors | firstKey) === 'matDatepickerFilter'"
        translate="FORM.VALIDATION.DATE_DEFAULT">
    </span>
</span>

<!--some additional text information -->
<div class="form-control__message" *ngIf="infoTranslate" translate="{{infoTranslate}}" [translateValues]="infoTranslateValues"></div>
`,
  animations: [
    trigger('scFormControlState', [
      state(
        'active',
        style({
          height: '*',
          opacity: '1',
        })
      ),
      transition('void => *', [
        style({
          height: '0',
          opacity: '0',
        }),
        animate(ACCORDION_ANIMATION_DURATION + 'ms ease'),
      ]),
      transition('* => void', [
        animate(
          ACCORDION_ANIMATION_DURATION + 'ms ease',
          style({
            height: '0',
            opacity: '0',
          })
        ),
      ]),
    ]),
  ],
})

/**
 * A Form Control wraps one of the following components:
 *  - input
 *  - sc-checkbox
 *  - sc-radio
 *
 * It provides a way to provide a label which will focus the wrapped control on click,
 * and general error handling (displaying of common errors). It also provides
 * a way to mark required components and add some extra information for the user
 * underneath the component.
 */
export class FormControlComponent implements OnInit, AfterViewInit, OnChanges {
  private static NODE_NAME_INPUT = 'input'
  private static NODE_NAME_SELECT = 'select'
  private static NODE_NAME_SC_CHECKBOX = 'sc-checkbox'
  private static NODE_NAME_SC_RADIO_GROUP = 'sc-radio-group'
  private static NODE_NAME_SC_SLIDE_TOGGLE = 'sc-slide-toggle'

  // important! keep this, as form-control classes are used even there is no form-control component
  @HostBinding('class.form-control') hostClass = true

  @Input() labelTranslate: string
  @Input() show: boolean
  @Input() formControlField: FormControl
  @Input() disabled: boolean
  @Input() infoTranslate: string
  @Input() infoTranslateValues: any
  @Input() boolDirection: 'vertical' | 'horizontal' = 'vertical'
  @Input() errorDisplayStrategy: FormControlErrorDisplayStrategy

  @Input() requiredTranslate: string
  @Input() requiredTranslateValues: any

  @Input() requiredBoolTranslate: string
  @Input() requiredBoolTranslateValues: any

  @Input() minLengthTranslate: string
  @Input() minLengthTranslateValues: any

  @Input() maxLengthTranslate: string
  @Input() maxLengthTranslateValues: any

  @Input() rangeTranslate: string
  @Input() rangeTranslateValues: string

  @Input() minTranslate: string
  @Input() minTranslateValues: string

  @Input() maxTranslate: string
  @Input() maxTranslateValues: string

  @Input() patternTranslate: string
  @Input() patternTranslateValues: any

  @Input() uniqueEmailTranslate: string
  @Input() uniqueEmailTranslateValues: any

  @Input() uniqueDomainTranslate: string
  @Input() uniqueDomainTranslateValues: any

  @Input() enterpriseDomainTranslate: string
  @Input() enterpriseDomainTranslateValues: any

  @Input() domainBlackListedTranslate: string
  @Input() domainBlackListedTranslateValues: any

  @Input() uniqueListTranslate: string
  @Input() uniqueListTranslateValues: string

  @ViewChild('contentdiv') contentDiv: ElementRef
  @ViewChild('label') labelEl: ElementRef

  isComplexBoolControl = false
  isSelectControl = false
  requiredAddon: string
  isErrorVisible: () => boolean
  placeholder: string

  private logger: Logger
  private nElement: HTMLElement

  private inputType: FormControlInputType
  private formControlElements: Element[] = []
  private labelCollection: HTMLLabelElement[] = []

  constructor(
    loggerService: LoggerService,
    el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    this.logger = loggerService.getInstance('FormControl')
    this.nElement = el.nativeElement
  }

  ngOnInit() {
    // init default values
    this.show = this.show === undefined ? true : this.show
    this.errorDisplayStrategy =
      this.errorDisplayStrategy === undefined ? FormControlErrorDisplayStrategy.TOUCHED : this.errorDisplayStrategy

    if (!this.formControlField) {
      throw new Error(
        'There was no formControlField bound to the sc-form-control using the formControlField attribute, need one to work'
      )
    }

    this.isErrorVisible = function(): boolean {
      let visible: boolean
      switch (this.errorDisplayStrategy) {
        case FormControlErrorDisplayStrategy.TOUCHED:
          visible = this.formControlField.touched
          break
        case FormControlErrorDisplayStrategy.DIRTY:
          visible = this.formControlField.dirty
          break
        case FormControlErrorDisplayStrategy.INVALID:
          visible = this.formControlField.invalid
          break
        default:
          visible = this.formControlField.touched
      }
      return visible
    }
  }

  ngAfterViewInit() {
    if (this.show) {
      // get the element and add attributes
      const children: HTMLCollection = this.contentDiv.nativeElement.children

      if (children.length === 1 && children.item(0).hasAttribute('formControlName')) {
        // only one child
        this.formControlElements.push(children.item(0))
      } else {
        // more than one child, search for the one with the formControlName attribute
        this.traverseElements(children)
      }

      if (this.formControlElements.length === 0) {
        // NOTE if you build a form dynamically and iterate over the controls to setup the form in the html template
        // you probably can't set the formControlName on the input html element directly as a static string.
        // WORKAROUND: use it like this [attr.formControlName]="item.name". somehow the attribute is not set otherwise..
        // -> [formControlName]="item.name" should work but doesn't.
        throw new Error(
          'There was no child found in sc-form-control which has the formControlName attribute, need one to work'
        )
      } else {
        // decorate the form element and label with name and id attrs
        const name: string | null = this.formControlElements[0].getAttribute('formControlName')
        let postfix = 1

        if (this.labelEl !== undefined) {
          this.renderer.setAttribute(this.labelEl.nativeElement, 'for', name + postfix)
        }

        // only used if labelTranslate is not set
        if (this.formControlElements[0].getAttribute('placeholder')) {
          this.placeholder = this.formControlElements[0].getAttribute('placeholder')
        }

        this.inputType = this.resolveInputType(this.formControlElements[0])

        // add special classes depending on wrapped component
        this.renderer.addClass(this.nElement, `form-control--${this.inputType.main}`)
        this.renderer.addClass(this.nElement, `form-control--${this.inputType.sub}`)
        !!this.inputType.dir
          ? this.renderer.addClass(this.nElement, `form-control--${this.inputType.dir}`)
          : this.renderer.removeClass(this.nElement, `form-control--${this.inputType.dir}`)

        // add id, name and css class to each found formControl element
        this.formControlElements.forEach(element => {
          const isComplexCheckbox = this.inputType.nodeName === FormControlComponent.NODE_NAME_SC_CHECKBOX
          const isComplexRadioGroup = this.inputType.nodeName === FormControlComponent.NODE_NAME_SC_RADIO_GROUP
          const isComplexSlideToggle = this.inputType.nodeName === FormControlComponent.NODE_NAME_SC_SLIDE_TOGGLE
          const isSelectControl = this.inputType.nodeName === FormControlComponent.NODE_NAME_SELECT
          const isInputControl = this.inputType.nodeName === FormControlComponent.NODE_NAME_INPUT

          if (isSelectControl) {
            this.isSelectControl = true
          }

          if (isComplexCheckbox || isComplexRadioGroup || isComplexSlideToggle) {
            this.isComplexBoolControl = true
            this.renderer.addClass(element, 'form-control__input-container')
          } else if (isInputControl) {
            this.renderer.setAttribute(element, 'id', name + postfix)
            this.renderer.setAttribute(element, 'name', name + postfix)
            this.renderer.addClass(element, 'form-control__input')
            this.addClassBasedOnErrorDisplayStrategy(element)
          } else {
            this.renderer.addClass(element, 'form-control__input')
            this.addClassBasedOnErrorDisplayStrategy(element)
          }

          postfix++
        })

        // set for attribute on found labels
        postfix = 1
        if (this.labelCollection.length > 0 && this.inputType.nodeName === FormControlComponent.NODE_NAME_INPUT) {
          this.labelCollection.forEach(htmlLabelElement => {
            if (this.formControlElements.length >= postfix) {
              this.renderer.setAttribute(htmlLabelElement, 'for', name + postfix)
              postfix++
            } else {
              throw new Error(
                'Too many label elements inside the sc-form-control. ' +
                  'needs to be same amount as elements with formControlName attribute to work.'
              )
            }
          })
        }

        // add custom symbol for all required fields
        if (this.formControlField.errors && this.formControlField.errors['required']) {
          this.requiredAddon = ''
        }

        if (this.disabled !== undefined) {
          this.disabledHelper()
        }

        // needs to be done to avoid 'has changed after checked' error when changing the requiredAddon value
        this.changeDetectorRef.detectChanges()
      }
    } else {
      this.logger.debug('show is false, therefore the element is not displayed..')
    }
  }

  ngOnChanges(changes: any): void {
    if (changes.disabled !== undefined) {
      this.disabledHelper()
    }
  }

  // Check for main and subtype which handles appearance via css classes
  private resolveInputType(inputElement: Element): FormControlInputType {
    let result: FormControlInputType
    const nodeName: string = inputElement.nodeName.toLowerCase()

    // Form-control has the base type `bool`
    if (nodeName === FormControlComponent.NODE_NAME_SC_CHECKBOX) {
      result = new FormControlInputType('sc-checkbox', 'bool', 'checkbox', this.boolDirection)
    } else if (nodeName === FormControlComponent.NODE_NAME_SC_RADIO_GROUP) {
      result = new FormControlInputType('sc-radio-group', 'bool', 'radio', this.boolDirection)
    } else if (nodeName === FormControlComponent.NODE_NAME_SC_SLIDE_TOGGLE) {
      result = new FormControlInputType('sc-slide-toggle', 'bool', 'radio', this.boolDirection)
    } else {
      const nodeType: string = (<HTMLInputElement>inputElement).type.toLowerCase()
      // tslint:disable-next-line:no-bitwise
      if (nodeName === 'input' && ~['radio', 'checkbox'].indexOf(nodeType)) {
        result = new FormControlInputType('input', 'bool', nodeType, this.boolDirection)
      } else if (nodeName === 'select') {
        this.isSelectControl = true
        result = new FormControlInputType('input', 'input', 'select', '')
      } else {
        result = new FormControlInputType('input', 'input', nodeType, '')
      }
    }

    return result
  }

  // this will look for the formControl elements and collect all contained labels
  private traverseElements(children: HTMLCollection): void {
    for (let i = 0; i < children.length; i++) {
      const child: Element = children.item(i)
      if (child instanceof HTMLElement && child.hasAttribute('formControlName')) {
        this.formControlElements.push(child)
        // break;
      } else if (child instanceof HTMLLabelElement) {
        this.labelCollection.push(child)
      } else if (child instanceof HTMLElement) {
        const childEl: HTMLElement = <HTMLElement>child
        if (childEl.children.length) {
          this.traverseElements(childEl.children)
        }
      }
    }
  }

  private disabledHelper(): void {
    if (this.formControlElements.length > 0) {
      // this.logger.debug('disabled state changed to: ', this.disabled);
      const value: string | null = this.disabled ? 'disabled' : null // null means the attribute will be removed
      this.formControlElements.forEach(element => {
        this.renderer.setAttribute(element, 'disabled', <string>value)
      })
    }
  }

  private addClassBasedOnErrorDisplayStrategy(fce: Element): void {
    if (this.errorDisplayStrategy === FormControlErrorDisplayStrategy.DIRTY) {
      this.renderer.addClass(fce, 'form-control__input--dirty')
    }
  }
}
