/**
 * Cell definition for the mat-table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
import { CdkCell, CdkCellDef, CdkColumnDef, CdkHeaderCell, CdkHeaderCellDef } from '@angular/cdk/table'
import { Directive, ElementRef, Input } from '@angular/core'

@Directive({
  selector: '[scCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: ScCellDef }],
})
// tslint:disable-next-line:directive-class-suffix
export class ScCellDef extends CdkCellDef {}

/**
 * Header cell definition for the sc-table.
 * Captures the template of a column's header cell and as well as cell-specific properties.
 */
// tslint:disable-next-line:max-classes-per-file
@Directive({
  selector: '[scHeaderCellDef]',
  providers: [{ provide: CdkHeaderCellDef, useExisting: ScHeaderCellDef }],
})
// tslint:disable-next-line:directive-class-suffix
export class ScHeaderCellDef extends CdkHeaderCellDef {}

/**
 * Column definition for the sc-table.
 * Defines a set of cells available for a table column.
 */
// tslint:disable-next-line:max-classes-per-file
@Directive({
  selector: '[scColumnDef]',
  providers: [{ provide: CdkColumnDef, useExisting: ScColumnDef }],
})
// tslint:disable-next-line:directive-class-suffix
export class ScColumnDef extends CdkColumnDef {
  /** Unique name for this column. */
  // tslint:disable-next-line:no-input-rename
  @Input('scColumnDef') name: string
}

/** Header cell template container that adds the right classes and role. */
// tslint:disable-next-line:max-classes-per-file
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'sc-header-cell',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'sc-header-cell',
    role: 'columnheader',
  },
})
// tslint:disable-next-line:directive-class-suffix
export class ScHeaderCell extends CdkHeaderCell {
  constructor(columnDef: CdkColumnDef, elementRef: ElementRef) {
    super(columnDef, elementRef)
    elementRef.nativeElement.classList.add(`sc-column-${columnDef.cssClassFriendlyName}`)
  }
}

/** Cell template container that adds the right classes and role. */
// tslint:disable-next-line:max-classes-per-file
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'sc-cell',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'sc-cell sc-cell--gutter-small',
    role: 'gridcell',
  },
})
// tslint:disable-next-line:directive-class-suffix
export class ScCell extends CdkCell {
  constructor(columnDef: CdkColumnDef, elementRef: ElementRef) {
    super(columnDef, elementRef)
    elementRef.nativeElement.classList.add(`sc-column-${columnDef.cssClassFriendlyName}`)
  }
}
