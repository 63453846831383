import { DOCUMENT } from '@angular/common'
import { Inject, Injectable, Optional } from '@angular/core'
import { isObject, pull } from 'lodash'
import { WindowRef } from '../window/browser-window.ref'
import { DefaultLocalStorageOptions, LocalStorageOptions } from './local-storage-options'
import { WebStorageType } from './web-storage-type.enum'
import { createUUID } from '../static-utils/create-uuid.function'
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the moment-es6 module with a default export.
// TODO: See if we can clean this up at some point.
import moment from 'moment-es6'

export type StorageEventListener = (event: StorageEvent) => void

/**
 * Provides easy access to the local storage with cookie fallback.
 * The source is based on the angular1 version of angular-local-storage module (https://github.com/grevory/angular-local-storage)
 */
@Injectable()
export class LocalStorage {
  static LS_WINDOW_UUID = 'WINDOW_UUID'
  /*
   * injected properties
   */
  private options: LocalStorageOptions
  private webStorage: any // the browser implementation we use to store and retrieve items
  private isLocalStorageSupported: boolean
  private isCookieSupported: boolean
  private window: Window
  private storageListeners: Map<string, StorageEventListener[]> = new Map<string, StorageEventListener[]>()
  private windowUUID: string

  constructor(windowRef: WindowRef, @Inject(DOCUMENT) private document: any, @Optional() options: LocalStorageOptions) {
    this.window = windowRef.nativeWindow

    const defaultOptions: LocalStorageOptions = new DefaultLocalStorageOptions()
    if (options) {
      defaultOptions.merge(options)
    }

    this.options = defaultOptions

    this.init()
  }

  /* ----------------------------------------------------------------------------------------------------------------
   *
   * exported api for the local storage service (aliases for private api, for convenient usage)
   *
   ---------------------------------------------------------------------------------------------------------------- */
  setItem(key: string, value: any): void {
    this.addToLocalStorage(LocalStorage.LS_WINDOW_UUID, this.windowUUID)
    this.addToLocalStorage(key, value)
  }

  setMomentItem(key: string, date: moment.Moment): void {
    const dateSerialized: string = date.format() // ISO formatted string
    this.addToLocalStorage(key, dateSerialized)
  }

  getItem(key: string): any {
    return this.getFromLocalStorage(key)
  }

  getMomentItem(key: string): moment.Moment {
    const dateSerialized = this.getFromLocalStorage(key)
    return moment(dateSerialized)
  }

  keys(): string[] {
    return this.getKeysForLocalStorage()
  }

  remove(...keys: string[]): void {
    this.addToLocalStorage(LocalStorage.LS_WINDOW_UUID, this.windowUUID)
    this.removeFromLocalStorage(...keys)
  }

  clearAll(): void {
    this.addToLocalStorage(LocalStorage.LS_WINDOW_UUID, this.windowUUID)
    this.clearAllFromLocalStorage()
  }

  addListener(key: string, listener: StorageEventListener): void {
    if (this.storageListeners.size === 0) {
      // add storage listener
      this.addStorageListener(this.handleStorageEvent.bind(this))
    }

    let listenersForKey: StorageEventListener[] | undefined = this.storageListeners.get(key)

    if (!listenersForKey) {
      listenersForKey = [listener]
    } else {
      listenersForKey.push(listener)
    }

    this.storageListeners.set(key, listenersForKey)
  }

  removeListener(key: string, listener: StorageEventListener): void {
    const listenersForKey: StorageEventListener[] | undefined = this.storageListeners.get(key)

    // remove listener if it exists
    if (listenersForKey && listenersForKey.length) {
      pull(listenersForKey, listener)
    }

    if (this.storageListeners.size === 0) {
      this.removeStorageListener(this.handleStorageEvent)
    }
  }

  handleStorageEvent(event: StorageEvent): void {
    // ie 11 fix for same window events
    if (!this.isInSameWindow() && this.storageListeners.has(this.underiveQualifiedKey(event.key))) {
      // notify all listeners
      const mapKey = this.underiveQualifiedKey(event.key)
      const listenersForKey: StorageEventListener[] | undefined = this.storageListeners.get(mapKey)
      if (listenersForKey) {
        const convertedEvent = { ...event }
        convertedEvent.newValue = JSON.parse(event.newValue)
        convertedEvent.oldValue = JSON.parse(event.oldValue)
        listenersForKey.forEach((listener: StorageEventListener) => {
          listener(convertedEvent)
        })
      }
    }
  }

  getCurrentWindowUUID(): string {
    return this.windowUUID
  }

  private isInSameWindow(): boolean {
    // ie 11 fix (storage event also fires inside the calling tab/window)
    const currentWindowUUID: string = this.getCurrentWindowUUID()
    const storedWindowUUID: string = this.getItem(LocalStorage.LS_WINDOW_UUID)

    return currentWindowUUID === storedWindowUUID
  }

  private init() {
    this.isLocalStorageSupported = this.browserSupportsLocalStorage()
    this.isCookieSupported = this.browserSupportsCookies()

    // If there is a prefix set in the config lets use that with an appended period for readability
    if (this.options.prefix.substr(-1) !== '.') {
      this.options.prefix = !!this.options.prefix ? this.options.prefix + '.' : ''
    }

    this.windowUUID = createUUID()
  }

  /* ----------------------------------------------------------------------------------------------------------------
   *
   * api classes
   *
   ---------------------------------------------------------------------------------------------------------------- */
  /*
   * public api
   */
  private addToLocalStorage(key: string, value: any) {
    // Let's convert undefined values to null to get the value consistent
    if (value === undefined) {
      value = null
    } else {
      value = JSON.stringify(value)
    }

    // If this browser does not support local storage use cookies
    if (
      (!this.isLocalStorageSupported && this.options.defaultToCookie) ||
      this.options.storageType === WebStorageType.Cookie
    ) {
      return this.addToCookies(key, value)
    }

    try {
      if (this.webStorage) {
        this.webStorage.setItem(this.deriveQualifiedKey(key), value)
      }
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e.Message)
      return this.addToCookies(key, value)
    }
    return true
  }

  private getFromLocalStorage(key: string): any {
    if (
      (!this.isLocalStorageSupported && this.options.defaultToCookie) ||
      this.options.storageType === WebStorageType.Cookie
    ) {
      return this.getFromCookies(key)
    }

    const item = this.webStorage ? this.webStorage.getItem(this.deriveQualifiedKey(key)) : null
    // angular.toJson will convert null to 'null', so a proper conversion is needed
    // NOTE not a perfect solution, since a valid 'null' string can't be stored
    if (!item || item === 'null') {
      return null
    }

    try {
      return JSON.parse(item)
    } catch (e) {
      return item
    }
  }

  // Remove an item from local storage
  // Example use: localStorageService.remove('library'); // removes the key/value pair of library='angular'
  private removeFromLocalStorage(...keys: string[]) {
    for (const key of keys) {
      if (
        (!this.isLocalStorageSupported && this.options.defaultToCookie) ||
        this.options.storageType === WebStorageType.Cookie
      ) {
        this.removeFromCookies(key)
      } else {
        try {
          this.webStorage.removeItem(this.deriveQualifiedKey(key))
        } catch (e) {
          // tslint:disable-next-line:no-console
          console.error(e.message)
          this.removeFromCookies(key)
        }
      }
    }
  }

  // Return array of keys for local storage
  // Example use: var keys = localStorageService.keys()
  /**
   *
   * @returns Returns all the keys for local storage items which start with our prefix
   */
  private getKeysForLocalStorage(): string[] {
    if (!this.isLocalStorageSupported && this.isCookieSupported) {
      return this.getCookieKeys()
    }

    const prefixLength: number = this.options.prefix.length
    const keys: string[] = []
    for (const key in this.webStorage) {
      // Only return keys that are for this app
      if (key.substr(0, prefixLength) === this.options.prefix) {
        try {
          keys.push(key.substr(prefixLength))
        } catch (e) {
          // tslint:disable-next-line:no-console
          console.error((<Error>e).message)
          return []
        }
      }
    }

    return keys
  }

  /**
   * Removes all data which starts with our prefix from local storage. Optionally a regular expression can be provided
   * to just remove matching items.
   */
  private clearAllFromLocalStorage(regularExpression?: any): boolean {
    // Setting both regular expressions independently
    // Empty strings result in catchall RegExp
    const prefixRegex: RegExp = !!this.options.prefix ? new RegExp('^' + this.options.prefix) : new RegExp('')
    const testRegex: RegExp = !!regularExpression ? new RegExp(regularExpression) : new RegExp('')

    if (
      (!this.isLocalStorageSupported && this.options.defaultToCookie) ||
      this.options.storageType === WebStorageType.Cookie
    ) {
      return this.clearAllFromCookies()
    }

    if (!this.isLocalStorageSupported && !this.options.defaultToCookie) {
      return false
    }

    const prefixLength: number = this.options.prefix.length

    for (const key in this.webStorage) {
      // Only remove items that are for this app and match the regular expression
      if (prefixRegex.test(key) && testRegex.test(key.substr(prefixLength))) {
        try {
          this.removeFromLocalStorage(key.substr(prefixLength))
        } catch (e) {
          // tslint:disable-next-line:no-console
          console.error((<Error>e).message)
          return this.clearAllFromCookies()
        }
      }
    }

    return true
  }

  /**
   * Adds an event listener to react to any storage changes, this will not work on the page making the changes,
   * it is only to react in inactive tabs for example
   * @param listener
   */
  private addStorageListener(listener: any) {
    this.window.addEventListener('storage', listener)
  }

  private removeStorageListener(listener: any): void {
    this.window.removeEventListener('storage', listener)
  }

  private addToCookies(key: string, value: any, daysToExpiry?: number): boolean {
    if (value === undefined) {
      return false
    } else if (Array.isArray(value) || isObject(value)) {
      value = JSON.stringify(value)
    }

    if (!this.isCookieSupported) {
      // tslint:disable-next-line:no-console
      console.error('cookies not supported')
      return false
    }

    try {
      let expiry = ''
      const expiryDate: Date = new Date()
      let cookieDomain = ''

      if (value === null) {
        // Mark that the cookie has expired one day ago
        expiryDate.setTime(expiryDate.getTime() + -1 * 24 * 60 * 60 * 1000)
        expiry = '; expires=' + expiryDate.toUTCString()
        value = ''
      } else if (Number.isInteger(daysToExpiry) && daysToExpiry !== 0) {
        expiryDate.setTime(expiryDate.getTime() + daysToExpiry * 24 * 60 * 60 * 1000)
        expiry = '; expires=' + expiryDate.toUTCString()
      } else if (this.options.cookieOptions.expiry !== 0) {
        expiryDate.setTime(expiryDate.getTime() + this.options.cookieOptions.expiry * 24 * 60 * 60 * 1000)
        expiry = '; expires=' + expiryDate.toUTCString()
      }

      if (!!key) {
        const cookiePath = '; path=' + this.options.cookieOptions.path
        if (this.options.cookieOptions.domain) {
          cookieDomain = '; domain=' + this.options.cookieOptions.domain
        }

        let additions = ''
        if (this.options.cookieOptions.secure) {
          additions += '; secure'
        }

        if (this.options.cookieOptions.httpOnly) {
          additions += '; httpOnly'
        }

        this.document.cookie =
          this.deriveQualifiedKey(key) +
          '=' +
          encodeURIComponent(value) +
          expiry +
          cookiePath +
          cookieDomain +
          additions
      }
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error((<Error>e).message)
      return false
    }

    return true
  }

  /**
   * @param key
   * @returns Returns false if cookies are not supported, otherwise the stored value will be retured as a js object
   * (using Json.parse()), returns null if key was not found
   */
  private getFromCookies(key): any {
    if (!this.isCookieSupported) {
      // tslint:disable-next-line:no-console
      console.error('cookies are not supported')
      return false
    }

    const cookies = (this.document.cookie && this.document.cookie.split(';')) || []
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < cookies.length; i++) {
      let thisCookie = cookies[i]
      while (thisCookie.charAt(0) === ' ') {
        thisCookie = thisCookie.substring(1, thisCookie.length)
      }
      if (thisCookie.indexOf(this.deriveQualifiedKey(key) + '=') === 0) {
        const storedValues = decodeURIComponent(
          thisCookie.substring(this.options.prefix.length + key.length + 1, thisCookie.length)
        )
        try {
          return JSON.parse(storedValues)
        } catch (e) {
          return storedValues
        }
      }
    }

    return null
  }

  private removeFromCookies(key: string): boolean {
    return this.addToCookies(key, null)
  }

  private clearAllFromCookies(): boolean {
    let thisCookie: string
    const prefixLength: number = this.options.prefix.length
    const cookies: string[] = this.document.cookie.split(';')

    let result: boolean

    if (cookies.length) {
      result = true // initialize with true to use the & operator
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < cookies.length; i++) {
        thisCookie = cookies[i]

        while (thisCookie.charAt(0) === ' ') {
          thisCookie = thisCookie.substring(1, thisCookie.length)
        }

        const key = thisCookie.substring(prefixLength, thisCookie.indexOf('='))
        result = result && this.removeFromCookies(key)
      }
    } else {
      result = false
    }

    return result
  }

  private getCookieKeys(): string[] {
    let thisCookie: string
    const prefixLength: number = this.options.prefix.length
    const cookies: string[] = this.document.cookie.split(';')

    const result = []

    if (cookies.length) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < cookies.length; i++) {
        thisCookie = cookies[i]

        while (thisCookie.charAt(0) === ' ') {
          thisCookie = thisCookie.substring(1, thisCookie.length)
        }

        const key = thisCookie.substring(prefixLength, thisCookie.indexOf('='))
        result.push(key)
      }
    }

    return result
  }

  /* ----------------------------------------------------------------------------------------------------------------
   *
   * helper methods
   *
   ---------------------------------------------------------------------------------------------------------------- */
  /**
   * Adds the prefix to the start of the key
   */
  private deriveQualifiedKey(key: string) {
    return this.options.prefix + key
  }

  /**
   * Removes prefix from key
   */
  /* tslint:disable */
  private underiveQualifiedKey(key: string | undefined): string | undefined {
    /* tslint:enable */
    if (key) {
      return key.replace(new RegExp('^' + this.options.prefix, 'g'), '')
    } else {
      return key
    }
  }

  /**
   * Checks if the key is withing our prefix namespace
   */
  /* tslint:disable */
  private isKeyPrefixOurs(key: string): boolean {
    /* tslint:enable */
    return key.indexOf(this.options.prefix) === 0
  }

  /**
   * Checks if local storage is supported in current browser
   */
  private browserSupportsLocalStorage(): boolean {
    try {
      const supported =
        this.options.getStorageTypeImplName() in this.window &&
        this.window[this.options.getStorageTypeImplName()] !== null

      // When Safari (OS X or iOS) is in private browsing mode, it appears as though localStorage
      // is available, but trying to call .setItem throws an exception.
      //
      // "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made to add something to storage
      // that exceeded the quota."
      const key = this.deriveQualifiedKey('__' + Math.round(Math.random() * 1e7))
      if (supported) {
        this.webStorage = this.window[this.options.getStorageTypeImplName()]
        this.webStorage.setItem(key, '')
        this.webStorage.removeItem(key)
      }

      return supported
    } catch (e) {
      // Only change storageType to cookies if defaulting is enabled.
      if (this.options.defaultToCookie) {
        this.options.storageType = WebStorageType.Cookie
      }

      // tslint:disable-next-line:no-console
      console.error('could not store item to local storage', (<Error>e).message)
      return false
    }
  }

  /**
   * Checks the browser to see if cookies are supported
   */
  private browserSupportsCookies(): boolean {
    try {
      return (
        this.window.navigator.cookieEnabled ||
        ('cookie' in this.document &&
          (this.document.cookie.length > 0 ||
            (this.document.cookie = 'test').indexOf.call(this.document.cookie, 'test') > -1))
      )
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error((<Error>e).message)
      return false
    }
  }
}
