import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { isEmpty } from 'lodash'

// @dynamic
export class ScValidators {
  static notEmpty = (control: AbstractControl): ValidationErrors | null => {
    return control.value !== null && !isEmpty(control.value.trim()) ? null : { required: true }
  }

  static requiredNotOnlyNullInArray = (control: AbstractControl): ValidationErrors | null => {
    return control.value && control.value.length && control.value.filter(v => v !== null).length
      ? null
      : { required: true }
  }

  // NOTE SG does not work with an array with objects so we could pass in a matching field... sucks big time
  static uniqueListValidator = (list: string[]): ValidatorFn => {
    return (control: AbstractControl): any => {
      if (Validators.required(control) !== undefined && Validators.required(control) !== null) {
        return null
      }

      const currentVal: string = control.value

      if (list && list.length) {
        return list.indexOf(currentVal) > -1 ? { uniqueList: { current: currentVal } } : null
      } else {
        return null
      }
    }
  }

  static range(range: number[]): ValidatorFn {
    return (control: AbstractControl): any => {
      // NOTE SG value gets null if user enters 2.2.2 for example and Validators.required(control) has required property... don't know why..
      // problem: if user deletes everything value gets null and input is invalid even it is actually not required
      // solution: we set input to '' if above happens for now
      // let required = Validators.required(control);
      // if (control.value === null && required) {
      //   return {
      //     'range': {
      //       'min': range[0],
      //       'max': range[1],
      //       'current': control.value
      //     }
      //   };
      // } else if (Validators.required(control) !== undefined && Validators.required(control) !== null) {
      //   return null;
      // }

      // stupid solution to set null value to ''
      if (control.value === null) {
        control.setValue('')
        return null
      } else if (control.value === '') {
        return null
      }

      if (/^[0-9]*$/.test(control.value)) {
        const v: number = control.value
        return v >= range[0] && v <= range[1]
          ? null
          : {
              range: { min: range[0], max: range[1], current: v },
            }
      } else {
        return { range: { min: range[0], max: range[1], current: control.value } }
      }
    }
  }
}
