import { ModuleWithProviders, NgModule } from '@angular/core'
import { BrowserWindowRef, WindowRef } from './browser-window.ref'

@NgModule({
  providers: [BrowserWindowRef],
})
export class WindowModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: WindowModule,
      providers: [
        // window
        {
          provide: WindowRef,
          useClass: BrowserWindowRef,
        },
      ],
    }
  }
}
