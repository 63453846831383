import { DOCUMENT } from '@angular/common'
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core'
import { Logger, LoggerService, ScrollToService, WindowRef } from '@shiftcoders/core'

@Component({
  selector: 'sc-scroll-to-top',
  template: `<sc-icon *ngIf="attachToBottom" class="icon icon--md" svgSrc="/assets/icon/icon-20-chevron-up.svg">
</sc-icon>

<div *ngIf="!attachToBottom" class="btn btn--secondary">
  {{labelTranslate ? (labelTranslate | translate) : ('COMMONS.BACK_TO_TOP' | translate)}}
  <sc-icon class="icon icon--md" svgSrc="/assets/icon/icon-20-chevron-up.svg">
  </sc-icon>
</div>

`,
  styles: [`:host{transition:opacity .2s ease;opacity:0}:host(.scroll-to-top--visible) /deep/{opacity:1;cursor:pointer}:host(.scroll-to-top--bottom) /deep/{position:fixed;right:10px;z-index:1;background-color:#06c;color:#fff;padding:12px}:host(.scroll-to-top--default) /deep/{width:100%;display:flex;justify-content:center;margin-top:24px}:host(.scroll-to-top--default) /deep/ .icon{margin-left:3px}`],
})
export class ScrollToTopComponent implements OnInit, OnDestroy, AfterViewInit {
  private static SCROLL_TOP_VISIBLE_CLASS = 'scroll-to-top--visible'
  private static BOTTOM_CLASS = 'scroll-to-top--bottom'
  private static DEFAULT_CLASS = 'scroll-to-top--default'
  private static BOTTOM_OFFSET = 20

  @Input() offset = 80
  @Input() elementId: string
  @Input() attachToBottom = false
  @Input() labelTranslate: string

  readonly listenGlobalFn: () => void
  readonly window: Window

  private logger: Logger
  private element: HTMLElement
  private visible = false
  private bodyEl: HTMLElement

  constructor(
    loggerService: LoggerService,
    windowRef: WindowRef,
    // FIXME type document instead of any
    @Inject(DOCUMENT) private document: any,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private scrollTo: ScrollToService
  ) {
    this.logger = loggerService.getInstance('ScrollToTopComponent')
    this.window = windowRef.nativeWindow
    this.bodyEl = this.window.document.body
    this.listenGlobalFn = this.renderer.listen('window', 'scroll', () => this.handlePosition())
  }

  ngOnInit() {
    this.renderer.addClass(
      this.elementRef.nativeElement,
      this.attachToBottom ? ScrollToTopComponent.BOTTOM_CLASS : ScrollToTopComponent.DEFAULT_CLASS
    )
  }

  ngOnDestroy() {
    this.listenGlobalFn()
  }

  ngAfterViewInit(): void {
    this.element = this.document.getElementById(this.elementId)
  }

  @HostListener('click')
  onClick() {
    if (this.visible) {
      this.scrollTo.scrollToElement(this.element, this.offset)
    }
  }

  private handlePosition() {
    const diffTop = this.scrollTo.getScrollTargetY(this.element, this.offset)
    if (this.element && this.window && (this.window.scrollY > diffTop || this.window.pageYOffset > diffTop)) {
      if (!this.elementRef.nativeElement.classList.contains(ScrollToTopComponent.SCROLL_TOP_VISIBLE_CLASS)) {
        this.renderer.addClass(this.elementRef.nativeElement, ScrollToTopComponent.SCROLL_TOP_VISIBLE_CLASS)
        this.visible = true
      }

      if (this.visible && this.attachToBottom) {
        const scrollTop = this.window.pageYOffset || this.bodyEl.scrollTop
        const footer = this.document.getElementsByTagName('footer')[0]
        const footerHeight = footer ? footer.clientHeight : 0
        const bodyHeight = Math.max(this.bodyEl.scrollHeight, this.bodyEl.offsetHeight, this.bodyEl.clientHeight)
        const windowHeight = this.window.innerHeight || this.bodyEl.clientHeight

        const diff = bodyHeight - windowHeight - scrollTop

        if (diff < footerHeight) {
          this.renderer.setStyle(
            this.elementRef.nativeElement,
            'bottom',
            `${footerHeight - diff + ScrollToTopComponent.BOTTOM_OFFSET}px`
          )
        } else {
          this.renderer.setStyle(this.elementRef.nativeElement, 'bottom', `${ScrollToTopComponent.BOTTOM_OFFSET}px`)
        }
      }
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, ScrollToTopComponent.SCROLL_TOP_VISIBLE_CLASS)
      this.visible = false
    }
  }
}
