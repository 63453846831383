import { ModuleWithProviders, NgModule } from '@angular/core'
import { UIEventService } from './ui-event.service'

@NgModule({})
export class UiEventModule {
  // TODO Review
  // constructor(
  //   @Optional()
  //   @SkipSelf()
  //     parentModule: AuthModule
  // ) {
  //   if (parentModule) {
  //     throw new Error('AuthModule is already loaded. Import it only once')
  //   }
  // }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UiEventModule,
      providers: [UIEventService],
    }
  }
}
