import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { AvatarModule } from '../avatar/avatar.module'
import { IconModule } from '../icon/icon.module'
import { StateButtonModule } from '../state-button/state-button.module'
import { LocaleModule } from '@shiftcoders/core'
import { AutoCompleteComponent } from './auto-complete/auto-complete.component'
import { TagInputComponent } from './tag-input.component'

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, LocaleModule, IconModule, AvatarModule, StateButtonModule],
  declarations: [TagInputComponent, AutoCompleteComponent],
  exports: [TagInputComponent, AutoCompleteComponent],
})
export class TagInputModule {}
