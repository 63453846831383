import { ModuleWithProviders, NgModule } from '@angular/core'
import { ClientIdService } from './client-id.service'

@NgModule({})
export class ClientIdModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ClientIdModule,
      providers: [ClientIdService],
    }
  }
}
