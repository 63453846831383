import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DomReadyDirective } from './dom-ready.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [DomReadyDirective],
  exports: [DomReadyDirective],
})
export class DomReadyModule {}
