import { ImageDimensions } from './image-dimensions.model'
import { Offset } from './offset.model'

export class ImageDimensionsAndOffset {
  width: number
  height: number
  offset: Offset
  // offsetChange: Offset;

  constructor(width: number, height: number, x: number, y: number) {
    // xChange: number, yChange: number
    this.width = width
    this.height = height
    const offset: Offset = new Offset()
    offset.x = x
    offset.y = y
    this.offset = offset

    // let offsetChange: Offset = new Offset();
    // offsetChange.x = xChange;
    // offsetChange.y = yChange;
    // this.offsetChange = offsetChange;
  }
}

// tslint:disable-next-line:max-classes-per-file
export class ImageCropper {
  private static COLORS = {
    overlayFill: 'rgba(245, 245, 245, 0.9)',
    transparent: 'rgba(0, 0, 0, 1)',
  }

  static offsetRespectBoundaries(
    currentImage: ImageDimensions,
    originalImageRatio: number,
    currentScale: number,
    offset: Offset,
    xChange: number,
    yChange: number,
    maxAreaSize: number
  ): Offset {
    let width: number
    let height: number

    // offset 0, 0 -> top / left corner
    let offsetX: number
    let offsetY: number

    // keep proportions of original image
    if (originalImageRatio > 1) {
      // portrait (height > width)
      width = currentImage.width + currentScale
      height = currentImage.height + currentScale * originalImageRatio
      offsetX = offset.x - currentScale / 2 - xChange
      offsetY = offset.y - (currentScale * originalImageRatio) / 2 - yChange
    } else if (originalImageRatio < 1) {
      // landscape (width > height)
      width = currentImage.width + currentScale / originalImageRatio
      height = currentImage.height + currentScale
      offsetX = offset.x - currentScale / originalImageRatio / 2 - xChange
      offsetY = offset.y - currentScale / 2 - yChange
    } else {
      // square
      width = currentImage.width + currentScale
      height = currentImage.height + currentScale
      offsetX = offset.x - currentScale / 2 - xChange
      offsetY = offset.y - currentScale / 2 - yChange
    }

    // check max draggable offsets -> update offsetXYChange
    const maxX = 0
    const minX = maxAreaSize - width
    const maxY = 0
    const minY = maxAreaSize - height

    let offsetXChange: number = xChange
    if (offsetX < minX) {
      offsetXChange = xChange - (minX - offsetX)
    } else if (offsetX > maxX) {
      offsetXChange = xChange + (offsetX - maxX)
    }

    let offsetYChange: number = yChange
    if (offsetY < minY) {
      offsetYChange = yChange - (minY - offsetY)
    } else if (offsetY > maxY) {
      offsetYChange = yChange + (offsetY - maxY)
    }

    return { x: offsetXChange, y: offsetYChange }
  }

  static transformImage(
    currentImage: ImageDimensions,
    originalImageRatio: number,
    currentScale: number,
    offset: Offset,
    xChange: number,
    yChange: number,
    maxAreaSize: number
  ): ImageDimensionsAndOffset {
    let width: number
    let height: number

    // offset 0, 0 -> top / left corner
    let offsetX: number
    let offsetY: number

    // keep proportions of original image
    if (originalImageRatio > 1) {
      // portrait (height > width)
      width = currentImage.width + currentScale
      height = currentImage.height + currentScale * originalImageRatio
      offsetX = offset.x - currentScale / 2 - xChange
      offsetY = offset.y - (currentScale * originalImageRatio) / 2 - yChange
    } else if (originalImageRatio < 1) {
      // landscape (width > height)
      width = currentImage.width + currentScale / originalImageRatio
      height = currentImage.height + currentScale
      offsetX = offset.x - currentScale / originalImageRatio / 2 - xChange
      offsetY = offset.y - currentScale / 2 - yChange
    } else {
      // square
      width = currentImage.width + currentScale
      height = currentImage.height + currentScale
      offsetX = offset.x - currentScale / 2 - xChange
      offsetY = offset.y - currentScale / 2 - yChange
    }

    // check max draggable offsets -> update offsetXYChange
    const maxX = 0
    const minX = maxAreaSize - width
    const maxY = 0
    const minY = maxAreaSize - height

    let offsetXChange: number = xChange
    if (offsetX < minX) {
      offsetXChange = xChange - (minX - offsetX)
      offsetX = minX
    }

    if (offsetX > maxX) {
      offsetXChange = xChange + (offsetX - maxX)
      offsetX = maxX
    }

    let offsetYChange: number = yChange
    if (offsetY < minY) {
      offsetYChange = yChange - (minY - offsetY)
      offsetY = minY
    }

    if (offsetY > maxY) {
      offsetYChange = yChange + (offsetY - maxY)
      offsetY = maxY
    }

    const currentImageProperties: ImageDimensionsAndOffset = new ImageDimensionsAndOffset(
      width,
      height,
      offsetX,
      offsetY
    ) // offsetXChange, offsetYChange

    return currentImageProperties
  }

  /**
   * Draws a circle overlay, this is just a visibility thing, the actual image data will remain the same
   * @param context
   * @param maxAreaSize
   * @param fillColor
   */
  static drawOverlay(context: CanvasRenderingContext2D, maxAreaSize: number, fillColor?: string): void {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = fillColor || ImageCropper.COLORS.overlayFill
    context.rect(context.canvas.width, 0, -context.canvas.width, context.canvas.height)
    context.fill()
    context.globalCompositeOperation = 'destination-out'
    context.fillStyle = ImageCropper.COLORS.transparent
    context.beginPath()
    context.arc(context.canvas.width / 2, context.canvas.height / 2, maxAreaSize / 2, 0, 2 * Math.PI)
    context.fill()
    context.globalCompositeOperation = 'destination-over'
  }

  static toBlob(
    img: HTMLImageElement,
    canvasWidth: number,
    canvasHeight: number,
    width: number,
    height: number,
    x: number,
    y: number,
    fileType: string
  ): Blob {
    const canvas = <HTMLCanvasElement>document.createElement('canvas')
    canvas.width = canvasWidth
    canvas.height = canvasHeight
    const ctxCanvas = canvas.getContext('2d')
    if (ctxCanvas !== null) {
      ctxCanvas.drawImage(img, x, y, width, height)
    } else {
      throw Error("Canvas Rendering Context is null, can't draw the image")
    }

    // TODO LOW why are we not generating a blob directly using toBlob()
    const url = canvas.toDataURL(fileType)

    const blob = ImageCropper.dataURItoBlob(url)
    return blob
  }

  private static dataURItoBlob(dataURI): Blob {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1])
    } else {
      byteString = decodeURI(dataURI.split(',')[1])
    }

    // separate out the mime component
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0]

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], { type: mimeString })
  }
}
