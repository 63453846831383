import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators'

@Component({
  selector: 'sc-search-input',
  template: `<form [formGroup]="form">
  <sc-form-control class="form-control--input form-control--icon"
                   [formControlField]="form.get('search')">
    <input type="search"
           [placeholder]="'COMMONS.SEARCH' | translate"
           formControlName="search">
    <span class="form-control__icon">
      <sc-icon class="icon icon--lg" svgSrc="/assets/icon/icon-40-search.svg"></sc-icon>
    </span>
  </sc-form-control>
</form>
`,
  styles: [`:host{display:block}`],
  exportAs: 'scSearchInput',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'titlebar__col col-12 col-lg-8',
  },
})
export class SearchInputComponent implements OnInit {
  searchQuery$: Observable<null | string>
  form: FormGroup

  constructor() {}

  ngOnInit() {
    this.form = new FormGroup({
      search: new FormControl(),
    })

    this.searchQuery$ = this.form.valueChanges.pipe(
      debounceTime(150),
      map(values => values['search']),
      map(searchQuery => (searchQuery ? searchQuery : null)),
      distinctUntilChanged()
    )
  }
}
