import {
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Logger, LoggerService, createUUID } from '@shiftcoders/core'

@Component({
  selector: 'sc-file-selector',
  template: `<label [attr.for]="id">
  <ng-content></ng-content>
</label>

<!-- needed for file upload-->
<input #input
       [id]="id"
       type="file"
       [multiple]="multiple"
       [accept]="accept">
`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileSelectorComponent),
      multi: true,
    },
  ],
})
export class FileSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
  private static DEFAULT_ACCEPT = '*'

  @Input() required: boolean
  @Input() multiple: boolean
  @Input() accept: string // provide multiple vales with a comma, see http://www.w3schools.com/tags/att_input_accept.asp for valid inputs

  @ViewChild('input') input: ElementRef

  propagateChange: (newValue: FileList) => {}
  propagateTouched: () => {}
  id: string

  private logger: Logger

  constructor(loggerService: LoggerService, private renderer: Renderer2) {
    this.logger = loggerService.getInstance('FileSelector')
    this.id = createUUID()
  }

  ngOnInit(): any {
    // hide input field
    this.renderer.setStyle(this.input.nativeElement, 'display', 'none')

    // init defaults
    this.accept = this.accept || FileSelectorComponent.DEFAULT_ACCEPT
  }

  @HostListener('change')
  onChange(): void {
    const files: FileList = this.input.nativeElement.files
    this.logger.debug('onchange', files)

    this.propagateChange(files)
    this.propagateTouched()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const requiredChange: SimpleChange = changes['required']
    const multipleChange: SimpleChange = changes['multiple']
    const acceptChange: SimpleChange = changes['accept']

    // convert boolean attributes (attributes with no values) to actual boolean values
    if (requiredChange && requiredChange.currentValue === '') {
      this.required = true
    }

    if (multipleChange && multipleChange.currentValue === '') {
      this.multiple = true
    }

    if (acceptChange && !acceptChange.currentValue) {
      this.accept = FileSelectorComponent.DEFAULT_ACCEPT
    }
  }

  /*
   * ControlValueAccessor implementation
   */

  /*
   * write from model to view
   */
  writeValue(value: FileList): void {
    // selected files are not represented in the view by default, just for testing
    this.logger.debug('writeValue :: (%o)', value)
  }

  /*
   * is called whenever something in the view changed, a new file was selected
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn
  }

  /*
   * called whenever a component was Â«touchedÂ»
   */
  registerOnTouched(fn: any): void {
    // not required
    this.propagateTouched = fn
  }
}
