import * as moment from 'moment'
import { get } from 'lodash'

export type SortDirection = 'asc' | 'desc'

export class MomentComparator {
  static comparePath<T>(path: keyof T, direction?: SortDirection): (a: any, b: any) => 1 | 0 | -1
  static comparePath(path: string, direction?: SortDirection): (a: any, b: any) => 1 | 0 | -1

  static comparePath(path: string, direction: SortDirection = 'desc'): (a: any, b: any) => 1 | 0 | -1 {
    return (a, b) => {
      const aMoment: moment.Moment = get(a, path)
      const bMoment: moment.Moment = get(b, path)

      if (aMoment.isBefore(bMoment)) {
        return direction === 'desc' ? 1 : -1
      } else if (aMoment.isSame(bMoment)) {
        return 0
      } else {
        return direction === 'desc' ? -1 : 1
      }
    }
  }

  static compare(direction: SortDirection = 'desc'): (a: moment.Moment, b: moment.Moment) => 1 | 0 | -1 {
    return (a, b) => {
      if (a.isBefore(b)) {
        return direction === 'desc' ? 1 : -1
      } else if (a.isSame(b)) {
        return 0
      } else {
        return direction === 'desc' ? -1 : 1
      }
    }
  }
}
