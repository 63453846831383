import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { IconModule } from '../icon/icon.module'
import { StateButtonModule } from '../state-button/state-button.module'
import { LocaleModule } from '@shiftcoders/core'
import { ImageCropperComponent } from './image-cropper.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    // sc ng commons
    LocaleModule,
    IconModule,
    StateButtonModule,
  ],
  declarations: [ImageCropperComponent],
  exports: [ImageCropperComponent],
})
export class ImageCropperModule {}
