import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core'
import { DeviceInfoService, Logger, LoggerService, WindowRef } from '@shiftcoders/core'
import { first } from 'rxjs/operators'

@Directive({
  selector: '[scAutoFocus]',
})
/**
 * Will focus the current element and scroll to top if activated
 */
export class AutoFocusDirective implements OnInit, AfterViewInit {
  @Input('scAutoFocus')
  get scrollToTopOnFocus(): boolean {
    return this._scrollToTopOnFocus
  }

  set scrollToTopOnFocus(value: boolean) {
    this._scrollToTopOnFocus = !!value
  }

  private _scrollToTopOnFocus: boolean

  private logger: Logger
  private window: Window

  constructor(
    loggerService: LoggerService,
    windowRef: WindowRef,
    private el: ElementRef,
    private deviceInfoService: DeviceInfoService
  ) {
    this.logger = loggerService.getInstance('AutoFocusDirective')
    this.window = windowRef.nativeWindow
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.deviceInfoService.featureChanges.pipe(first()).subscribe(features => {
      if (!features.touch) {
        this.logger.debug('device has no touch functionality -> set focus to element')
        ;(<HTMLElement>this.el.nativeElement).focus()
        if (this.scrollToTopOnFocus) {
          this.window.scrollTo(0, 0)
        }
      }
    })
  }
}
