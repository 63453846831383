import { Injectable } from '@angular/core'
import { ProjectStage } from './project-stage.enum'
import { ServiceType } from './service-type'
import { UrlType } from './url-type.enum'

/**
 * it's not possible to inject an interface because there's no metadata at runtime for interfaces
 * so you can't register a provider for an interface like:
 * {provider: IExample, useClass: ExampleImpl}
 *
 * We use abstract classes for this purpose
 */
@Injectable()
export abstract class RuntimeConfiguration {
  projectStage: ProjectStage
  urlType: UrlType
  appName: string
  appVariant: string

  // values for all envs the same
  logglyToken: string

  protected endpointMap: Map<number, Map<ServiceType, string>>

  abstract getUrlForRestService(microService: number): string

  abstract getUrlForWebSocketService(microService: number): string

  abstract isMicroServiceUrl(url: string): boolean

  abstract isProduction(): boolean

  abstract getAnalyticsId(): string
}
