import { ModuleWithProviders, NgModule } from '@angular/core'
import { HttpErrorService } from './http-error.service'

@NgModule({})
export class HttpModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: HttpModule,
      providers: [
        // http
        HttpErrorService,
      ],
    }
  }
}
