import { Inject, Injectable } from '@angular/core'
import { FetchDataFn, PagedDataSource, PagedMeta } from './paged-data-source'
import { PAGED_META } from './paged-meta.injection-token'
import { Logger, LoggerService } from '@shiftcoders/core'

@Injectable()
export class PagedDataSourceFactory {
  private logger: Logger

  constructor(private loggerService: LoggerService, @Inject(PAGED_META) private pagedMeta: PagedMeta) {
    this.logger = loggerService.getInstance('PagedDataSource')
  }

  createDataSource<T>(fetchDataFn: FetchDataFn<T>): PagedDataSource<T> {
    return new PagedDataSource(this.loggerService, fetchDataFn, this.pagedMeta)
  }
}
