import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { finalize, takeUntil } from 'rxjs/operators'
import { Logger, LoggerService } from '@shiftcoders/core'

@Component({
  selector: 'sc-state-button',
  template: `<button #button
        [type]="type"
        [disabled]="disabled || working"
        class="btn btn--loader"
        [ngClass]="[
          working ? 'btn--is-loading' : '',
          facet && ('btn--' + facet),
          size && ('btn--' + size)
        ]"
        [attr.data-inert]="working ? true : null">
  <span class="btn__content">
    <span class="btn__wrap">
      <span class="btn__icon" *ngIf="icon">
        <sc-icon class="icon icon--breakout"
                 [ngClass]="['icon--' + iconSize]" [svgSrc]="icon">
        </sc-icon>
      </span>
      <span *ngIf="labelTranslate" class="btn__label" [translate]="labelTranslate"
            [translateValues]="labelTranslateValues">
      </span>
    </span>
  </span>
  <span class="btn__loader">
    <sc-loading-indicator [size]="size"></sc-loading-indicator>
  </span>
</button>




`,
})
export class StateButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() working: boolean
  @Input() type = 'submit'
  @Input() labelTranslate: string
  @Input() labelTranslateValues: { [key: string]: string }
  @Input() icon: string
  @Input() disabled: boolean
  @Input() facet: string
  @Input() size: 'sm' | 'md' // one of sm, md (Default)
  @Input() iconSize: 'sm' | 'md' | 'lg' | 'xl' // one of sm, md, lg, xl
  @Input() worker$: Observable<any>

  // compared to the worker observable which changes for each asyn operation,
  // this observable stays the same, so we just subscribe it and handle the incoming events
  @Input() loading$: Observable<boolean>

  @Output() scsClick = new EventEmitter<any>()

  @ViewChild('button') button: ElementRef

  /*
   * set a tabindex to allow the custom html element to be able to receive focus events,
   * but make the index high enough so the state button will not
   * get the index when tabbing away from the component
   * (0 would always give the focus back to the state-button when tabbing away)
   */
  @HostBinding('tabindex') tabIndex = 100

  private logger: Logger
  private onDestroy: Subject<void> = new Subject()

  constructor(loggerService: LoggerService, private elementRef: ElementRef) {
    this.logger = loggerService.getInstance('StateButton')
  }

  // propagate the focus to the button when the state-button is focused
  @HostListener('focus')
  onFocus() {
    this.logger.debug('focus')
    this.button.nativeElement.focus()
  }

  // define a (simpleClick)="fn(args)" to use state button as type button with click handler
  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (this.working || this.disabled) {
      event.preventDefault()
      event.stopImmediatePropagation()
    } else {
      this.scsClick.emit()
    }
  }

  ngOnInit(): void {
    this.working = this.working ? this.working : false
    this.type = this.type ? this.type : 'submit'
    this.facet = this.facet ? this.facet : 'primary'
    this.disabled = this.disabled ? this.disabled : false
    this.size = this.size ? this.size : 'md'
    this.iconSize = this.iconSize ? this.iconSize : this.size

    // listen for blur events, and call it to the state-button too
    this.button.nativeElement.addEventListener('blur', () => {
      this.logger.debug('blur')
      this.elementRef.nativeElement.blur()
    })
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if ('worker$' in changes && changes['worker$'].currentValue) {
      this.logger.debug('worker has changed and is ready to subscribe')

      this.working = true

      /*
       * Because we subscribe here,
       * this could cause multiple http requests or in general multiple executions of whatever the implementation does.
       * The implementor must make sure to call .share() on the observable, to ensure single execution.
       */
      this.worker$
        .pipe(
          takeUntil(this.onDestroy),
          finalize(() => {
            this.working = false
            this.logger.debug('finally')
          })
        )
        .subscribe(
          () => {
            this.logger.debug('next()')
            this.working = false
          },
          () => {
            this.logger.debug('error()')
            this.working = false
          },
          () => {
            this.logger.debug('complete()')
            this.working = false
          }
        )
    }

    if ('loading$' in changes && changes['loading$'].currentValue) {
      ;(<Observable<boolean>>changes['loading$'].currentValue).pipe(takeUntil(this.onDestroy)).subscribe(
        loading => {
          this.working = loading
        },
        () => {
          this.working = false
        },
        () => {
          this.working = false
        }
      )
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }
}
