import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FirstKeyPipe } from './first-key.pipe'
import { FormControlComponent } from './form-control.component'
import { LocaleModule, LoggerModule } from '@shiftcoders/core'

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LocaleModule, LoggerModule],
  declarations: [FormControlComponent, FirstKeyPipe],
  exports: [FormControlComponent, FormsModule, ReactiveFormsModule],
})
export class FormControlModule {}
