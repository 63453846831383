import { CollectionViewer, DataSource } from '@angular/cdk/collections'
import { isEqual } from 'lodash'
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject } from 'rxjs'
import { distinctUntilChanged, map, share, startWith, switchMap, tap } from 'rxjs/operators'
import { Logger, LoggerService } from '@shiftcoders/core'
import { Sort } from '../sort/sort'

export interface Criteria<T> {
  search: string
  sort: Sort
  filter: T
}

export interface FetchDataParams<T> extends Criteria<T> {
  pagedMeta: PagedMeta
}

export interface FetchDataParamsWithNextData<T> extends Criteria<T> {
  nextData: NextData
}

export interface PagedMeta {
  pageSize: number
  offset: number
}

export interface PagedResponse<T> {
  items: T[]
  count: number
}

export interface HasMoreState {
  pending: boolean
  data: boolean | null
}

export type FetchDataFn<T> = (params: FetchDataParams<any>) => Observable<PagedResponse<T>>

export interface NextData {
  pagedMeta: PagedMeta
  dataMode: 'concat' | 'set'
}

// FIXME makes too many calls to the backend for changes on filter for example
export class PagedDataSource<T> extends DataSource<T> {
  readonly logger: Logger

  readonly fetchDataFn: FetchDataFn<T>

  readonly loading$: Subject<boolean> = new Subject<boolean>()

  readonly hasMore$: Subject<HasMoreState> = new Subject<HasMoreState>()
  readonly count$: BehaviorSubject<number> = new BehaviorSubject(0)

  readonly refetchSubject: BehaviorSubject<null> = new BehaviorSubject(null)
  readonly resetSubject: BehaviorSubject<null> = new BehaviorSubject(null)

  private renderChangesSubscription: any

  private nextSubject: Subject<NextData> = new Subject()

  private currentPagedData: PagedMeta

  // sometimes we want to disable the update change subscription for single setter (see init method)
  private preventUpdateChangeSubscription: boolean
  private readonly data: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([])

  /*
   * search
   */
  private _search$: Observable<string>

  get search$(): Observable<string> {
    return this._search$
  }

  set search$(value: Observable<string>) {
    if (!isEqual(this._search$, value)) {
      this._search$ = value
      if (!this.preventUpdateChangeSubscription) {
        this.updateChangeSubscription()
      }
    }
  }

  /*
   * sort
   */
  private _sort$: Observable<Sort>

  get sort$(): Observable<Sort> {
    return this._sort$
  }

  set sort$(value: Observable<Sort>) {
    if (!isEqual(this._sort$, value)) {
      this._sort$ = value
      if (!this.preventUpdateChangeSubscription) {
        this.updateChangeSubscription()
      }
    }
  }

  /*
   * filters
   */
  private _filter$: Observable<string>

  get filter$(): Observable<string> {
    return this._filter$
  }

  set filter$(value: Observable<string>) {
    if (!isEqual(this._filter$, value)) {
      this._filter$ = value
      if (!this.preventUpdateChangeSubscription) {
        this.updateChangeSubscription()
      }
    }
  }

  constructor(
    loggerService: LoggerService,
    fetchDataFn: FetchDataFn<T>,
    private defaultPagedMeta: PagedMeta,
    preventInitialFetch: boolean = false
  ) {
    super()
    this.logger = loggerService.getInstance('PagedDataSource')
    this.fetchDataFn = fetchDataFn
    if (preventInitialFetch) {
      this.updateChangeSubscription()
    }
  }

  init(values: { sort$; filter$; search$ }): void {
    this.preventUpdateChangeSubscription = true
    this.sort$ = values.sort$
    this.filter$ = values.filter$
    this.search$ = values.search$
    this.updateChangeSubscription()
    this.preventUpdateChangeSubscription = false
  }

  nextPage(): void {
    this.nextSubject.next({
      pagedMeta: {
        pageSize: this.defaultPagedMeta.pageSize,
        offset: this.currentPagedData.offset + this.defaultPagedMeta.pageSize,
      },
      dataMode: 'concat',
    })
  }

  /**
   * this method should be called when an item was added / removed or edited to refetch the current visible data,
   * to make sure the data is up to date
   */
  refetch(): void {
    this.nextSubject.next({
      pagedMeta: { pageSize: this.data.value.length, offset: 0 },
      dataMode: 'set',
    })
    this.refetchSubject.next(null)
  }

  reset(): void {
    this.nextSubject.next({ pagedMeta: { pageSize: this.defaultPagedMeta.pageSize, offset: 0 }, dataMode: 'set' })
    this.resetSubject.next(null)
  }

  /**
   * Subscribe to changes that should trigger an update to the table's rendered rows. When the
   * changes occur, process the current state of the search, filter, sort and pagination along with
   * the provided base data and send it to the table for rendering.
   */
  private updateChangeSubscription() {
    this.logger.debug('updateChangeSubscription()')
    // Sorting and/or pagination should be watched if MatSort and/or MatPaginator are provided.
    // Otherwise, use an empty observable stream to take their place.
    const search$ = this._search$ ? this._search$ : EMPTY
    const filter$ = this._filter$ ? this._filter$ : EMPTY

    // sorting can be triggered in different ways, either by the select for mobile devices with smalls screens
    // or by clicking on a header cell on the table
    const sort$ = this._sort$ ? this._sort$ : EMPTY

    if (this.renderChangesSubscription) {
      this.renderChangesSubscription.unsubscribe()
    }

    const param$: Observable<Criteria<any>> = combineLatest(
      search$.pipe(startWith(null)),
      filter$.pipe(startWith(null)),
      sort$.pipe(startWith(null))
    ).pipe(
      distinctUntilChanged((a, b) => {
        return isEqual(a, b)
      }),
      tap(() => this.reset()),
      map(values => {
        return {
          search: values[0],
          filter: values[1],
          sort: values[2],
        }
      }),
      tap(() => this.logger.debug('one of the criteria or ui param changed')),
      share()
    )

    const next$ = this.nextSubject.pipe(
      startWith(<NextData>{ pagedMeta: this.defaultPagedMeta, dataMode: 'set' }),
      distinctUntilChanged((a, b) => {
        return isEqual(a, b)
      }),
      tap(nextData => this.logger.debug('load next page', nextData)),
      share()
    )

    const fetchData$: Observable<FetchDataParamsWithNextData<any>> = combineLatest(param$, next$).pipe(
      map((values: [Criteria<any>, NextData]) => {
        return <FetchDataParamsWithNextData<any>>{ ...values[0], nextData: values[1] }
      }),
      distinctUntilChanged((a, b) => {
        return isEqual(a, b)
      }),
      tap(value => this.logger.debug('fetchData changed', value))
    )

    const reset$ = this.resetSubject.pipe(tap(() => this.logger.debug('reset')))

    const refetch$ = this.refetchSubject.pipe(tap(() => this.logger.debug('refetch')))

    const data$ = combineLatest(fetchData$, reset$, refetch$).pipe(
      tap(() => {
        this.loading$.next(true)
        this.hasMore$.next({ pending: true, data: null })
      }),
      map((values: [FetchDataParamsWithNextData<any>, null, null]) => {
        return values[0]
      }),
      // distinctUntilChanged((a, b) => {
      //   return isEqual(a, b)
      // }),
      switchMap<FetchDataParamsWithNextData<any>, { pagedResponse: PagedResponse<T>; nextData: NextData }>(values => {
        if (!this.fetchDataFn) {
          throw new Error(`Make sure you provide a fetch data function when instantiating a paged data source`)
        }

        return this.fetchDataFn({
          sort: values.sort,
          search: values.search,
          filter: values.filter,
          pagedMeta: values.nextData.pagedMeta,
        }).pipe(
          tap<PagedResponse<T>>(pagedResponse => {
            this.currentPagedData = values.nextData.pagedMeta
            this.loading$.next(false)
            this.hasMore$.next({
              pending: false,
              data: pagedResponse.count > values.nextData.pagedMeta.offset + values.nextData.pagedMeta.pageSize,
            })
          }),
          map(pagedResponse => {
            return {
              pagedResponse: { ...pagedResponse },
              nextData: values.nextData,
            }
          })
        )
      }),
      /*
       * emit the total count when the data returns
       */
      tap((data: { pagedResponse: PagedResponse<T>; nextData: NextData }) => {
        this.count$.next(data.pagedResponse.count)
      }),
      share()
    )

    this.renderChangesSubscription = data$.subscribe(
      (data: { pagedResponse: PagedResponse<T>; nextData: NextData }) => {
        // we either append the new data or replace the current data with the new
        let allData: T[] = []

        switch (data.nextData.dataMode) {
          case 'concat':
            if (data.nextData.pagedMeta.offset === 0) {
              // fallback to 'set' action
              allData = data.pagedResponse.items
            } else {
              allData = this.data.value.concat(...data.pagedResponse.items)
            }
            break
          case 'set':
            allData = data.pagedResponse.items
            break
        }

        this.data.next(allData)
      },
      (error: any) => {
        this.loading$.next(false)
        this.hasMore$.next({ pending: false, data: null })
      }
    )
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.data.asObservable()
  }

  disconnect(collectionViewer: CollectionViewer | null): void {
    this.data.next([])
    this.renderChangesSubscription.unsubscribe()
  }
}
