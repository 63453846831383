import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { HttpError } from './http-error.model'

@Injectable()
export class HttpErrorService {
  readonly httpError$: Observable<HttpError<any>>

  private httpErrorSubject = new Subject<HttpError<any>>()

  constructor() {
    this.httpError$ = this.httpErrorSubject.asObservable()
  }

  handleDefaultErrors(httpError: HttpError<any>) {
    this.httpErrorSubject.next(httpError)
  }
}
