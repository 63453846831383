import { ModuleWithProviders, NgModule } from '@angular/core'
import { IconRegistry } from './icon-registry.service'
import { ScIcon } from './icon.component'

@NgModule({
  imports: [],
  declarations: [ScIcon],
  exports: [ScIcon],
})
export class IconModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: IconModule,
      providers: [IconRegistry],
    }
  }
}
