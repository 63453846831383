import { coerceNumberProperty } from '@angular/cdk/coercion'
import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core'
import { Logger, LoggerService, RouterScrollService } from '@shiftcoders/core'

@Directive({ selector: '[scDomReady]' })
export class DomReadyDirective implements OnInit, OnDestroy {
  private static DEFAULT_TIME_OUT = 150

  @Input()
  get domReady() {
    return this._domReady
  }

  set domReady(value) {
    this._domReady = coerceNumberProperty(value) || DomReadyDirective.DEFAULT_TIME_OUT
  }

  // optional timeout value for views that need longer than 150ms to fully load... still hacky
  // tslint:disable-next-line
  private _domReady // if not set this is an empty string

  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private routerScrollService: RouterScrollService,
    private elementRef: ElementRef
  ) {
    this.logger = loggerService.getInstance('DomReadyDirective')
  }

  ngOnInit(): void {
    // TODO LOW hacky with the timeout, how can we tell exactly when the rendering ends so the element can actually
    // scrolled into view -> investigate for other solutions
    setTimeout(() => {
      this.routerScrollService.elementReady(this.elementRef)
    }, this._domReady)
  }

  /** why not use the after view init */
  // ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.routerScrollService.elementDestroyed(this.elementRef)
  }
}
