import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core'
import { Logger, LoggerService, RouterScrollService } from '@maprix/core'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scs-dom-ready]',
})
export class DomReadyDirective implements OnInit, OnDestroy {
  private static DEFAULT_TIME_OUT = 150

  // optional timeout value for views that need longer than 150ms to fully load... still hacky
  // tslint:disable-next-line
  @Input('scs-dom-ready') scsDomReady // if not set this is an empty string

  private logger: Logger

  constructor(
    loggerService: LoggerService,
    private routerScrollService: RouterScrollService,
    private elementRef: ElementRef
  ) {
    this.logger = loggerService.getInstance('DomReadyDirective')
  }

  ngOnInit(): void {
    this.scsDomReady =
      this.scsDomReady !== undefined || this.scsDomReady === '' ? DomReadyDirective.DEFAULT_TIME_OUT : this.scsDomReady
    // TODO LOW hacky with the timeout, how can we tell exactly when the rendering ends so the element can actually scrolled into view -> investigate for other solutions
    setTimeout(() => {
      this.routerScrollService.elementReady(this.elementRef)
    }, this.scsDomReady)
  }

  ngOnDestroy(): void {
    this.routerScrollService.elementDestroyed(this.elementRef)
  }
}
