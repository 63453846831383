import { Injectable } from '@angular/core'
import { LocalStorage } from '../local-storage/local-storage.service'
import { createUUID } from '../static-utils/create-uuid.function'

@Injectable()
export class ClientIdService {
  private static UNIQUE_ID_KEY = 'CL_ID'

  constructor(private localStorage: LocalStorage) {
    // if no clientID is present inside ls generate a new one
    if (this.getClientId() === null) {
      this.generateNewClientId()
    }
  }

  getClientId(): string {
    return this.localStorage.getItem(ClientIdService.UNIQUE_ID_KEY)
  }

  private setClientId(uuid: string): void {
    this.localStorage.setItem(ClientIdService.UNIQUE_ID_KEY, uuid)
  }

  private generateNewClientId(): string {
    const clientId = createUUID()
    this.setClientId(clientId)
    return clientId
  }
}
